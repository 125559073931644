//la funzione server per "pulire" un valore post moltiplicazione daglie rrori di JS nelle operazioni matematiche
//https://stackoverflow.com/questions/9993266/javascript-multiply-not-precise

export const truncate = (value: number | null, fixed: number | null) => {
    if (value !== null) {
        const stringValue = value.toString();
        if (stringValue !== null) {
            var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
            const stringValueMatch = stringValue.match(re);
            return stringValueMatch === null ? 0 : +stringValueMatch[0];
        }
    }
    return 0;
}

export const fixRoundingUp = (value: number | null, precision: number | null) => {
    var power = Math.pow(10, precision || 0)
    return value === null ? 0 : Math.ceil(value * power) / power
}

export const fixRounding = (value: number | null, precision: number | null) => {
    var power = Math.pow(10, precision || 0);
    return value === null ? 0 : Math.round(value * power) / power;
}

export const fixRoundingWithZeros = (value: number | null, precision: number | null) => {
    return fixRounding(value, precision).toFixed(precision || 0);
}