import React from 'react';
import '../../CustomCss/variableCss.scss';
import '../../CustomCss/FlexTrick.css';
import '../../CustomCss/FormCss/LoginForm.scss';
import '../../CustomCss/InputTypeCss/InputType.scss';
import { Alert, Button, Form } from 'antd';
import { Validate } from '../AntDesignType';
import { TranslateString } from '../../i18n/translate';
import { FormPasswordInput, FormTextInput_old } from '../Utils_Component/InputForm';
import { notifyError } from '../CustomToast/customToast';
import { LoginModel } from '../Model/LoginModel';
import store from '../Redux/Store';
import { RestCall } from '../Rest/Rest_Call/RestCall';
import RestUrl from '../../RestUrl.json';
import { Redirect } from 'react-router-dom';
import { ChangeUrl } from '../../utils/ChangeUrl';


interface ILogin {
}

const Login: React.FC<ILogin> = props => {

  const [firstName, setFirstName] = React.useState<string | undefined>(undefined);
  const [password, setPassword] = React.useState<string | undefined>(undefined);
  const [validateName, setValidateName] = React.useState<Validate>(undefined);
  const [validatePsw, setValidatePsw] = React.useState<Validate>(undefined);

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
    const target = event.target;
    const value = target.value;//contiene il nuovo valore della textbox
    const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]

    if (name === 'firstName') { setFirstName(value); }
    else if (name === 'password') { setPassword(value); }
  }

  const handleLogout = () => {
    RestCall.getAll(RestUrl.AUTHENTICACTION_LOGOUT, 'login_url').then(
      result => {
        if (typeof result === 'string') {
          if (result === '') {//le API ritornano una stringa vuota se va tutto bene
            store.dispatch({ type: 'SETLOGIN', isLogged: false });
            store.dispatch({ type: 'SETPERMISSIONS', user_type: undefined });
            ChangeUrl('');
          } else {
            notifyError(result);
          }
        }
      }
    );
  }

  const handleSubmit = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    handleLoginSubmit(false);
  }

  const handleKeySubmit = (event: React.KeyboardEvent<any>) => {//gestisce la chiamata da tastiera
    event.preventDefault();
    if (event.key === 'Enter') {
      handleLoginSubmit(false);
    }
  }

  const handleLoginSubmit = (client_id: boolean) => {
    if (firstName !== "" && password !== "" && typeof firstName !== 'undefined' && typeof password !== 'undefined') {
      RestCall.getToken(new LoginModel(firstName, password, 'G', 'string'), RestUrl.AUTHENTICACTION_GETTOKEN, 'login_url').then
        (
          result => {
            if (typeof result !== 'undefined' && typeof result !== 'string') {
              visibleSections();

            } else if (typeof result === 'string') {
              if (result === "VALID_TOKEN") {
                handleLoginSubmit(client_id);
              } else {
                notifyError(result);
              }
            }
          }
        );
    }
    if (firstName === "" || typeof firstName === 'undefined') {
      setValidateName("error");
    }
    if (password === "" || typeof password === 'undefined') {
      setValidatePsw("error");
    }
  }

  const visibleSections = () => {
    RestCall.getWithEasyAccess(RestUrl.USER_SETTING, 'url', result => {
      if (result !== undefined && result !== null) {
        store.dispatch({ type: 'SETISVISIBLE', enableTracking: result.data.enableTracking ?? false, enablePrese: result.data.enablePrese ?? false, enableDownloadPod: result.data.enableDownloadPod ?? false });
        loadPermissions();

        if (result.data.enablePrese) {
          ChangeUrl('Prese');
        } else if (result.data.enableTracking) {
          ChangeUrl('Tracking');
        }

      }
    },
      message => {
        if(message === "@ERROR_403"){
          handleLogout();
          notifyError("Utente Bloccato");
          return;
        }
        store.dispatch({ type: 'SETISVISIBLE', enableTracking: false, enablePrese: false, enableDownloadPod: false });
      }
    );


  }

  const loadPermissions = () => {
    RestCall.getAll(RestUrl.CURRENT_USER, 'login_url').then(
      result => {
        if (typeof result !== 'undefined' && typeof result !== 'string') {
          store.dispatch({ type: 'SETLOGIN', isLogged: true });
          if (result.superuser === true) {
            store.dispatch({ type: 'SETPERMISSIONS', user_type: 'superuser' });
          } else {
            store.dispatch({ type: 'SETPERMISSIONS', user_type: result.usertypedescription.toLowerCase() === 'cliente' ? 'normal' : 'backoffice' });
          }
        } else if (typeof result === 'string') {
          if (result === 'VALID_TOKEN') {
            loadPermissions();
          }
        }
      }
    )
  }

  const changeColor = () => {
    document.documentElement.style.setProperty('--app-primaryColor', firstName!)
  }

  return (
    <div className='backgroundIMG' style={{ height: "100vh" }}>
      <div className="mainLogin container-col c-ctr " >
        <div className='logoIMG' />
        <Form className="LoginForm " onKeyUp={handleKeySubmit} style={{ backgroundColor: 'white' }}>
          <div className="container-col c-ctr">
            <span className="login-title">&nbsp;</span>
          </div>
          <div className="login-padding">
            <FormTextInput_old mandatory={true} label_name="LOGIN_USER_NAME" input_name="firstName" placeholder={TranslateString("@LOGIN_USER_NAME")} input_value={firstName} onChange={handleChanges} validate={validateName} />

            <FormPasswordInput mandatory={true} label_name="LOGIN_PASSWORD" placeholder={TranslateString("@LOGIN_PASSWORD")} input_name="password" input_value={password} validate={validatePsw} onChange={handleChanges} />
          </div>
          <div className="container-col c-ctr">
            <Button className="button-login" type="primary" onClick={handleSubmit} > {TranslateString("@LOGIN_BUTTON_NAME")} </Button>
          </div>
        </Form>
        <Alert
          style={{ marginTop: 20, maxWidth: '50%', minWidth: '500px' }}
          description={<>Se sei già nostro cliente e desideri accedere al Portale puoi inviare una richiesta di attivazione all'indirizzo <a href="mailto:portale@transfilm.it">portale@transfilm.it</a> specificando Ragione sociale e Partita IVA della tua azienda. Verrai ricontattato dal nostro personale.</>}
          type="info"
          showIcon
        />
      </div>
    </div>
  );


}

export { Login };