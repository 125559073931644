import { SITableFilterController, SITableFilterTypeValues } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterController';
import { SITableOrder } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterModel';
import { SITableColumnStructure } from "@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableModels";
import { TranslateString } from "../../i18n/translate";
import { FormateDateWith00 } from "../../utils/FormatDate";
import { Button, Tag } from 'antd';
import { notifySucces } from '../CustomToast/customToast';
import {
  BarcodeOutlined, EyeOutlined, EditOutlined, DeleteOutlined, CopyOutlined, PaperClipOutlined
} from '@ant-design/icons';
import { UserType } from '../Redux/Reducer';
import { fixRoundingWithZeros } from '../Utils_Component/MathUtils';
import store from '../Redux/Store';


export const PreseColumns = (
  onOrderBy: (order_by: SITableOrder) => void,
  onFilter: (value: string, sql_name: string, operator: string, filter_type: SITableFilterTypeValues) => void,
  showUpdateModal: (row: any) => void,
  showUpdateModal2: (row: any, B: boolean) => void,
  showCancelModal: (row: any) => void,
  showTrackingModal: (row: any) => void,
  onOpenFile: (record: any) => void,
  user_type: UserType,
  /*   getPresaEditable: (stato: any) => void,
    getPresaAnnullabile: (stato: any) => void, */
): any[] => {

  const columns: SITableColumnStructure[] = [
    {
      title: <SITableFilterController
        sql_name={"nrPresa"}
        alias={TranslateString('@NUMEROPRESA')}
        //onOrderBy={onOrderBy}
        filter_type={'Number'}
        onSearch={onFilter}
      />,
      key: 'numeroPresa',
      column_type: 'Number',
      dataIndex: 'numeroPresa',
      width: 110,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"dataRitiro"}
        alias={TranslateString('@DATA_RITIRO')}
        /*         //onOrderBy={onOrderBy}
                filter_type={'DateTime'}
                onSearch={onFilter} */
        titleAlign='center'
      />,
      key: 'dataRitiro',
      column_type: 'DateTime',
      dataIndex: 'dataRitiro',
      width: 120,
      padding: "0",
      render: (record: string) => (<div className="container-row c-ctr ">{FormateDateWith00(record)}</div>)
    },
    {
      title: <SITableFilterController
        sql_name={"stato"}
        alias={TranslateString('@STATOPRESA')}
        /*         //onOrderBy={onOrderBy}
                filter_type={'Number'}
                onSearch={onFilter} */
        titleAlign='center'
      />,
      key: 'stato',
      column_type: 'Number',
      dataIndex: 'stato',
      width: 150,
      padding: "0",
      //cellClassName: ' '
      render: (stato: number) => {
        if (stato <= 2) {
          //"Caricata dal portale"
          return  <div className="container-row c-ctr"><Tag color='green' key={'stato_' + stato}>{TranslateString('@PRESE_STATO_CARICATA_DAL_PORTALE')}</Tag></div>
        }
         if (stato <= 10) {
          //"In gestione"
          return <div className="container-row c-ctr"><Tag color='cyan' key={'stato_' + stato}>{TranslateString('@PRESE_STATO_IN_GESTIONE')}</Tag></div>
        }

        if (stato <= 20) {
          //"Assegnata"
          return <div className="container-row c-ctr"><Tag color='purple' key={'stato_' + stato}>{TranslateString('@PRESE_STATO_ASSEGNATA')}</Tag></div>
        }

        if (stato <= 30) {
          //"Confermata"
          return <div className="container-row c-ctr"><Tag color='blue' key={'stato_' + stato}>{TranslateString('@PRESE_STATO_CONFERMATA')}</Tag></div>
        }

        if (stato <= 60) {
          //"Caricata"
          return <div className="container-row c-ctr"><Tag color='lime' key={'stato_' + stato}>{TranslateString('@PRESE_STATO_CARICATA')}</Tag></div>
        }

        if (stato <= 90) {
          //"Rientrata"
          return <div className="container-row c-ctr"><Tag color='orange' key={'stato_' + stato}>{TranslateString('@PRESE_STATO_RIENTRATA')}</Tag></div>
        }

        if (stato === 95) {
          //"Richiesta annullamento"
          return <div className="container-row c-ctr"><Tag color='black' key={'stato_' + stato}>{TranslateString('@PRESE_STATO_RICHIESTA_ANNULLAMENTO')}</Tag></div>
        }

        if (stato === 100) {
          //"Annullata"
          return <div className="container-row c-ctr"><Tag color='red' key={'stato_' + stato}>{TranslateString('@PRESE_STATO_ANNULLATA')}</Tag></div>
        } 


{/* 
        const s = statoMap(record.stato);
        return (<Tag color={s.color} key={'stato_' + record.id}>
          {s.description}
        </Tag>); */}
      }
    },
    {
      title: <SITableFilterController
        sql_name={"ragioneSocialeMittente"}
        alias={TranslateString('@MITTENTE_RAGIONE_SOCIALE')}
        //onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      />,
      key: 'mittenteRagioneSociale',
      column_type: 'String',
      dataIndex: 'mittenteRagioneSociale',
      width: 200,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"ragioneSocialeDest"}
        alias={TranslateString('@DESTINAZIONE_RAGIONE_SOCIALE')}
        //onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      />,
      key: 'destinazioneRagioneSociale',
      column_type: 'String',
      dataIndex: 'destinazioneRagioneSociale',
      width: 200,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"peso"}
        alias={TranslateString('@PESO')}
        /*         //onOrderBy={onOrderBy}
                filter_type={'Number'}
                onSearch={onFilter} */
        titleAlign='end'
      />,
      key: 'peso',
      column_type: 'Number',
      dataIndex: 'peso',
      width: 140,
      padding: "0",
      render: (value: number) => (<div className="container-col c-ctrbottom-b">{fixRoundingWithZeros(value, 2)}</div>)
    },
    {
      title: <SITableFilterController
        sql_name={"quantita"}
        alias={TranslateString('@QTA')}
        /*         //onOrderBy={onOrderBy}
                filter_type={'Number'}
                onSearch={onFilter} */
        titleAlign='end'
      />,
      key: 'quantita',
      column_type: 'Number',
      dataIndex: 'quantita',
      width: 140,
      padding: "0",
      render: (record: any) => (<div className="container-row c-ctrbottom-b padding-right-10 ">{record}</div>)
    },
    {
      title: <SITableFilterController
        sql_name={"autista"}
        alias={TranslateString('@AUTISTA')}
      /*         //onOrderBy={onOrderBy}
              filter_type={'String'}
              onSearch={onFilter} */
      />,
      key: 'autista',
      column_type: 'String',
      dataIndex: 'autista',
      width: 180,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"veicolo"}
        alias={TranslateString('@TARGA')}
      /*         //onOrderBy={onOrderBy}
              filter_type={'String'}
              onSearch={onFilter} */
      />,
      key: 'veicolo',
      column_type: 'String',
      dataIndex: 'veicolo',
      width: 180,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"provacons"}
        alias={<PaperClipOutlined />}
        titleAlign={'center'}
      />,
      dataIndex: 'provacons',
      key: 'provacons',
      fixed: 'right',
      width: 50,
      column_type: 'String',
      padding: "0px",
      render: (value: string, record: any) => (
        <div className="container-col c-ctr"> <a onClick={() => onOpenFile(record)}> <PaperClipOutlined /> </a> </div>
      )
    },
    {
      title: <SITableFilterController
        sql_name={""}
        alias={"Action"}
        titleAlign='center'
      />,
      key: 'action',
      column_type: 'String',
      fixed: 'right',
      dataIndex: 'action',
      width: (user_type === 'superuser' || user_type === 'backoffice') ? 60 : 190,
      padding: "0",
      render: (text: any, record: any) => {

        let isEditable = !(/*isBackoffice*/ user_type === 'superuser' || user_type === 'backoffice') && (record.stato === 2 || record.stato === -1); //inserita da portale o duplica o inserimento
        let isCancelable = !(/*isBackoffice*/ user_type === 'superuser' || user_type === 'backoffice') && record.stato <= 30; //ifino all' "in gestione"

        return (
          <div className="container-col">
            <div className="container-row c-ctr">
              {
                !(/*isBackoffice*/ user_type === 'superuser' || user_type === 'backoffice') ?
                  <>
                    <Button
                      className={isEditable ? "button-modify" : "normal-background-ghost"}
                      title={TranslateString(isEditable ? "@MODIFY" : "@SHOW")}
                      style={{ marginRight: "5px" }}
                      type={isEditable ? "primary" : undefined}
                      shape="round"
                      size={'small'}
                      icon={isEditable ? <EditOutlined /> : <EyeOutlined />}
                      onClick={() => showUpdateModal(record)}
                    />
                    <Button
                      className={"normal-background-ghost"}
                      title={TranslateString("@TRACKING")}
                      disabled={store.getState().enableTracking ? ((record.idBolla > 0) ? false : true) : true}
                      style={{ marginRight: "5px" }}
                      shape="round"
                      size={'small'}
                      icon={<BarcodeOutlined />}
                      onClick={() => showTrackingModal(record)}
                    />

                    <Button
                      className={"normal-background-ghost"}
                      title={TranslateString("@DUPLICATE")}
                      style={{ marginRight: "5px" }}
                      shape="round"
                      size={'small'}
                      icon={<CopyOutlined />}
                      onClick={() => showUpdateModal2(record, true)}
                    />
                    <Button
                      className={isCancelable ? "red-background-ghost" : "gray-background-ghost"}
                      title={TranslateString("@DELETE")}
                      shape="round"
                      size={'small'}
                      icon={<DeleteOutlined />}
                      onClick={() => isCancelable ? showCancelModal(record) : notifySucces("Non è possibile annullare la presa")}
                    />
                  </> :
                  <Button
                    className="normal-background-ghost"
                    title={TranslateString("@SHOW")}
                    style={{ marginRight: "5px" }}
                    shape="round"
                    size={'small'}
                    icon={<EyeOutlined />}
                    onClick={() => showUpdateModal(record)}
                  />
              }

            </div>
          </div>
        );
      },
    }

  ];

  return (columns);
}

