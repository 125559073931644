import {FormattedMessage, useIntl} from 'react-intl';

const translate = (id, value={}) => <FormattedMessage id= {id} values = {{...value}}/>

export const TranslateString = (id,paramTag,param) => {
    const intl = useIntl();
    if (typeof paramTag !== 'undefined'){
        const string = intl.formatMessage({id:`${id}`}).replace(`${paramTag}`, `${param}`);
        return (string);
    }else{
        const string = intl.formatMessage({id:`${id}`});
        return (string);
    }
    
}

export default translate;

