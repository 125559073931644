import { SITableFilterController, SITableFilterTypeValues } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterController';
import { SITableOrder } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterModel';
import { SITableColumnStructure } from "@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableModels";
import { TranslateString } from "../../i18n/translate";
import { Button, Tag } from 'antd';
import {
  LockOutlined, EditOutlined, DeleteOutlined, BarcodeOutlined
} from '@ant-design/icons';
import {
  CheckOutlined,

} from '@ant-design/icons';

export const UserColumns = (
  onOrderBy: (order_by: SITableOrder) => void,
  onFilter: (value: string, sql_name: string, operator: string, filter_type: SITableFilterTypeValues) => void,
  showUpdateModal: (row: any) => void,
  showChangePasswod: (row: any) => void,
  showDeleteModal: (row: any) => void,
  Checked: (row: any) => void,
  showTrackingModal: (row: any) => void,
): any[] => {

  const columns: SITableColumnStructure[] = [
    {
      title: <SITableFilterController
        sql_name={"id"}
        alias={TranslateString('@ID')}
        onOrderBy={onOrderBy}
        filter_type={'Number'}
        onSearch={onFilter}
        titleAlign='end'
      />,
      key: 'id',
      column_type: 'Number',
      dataIndex: 'id',
      width: 110,
      padding: "0",
      render: (record: any) => (<div className="container-row c-ctrbottom-b padding-right-10 ">{record}</div>)
    },
    {
      title: <SITableFilterController
        sql_name={"idUser"}
        alias={TranslateString('@USERID')}
        onOrderBy={onOrderBy}
        filter_type={'Number'}
        onSearch={onFilter}
        titleAlign='end'
      />,
      key: 'idUser',
      column_type: 'Number',
      dataIndex: 'idUser',
      width: 220,
      padding: "0",
      render: (record: any) => (<div className="container-row c-ctrbottom-b padding-right-10 ">{record}</div>)
    },
    {
      title: <SITableFilterController
        sql_name={"userName"}
        alias={TranslateString('@USERNAME')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
        titleAlign='center'
      />,
      key: 'userName',
      column_type: 'String',
      dataIndex: 'userName',
      width: 350,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"isBackoffice"}
        alias={TranslateString('@BACKOFFICE')}
        onOrderBy={onOrderBy}
        filter_type={'Boolean'}
        onSearch={onFilter}
        titleAlign='center'
      />,
      key: 'isBackoffice',
      column_type: 'Boolean',
      dataIndex: 'isBackoffice',
      width: 120,
      padding: "0",
      render: (record: any) => {
        //let a = Checked(record);
        return (
          <div className="container-col c-ctr " >
            {record ? <CheckOutlined /> : ""}
          </div >
        )
      }
    },
    {
      title: <SITableFilterController
        sql_name={"enablePrese"}
        alias={TranslateString('@USER_VISIBILE_PRESE')}
        onOrderBy={onOrderBy}
        filter_type={'Boolean'}
        onSearch={onFilter}
        titleAlign='center'
      />,
      key: 'enablePrese',
      column_type: 'Boolean',
      dataIndex: 'enablePrese',
      width: 120,
      padding: "0",
      render: (record: any) => {
        //let a = Checked(record);
        return (
          <div className="container-col c-ctr " >
            {record ? <CheckOutlined /> : ""}
          </div >
        )
      }
    },
    {
      title: <SITableFilterController
        sql_name={"enableTracking"}
        alias={TranslateString('@USER_VISIBLE_TRACKING')}
        onOrderBy={onOrderBy}
        filter_type={'Boolean'}
        onSearch={onFilter}
        titleAlign='center'
      />,
      key: 'enableTracking',
      column_type: 'Boolean',
      dataIndex: 'enableTracking',
      width: 120,
      padding: "0",
      render: (record: any) => {
        //let a = Checked(record);
        return (
          <div className="container-col c-ctr " >
            {record ? <CheckOutlined /> : ""}
          </div >
        )
      }
    },
    {
      title: <SITableFilterController
        sql_name={"enableDownloadPod"}
        alias={TranslateString('@USER_VISIBLE_DOWNLOAD_TRACKING')}
        onOrderBy={onOrderBy}
        filter_type={'Boolean'}
        onSearch={onFilter}
        titleAlign='center'
      />,
      key: 'enableDownloadPod',
      column_type: 'Boolean',
      dataIndex: 'enableDownloadPod',
      width: 120,
      padding: "0",
      render: (record: any) => {
        //let a = Checked(record);
        return (
          <div className="container-col c-ctr " >
            {record ? <CheckOutlined /> : ""}
          </div >
        )
      }
    },

    {
      title: <SITableFilterController
        sql_name={"codiceCliente"}
        alias={TranslateString('@CLIENTE_METODO')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      />,
      key: 'codiceCliente',
      column_type: 'String',
      dataIndex: 'codiceCliente',
      width: 220,
      padding: "0",
    },


    {
      title: <SITableFilterController
        sql_name={""}
        alias={"Action"}
        titleAlign='center'
      />,
      key: 'action',
      column_type: 'String',
      fixed: 'right',
      dataIndex: 'action',
      width: 200,
      padding: "0",
      render: (text: any, record: any) => {
        return (
          <div className="container-col">
            <div className="container-row c-ctr">
              <Button
                className="button-modify"
                title={TranslateString("@MODIFY")}
                style={{ marginRight: "5px" }}
                type="primary"
                shape="round"
                size={'small'}
                icon={<EditOutlined />}
                onClick={() => showUpdateModal(record)}
              />
              <Button
                className="primary-background-ghost"
                title={TranslateString("@MODIFY")}
                style={{ marginRight: "5px" }}
                shape="round"
                size={'small'}
                icon={<BarcodeOutlined />}
                onClick={() => showTrackingModal(record)}
              />
              <Button
                className="primary-background-ghost"
                title={TranslateString("@CHANGE_PASSWORD_HOVER")}
                style={{ marginRight: "5px" }}
                shape="round"
                size={'small'}
                icon={<LockOutlined />}
                onClick={() => showChangePasswod(record)}
              />
              <Button
                className="red-background-ghost"
                title={TranslateString("@DELETE")}
                shape="round"
                size={'small'}
                icon={<DeleteOutlined />}
                onClick={() => showDeleteModal(record)}
              />

            </div>
          </div>
        );
      },
    }

  ];

  return (columns);
}

