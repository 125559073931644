import { SITableFilterController, SITableFilterTypeValues } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterController';
import { SITableOrder } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterModel';
import { SITableColumnStructure } from "@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableModels";
import { TranslateString } from "../../i18n/translate";
import { Button, Tag } from 'antd';
import {
  LockOutlined, EditOutlined, DeleteOutlined, BarcodeOutlined
} from '@ant-design/icons';
import {
  CheckOutlined,

} from '@ant-design/icons';

export const TrackingColumns = (
  onOrderBy: (order_by: SITableOrder) => void,
  onFilter: (value: string, sql_name: string, operator: string, filter_type: SITableFilterTypeValues) => void,
  showDeleteModal: (row: any) => void,
): any[] => {

  const columns: SITableColumnStructure[] = [
    {
      title: <SITableFilterController
        sql_name={"codiceCliente"}
        alias={TranslateString('@CLIENTE_METODO')}
      /* onOrderBy={onOrderBy}
      filter_type={'String'}
      onSearch={onFilter} */
      />,
      key: 'codiceCliente',
      column_type: 'String',
      dataIndex: 'codiceCliente',
      width: 180,
      padding: "0",
    },
/*     {
      title: <SITableFilterController
        sql_name={"ragioneSociale"}
        alias={TranslateString('@USERNAME')}
        titleAlign='center'
      />,
      key: 'ragioneSociale',
      column_type: 'String',
      dataIndex: 'ragioneSociale',
      width: 350,
      padding: "0",
    },   */
    {
      title: <SITableFilterController
        sql_name={""}
        alias={"Action"}
        titleAlign='center'
      />,
      key: 'action',
      column_type: 'String',
      fixed: 'right',
      dataIndex: 'action',
      width: 60,
      padding: "0",
      render: (text: any, record: any) => {
        return (
          <div className="container-col">
            <div className="container-row c-ctr">
              <Button
                className="red-background-ghost"
                title={TranslateString("@DELETE")}
                shape="round"
                size={'small'}
                icon={<DeleteOutlined />}
                onClick={() => showDeleteModal(record)}
              />

            </div>
          </div>
        );
      },
    }

  ];

  return (columns);
}

