


export const ChangeUrl = (URL:string) => {
    const url = window.location.href;
    const currentpage = url.split("#")[0];
    window.location.assign(currentpage+"#/"+URL);    
}

export const  GetUrlId = (URL:string) : string=> {
    const url = window.location.href;
    const currentpage = url.split(URL)[1];

    return currentpage;
}