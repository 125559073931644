import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Popconfirm, Form, Checkbox } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { ExpandedRowRender } from 'rc-table/lib/interface';

export const EditableContext = React.createContext<FormInstance<any> | null>(null);

export interface EditableRowProps {
  index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof any;
  column_type: string;
  record: any;
  handleSave: (record: any) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({ title, editable, children, dataIndex, column_type, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>(null);
  const form = useContext(EditableContext)!;

  let value = undefined;
  if (typeof dataIndex !== 'undefined') {
    value = record[dataIndex];
  }

  /*useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);
 
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };*/

  useEffect(() => {
    if (dataIndex !== undefined) {
      form?.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
  }, [form, dataIndex]);


  const save = async () => {

    try {
      if (typeof record[dataIndex] === 'boolean') {
        const values = { [dataIndex]: !record[dataIndex] }
        //toggleEdit();
        handleSave({ ...record, ...values });
      } else {
        const values = await form.validateFields();
        //toggleEdit();
        handleSave({ ...record, ...values });
      }


    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;


  if (editable) {
    childNode = editing || true ? (
      <Form.Item
        style={{ margin: 0 }}
        name={typeof dataIndex !== 'symbol' ? dataIndex : undefined}
        rules={undefined /*[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]*/}
      >
        {
          typeof value === 'boolean'
            ?
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} >
              <Checkbox ref={inputRef} onChange={save} checked={record[dataIndex]} />
            </div>
            : typeof value === 'number' ?
              <Input ref={inputRef} type="number" onPressEnter={save} onBlur={save} />
              :
              <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        }
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24, minHeight: 32 }} /*onClick={toggleEdit}*/>
        {typeof value === 'boolean'
          ?
          <Checkbox ref={inputRef} onChange={save} checked={record[dataIndex]} />
          :
          children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};


type EditableTableProps = Parameters<typeof Table>[0];



type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface EdiTableProps {
  columns: any[],
  dataSource: any[],
  onChangeData: (value: any[]) => void
}

export const EdiTable: React.FC<EdiTableProps> = props => {

  const [column, setColumn] = useState(props.columns);
  const [data, setData] = useState(props.dataSource);

  React.useEffect(
    () => {
      setData(props.dataSource);
    }, [props.dataSource]
  );
  React.useEffect(
    () => {
      setColumn(props.columns);
    }, [props.columns]
  );

  const handleSave = (row: any) => {
    const newData = [...data];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setData(newData);
    props.onChangeData(newData);
  };


  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = column.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  return (
    <>
      <Table
        size={"small"}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={data}
        columns={columns as ColumnTypes}
        scroll={{ x: 180, y: 'auto' }}
        pagination={false}
      />
    </>
  );
}



interface ExpandSelEdiTableProps {
  columns: any[],
  dataSource: any[],
  table_keys: any[],
  onChangeKeys: (keys: any[], rows: any[]) => void,
  onChangeValue: (array: any[]) => void,
  expandedRowRender: ExpandedRowRender<any>,
  scroll_X: number | 'auto',
  scroll_Y: number | 'auto'
}

export const ExpandSelEdiTable: React.FC<ExpandSelEdiTableProps> = props => {

  const [column, setColumn] = useState(props.columns);
  const [data, setData] = useState(props.dataSource);

  React.useEffect(
    () => {
      setData(props.dataSource);
    }, [props.dataSource]
  );
  React.useEffect(
    () => {
      setColumn(props.columns);
    }, [props.columns]
  );

  const handleSave = (row: any) => {
    const newData = [...data];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setData(newData);
    props.onChangeValue(newData);
  };


  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = column.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  const rowSelection = {
    onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
      props.onChangeKeys(selectedRows, selectedRowKeys);
    }
  }

  return (
    <>
      <Table
        expandable={{ expandedRowRender: props.expandedRowRender }}
        components={components}
        rowClassName={(record: any, index) => record.error === true ? 'table-row-mandatory editable-row' : 'table-row-normal editable-row'}
        bordered
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
          selectedRowKeys: props.table_keys
        }}
        pagination={false}
        size={"small"}
        dataSource={data}
        columns={columns as ColumnTypes}
        scroll={{ x: props.scroll_X, y: props.scroll_Y }}
      />
    </>
  );
}