import { PresaPortDTO } from "../Model/PreseModel";
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { message, Upload } from 'antd';
import { UploadFile } from "antd/lib/upload/interface";
import React, { useState } from 'react';
import { Button, Modal, Result } from "antd";
import { TranslateString } from "../../i18n/translate";

const { Dragger } = Upload;

interface PreventiviSpedizioniereUploadProps {
    record: PresaPortDTO,
    guid: string,
    onSaveSpedizioniere: (file: File) => void,
    reject: () => void
}


export const PreventiviSpedizioniereUpload: React.FC<PreventiviSpedizioniereUploadProps> = props => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);

    const propsUpload: UploadProps = {
        name: 'file',
        multiple: false,
        maxCount: 1, //nonva
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            //setFileList([...fileList, file]);

            setFileList([file]);

            return false;
        },
        fileList,
    };

    const save = () => {

        const f = fileList[0];
        props.onSaveSpedizioniere(f as unknown as File);
    }

    return <>
        <div style={{height:'100%', paddingBottom:20}}>
            <Dragger {...propsUpload}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                    band files
                </p>
            </Dragger>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', position: 'sticky', bottom: 0, backgroundColor: '#FFF' }}>
            <Button size='large' style={{marginRight:50}} type="primary" danger onClick={() => props.reject()}>{TranslateString("@PREVENTIVO_REJECT")}</Button>
            <Button size='large' type="primary" disabled={fileList.length !== 1} onClick={() => save()}>{TranslateString("@PREVENTIVO_CONFIRM")}</Button>
        </div>
    </>;
}