import { Button, Checkbox, Form, Select } from 'antd';
import React from 'react';
import '../../CustomCss/InputTypeCss/InputType.scss';
import RestUrl from '../../RestUrl.json';
import { FormPasswordInput, FormTextInput_old } from '../Utils_Component/InputForm';
import { notifyError } from '../CustomToast/customToast';
import { TranslateString } from '../../i18n/translate';
import { CustomerListModel, UserPortDto } from '../Model/UsersModel';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RestCall } from '../Rest/Rest_Call/RestCall';
import { KeyOutlined } from '@ant-design/icons';
import { UsersValidator } from './UsersInterface';
import { SITableVirtualizedComponent } from '@si/si-react-components/dist/esm/components/SITable/SITableVirtualized/SITableVirtualizedComponent';
import { TrackingColumns } from './TrackingColumns';
import { PlusCircleOutlined } from '@ant-design/icons';

interface TrackingCustomerFormProps {
    user: UserPortDto,
    validator: UsersValidator,
    onChange: (user: UserPortDto) => void
}


export const TrackingCustomerForm: React.FC<TrackingCustomerFormProps> = props => {
    const [user, setUser] = React.useState(props.user);
    const [new_user, setNewUser] = React.useState(CustomerListModel.getDefault());
    const [wait, setWait] = React.useState(false);

    React.useEffect(//the first time the component is generated, it loads the dashboard data from the database if it hasn't the default id value (-1)
        () => {

            if (props.user.id > 0) {
                loadByID();
                //loadCustomers();
            }

        }, []
    )

    /*     const loadCustomers = () => {
            setWait(true);
            
            RestCall.getWithEasyAccess(RestUrl.USER_GET_SETTING_BY_USERID + "UserId=" + user.id.toString(), 'url', result => {
              
              if (result !== undefined && result !== null) {
                
        
              }
              setWait(false);
            },
              message => {
                //notifyError(message);
                setWait(false);
              }
            );
        
        
          } */


    const loadByID = () => {
        RestCall.getById(RestUrl.USER_GET_BY_ID, props.user.id, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setUser(result.data);
                } else if (typeof result === 'string') {
                    if (result === "VALID_TOKEN") {
                        loadByID();
                    } else {
                        notifyError(result);
                    }
                } else {
                    notifyError("@UNHANDLE_ERROR");
                }
            }
        );
    }

    const loadCliente = (code: string) => {
        setNewUser(CustomerListModel.getDefault());
        if (code !== null && code !== undefined && code.length > 0) {
            RestCall.post_with_result_no_pagination({ codiceCliente: code }, RestUrl.CLIENTE_POST_SEARCHCLIENTIMETODO, 'url').then(
                result => {

                    if (typeof result !== 'undefined' && typeof result !== 'string') {

                        if (result.data !== null && result.data.data !== null) {

                            const newUser = {
                                ...CustomerListModel.getDefault(),
                                codiceCliente: result.data.data.codice,
                                ragioneSociale: result.data.data.ragioneSociale,

                            };
                            setNewUser(newUser);
                        }
                        else {
                            notifyError('@SEARCH_BP_NOT_FOUND');
                        }
                    }
                    if (typeof result === 'string') {
                        if (result === 'VALID_TOKEN') {
                            loadCliente(code);
                        } else {
                            notifyError(result);
                        }
                    }
                }
            )
        }
    }

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>| React.ChangeEvent<HTMLTextAreaElement>) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        loadCliente(value);

    }

    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>| React.ChangeEvent<HTMLTextAreaElement>) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const newUser: CustomerListModel = {
            ...user,
            [name]: value,
            ragioneSociale: '',
        };

        setNewUser(newUser);


    }

    const handleCheckChanges = (evt: CheckboxChangeEvent) => {
        const name = evt.target.name;
        if (typeof name !== 'undefined') {
            const newUser = {
                ...user,
                [name]: evt.target.checked
            };
            setUser(newUser);//aggiunge i nuovi valori all'interno della form

            props.onChange(newUser);//informa la classe padre che la form ha cambiato il suo stato.
        }
    }

    const onDeleteModal = (record: CustomerListModel) => {
        let clientiTracking: CustomerListModel[] = user.clientiTracking.filter(x => x.codiceCliente !== record.codiceCliente);

        const newUser = {
            ...user,
            clientiTracking: clientiTracking,
        };

        setUser(newUser);

        props.onChange(newUser);
    }

    const onPlus = () => {
        if (new_user.ragioneSociale !== '') {
            
            var index = user.clientiTracking.findIndex(x => x.codiceCliente === new_user.codiceCliente);

            if (index <= 0) {
                let clientiTracking: CustomerListModel[] = [];
                if (user.clientiTracking !== undefined && user.clientiTracking !== null && user.clientiTracking.length > 0) {
                    clientiTracking = user.clientiTracking;
                }

                clientiTracking.push(new_user);


                const newUser = {
                    ...user,
                    clientiTracking: clientiTracking,
                };

                setUser(newUser);
                console.log(newUser.clientiTracking, 'setUser')

                props.onChange(newUser);

                setNewUser(CustomerListModel.getDefault());

            } else {

                alert('Cliente già presente');
            }
        }
        else {
            alert('Controllar il codice metodo!');
        }
    }


    return (
        <>
            <div className="container-col">
                <Form>
                    <div className="container-row" style={{ paddingBottom: '10px' }}>
                        <Checkbox defaultChecked={user.enableTracking!} name="enableTracking" onChange={(evt) => handleCheckChanges(evt)} >
                            {TranslateString("@VISIBLE_TRACKING")}
                        </Checkbox>
                    </div>
                    <div className="container-row">
                        <div className="column-3D4">
                            <FormTextInput_old onChange={handleChanges} label_name="CLIENTE_METODO" mandatory={false} input_name={"codiceCliente"} input_value={new_user.codiceCliente!} validate={undefined} onBlur={handleBlur} />
                        </div>
                        <div className="column-1D4" style={{ paddingTop: '28px', paddingLeft: '10px' }}>
                            <Button
                                className="primary-background-ghost"
                                title={TranslateString("@ADD")}
                                style={{ width: '100%' }}
                                //shape="round"
                                size={'small'}
                                icon={<PlusCircleOutlined />}
                                onClick={() => onPlus()}
                            />
                        </div>
                    </div>
                    <div style={{ height: 60 }}>{new_user.ragioneSociale}</div>
                </Form>
            </div>
            <div className="height-100-180">
                <div className="si-table-container height-100 width-100"> {console.log(user.clientiTracking, 'clienti')}
                    <SITableVirtualizedComponent
                        id_univoco={"customer_filter_transfilm"}
                        page={1}
                        page_size={undefined}
                        totalcount={1}
                        data_source={user.clientiTracking}
                        columns={TrackingColumns(() => { }, () => { }, onDeleteModal)}
                        filters={undefined}
                        bordered={true}
                        onPagination={() => { }}
                        select_rows={undefined}
                        onFilterChange={() => { }}
                        onShiftColumn={() => { }}
                        loading={wait}
                        colorRowClick={'#18D5FF'}
                        onRowClass={(record: any, index) => {
                            if (index % 2 == 0) {
                                return " light-gray "
                            } else {
                                return " "
                            }
                        }}
                    />
                </div>
            </div>

        </>
    );
}

