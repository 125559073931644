import React, { useCallback, useMemo } from "react";
import RestUrl from '../../RestUrl.json';
import { Button, Select } from "antd";
import { TranslateString } from "../../i18n/translate";
import { SITableFilterTypeValues } from "@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterController"
import { SITableOrder } from "@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterModel"
import { SITableVirtualizedComponent } from "@si/si-react-components/dist/esm/components/SITable/SITableVirtualized/SITableVirtualizedComponent"
import {
    ReloadOutlined, DownloadOutlined, ClearOutlined,
} from '@ant-design/icons';
import { FilterModel, FilterStructureModel, Order } from "../Model/FilterModel";
import { RestCall } from "../Rest/Rest_Call/RestCall";
import { notifyError } from "../CustomToast/customToast";
import { PreseSearchModel } from "../Model/PreseModel";
import store from '../Redux/Store';
import { UserType } from "../Redux/Reducer";
import { TrackingReducer } from "./TrackingType";
import { TrackingModel } from "../Model/TrackingModel";
import { TrackingColumns } from "./TrackingColumns";
import { useParams } from "react-router-dom";
import { FormDataPickerInput } from "../Utils_Component/InputForm";
import { Moment } from "moment";
import { split_YYYY_MM_YYYY_with_slash_no_null_Record } from "../Utils_Component/FormatDate";

interface UserFormProps {
    idBolla?: number | undefined,
}

var _ = require('lodash');

const { Option } = Select;

export const TrackingList: React.FC<UserFormProps> = props => {
    const [user_type, setUserType] = React.useState<UserType>(store.getState().user_type);
    const [enableDownloadPod] = React.useState<boolean>(store.getState().enableDownloadPod);
    const [loading, setLoading] = React.useState(false);
    //const [reload, setReload] = React.useState(false);
    const [timeReload, setTimeReload] = React.useState(Date.now());
    //const [codiceClienteList, setCodiceClienteList] = React.useState<any[]>([]);
    const [wait, setWait] = React.useState(Boolean);
    const [filters, setFilters] = React.useState<{ filtersSearch: PreseSearchModel, customer: string, forceReload: string }>({ filtersSearch: PreseSearchModel.getDefault(), customer: 'ALL', forceReload: '' });
    const [rifbolla, setRifBolla] = React.useState<number | undefined>(props.idBolla);

    const [state, dispatch] = React.useReducer(TrackingReducer, {
        create_visible: false,
        update_visible: false,
        delete_visible: false,
        record: TrackingModel.getDefault(),
        data_source: [],
        page: 1,
        pageSize: 100,
        totalcount: 0,
        set_date: "",
        where_filter: [],
        orderby_filter: new Order("", "ASC"),
        first_call: true,
        columns: [],
        timer_image_finished: false,
        arr_sel: [],
    });
    const params: any = useParams();

    var timer: any = undefined;


    React.useEffect(
        () => {
            if (state.first_call !== true) {
                loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
            }
        }, []
    );
    React.useEffect(
        () => {
            if (props.idBolla !== undefined) {
                if (state.first_call !== true) {
                    loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
                }
            }
        }, [props.idBolla]
    );


    React.useEffect(
        () => {
            const s = store.getState().user_type;
            setUserType(s);
        }, [store.getState().user_type]
    );



    React.useEffect(
        () => {

            const s = store.getState().user_type;
            setUserType(s);
            if (/*isBackoffice*/store.getState().user_type === 'superuser' || store.getState().user_type === 'backoffice') {
                //loadListaClienti();
            }

            // console.log('first useeffect')
            loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);

            const interval = setInterval(() => setTimeReload(Date.now()), 60000);
            return () => {
                clearInterval(interval);
            };
        }, []);



    React.useEffect(
        () => {
            const s = store.getState().user_type;
            if (state.first_call !== true && (!state.create_visible) && (!state.update_visible) && (!state.delete_visible)) {
                // console.log('reload table')
                loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
            }
        }, [state.create_visible, state.update_visible, state.delete_visible, state.where_filter, state.orderby_filter, state.page, state.pageSize]);


    React.useEffect(
        () => {
            if (state.first_call !== true) {
                if (filters !== undefined && filters.filtersSearch !== undefined) {
                    if ((filters.filtersSearch.daData !== '' && filters.filtersSearch.aData !== '') ||
                        (filters.filtersSearch.daData === '' && filters.filtersSearch.aData === '')) {
                        loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
                    }
                }
            }
        }, [filters]
    );


    React.useEffect(
        () => {
            if (state.first_call !== true) {
                // console.log('useeffect where filter')
                loadtable(state.where_filter, state.orderby_filter, undefined, state.pageSize);
                dispatch({ type: 'SETPAGINATION', page: undefined, pageSize: state.pageSize });
            }
        }, [state.where_filter]
    );
    React.useEffect(
        () => {
            if (state.first_call !== true) {
                // console.log('useeffect orderby')
                loadtable(state.where_filter, state.orderby_filter, undefined, state.pageSize);
                dispatch({ type: 'SETPAGINATION', page: undefined, pageSize: state.pageSize });
            }
        }, [state.orderby_filter]
    );

    /*const loadListaClienti = () => {
        RestCall.post_with_result_no_pagination({ soloClienti: true }, RestUrl.CLIENTE_SEARCH, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setCodiceClienteList(result.data.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaClienti();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    }*/

    const loadtable = (where: FilterStructureModel[], order: Order, page: number | undefined, pageSize: number | undefined, filtro: any = filters) => {
        setLoading(true);

        let filter = { ...filtro.filtersSearch };

        let where_filter = _.filter(where, (item: any) => item.table_View_ColumnName !== 'dataDocumento');

        if (filter !== undefined && filter !== null) {
            if ((filter.aData !== '' && filter.daData !== '') &&
                (filter.aData !== undefined && filter.daData !== undefined)) {

                let data = split_YYYY_MM_YYYY_with_slash_no_null_Record(filter.daData) + ';' + split_YYYY_MM_YYYY_with_slash_no_null_Record(filter.aData);

                where_filter.push(new FilterStructureModel('dataDocumento', 'BETWEEN', "DateTime", data));
            }
        }


        if (props.idBolla !== undefined) {
            where_filter = _.filter(where, (item: any) => item.table_View_ColumnName !== 'id');

            where_filter.push(new FilterStructureModel('id', '=', "Number", props.idBolla.toString()));
        }


        const object = new FilterModel(order, where_filter, []);

        RestCall.postWithReturn(object, RestUrl.TRACKING_SEARCH, page, pageSize, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    if (typeof result.data !== 'undefined' && result.data !== 'string') {
                        //const ds = result.data.data.map((el: any, index: any) => { return { ...el, key: index } });
                        dispatch({ type: 'SETDATASOURCETOTALCOUNT', value: result.data, totalcount: result.headers.x_pagination });
                        setLoading(false);
                    }
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadtable(where, order, page, pageSize);
                    } else {
                        notifyError(result);
                        setLoading(false);
                    }
                } else {
                    notifyError("@UNHANDLE_ERROR");
                    setLoading(false);
                }
            }
        );
    }
    const handleChangesFilterClear = (value: any) => {
        setFilters({ filtersSearch: PreseSearchModel.getDefault(), customer: 'ALL', forceReload: Date.now().toString() });
        dispatch({ type: 'SETFILTERWHERE_CLEAR' });
    }

    const handlePagination = (page: number | undefined, page_size?: number | undefined) => {//gestisce la paginazione
        dispatch({ type: 'SETPAGINATION', page: page, pageSize: page_size });
        loadtable(state.where_filter, state.orderby_filter, page, page_size);
    }

    //START filter handler
    const handleFilter = (value: string, sql_name: string, operator: string, filter_type: SITableFilterTypeValues) => { //gestisce i filtri delle colonne di tipo stringa 

        /*         if (sql_name === 'nRifBollaMittente') {
                    setRifBolla(undefined);
                } */

        dispatch({ type: 'SETFILTERWHERE', where_filter: new FilterStructureModel(sql_name, operator, filter_type, value) });
    }
    const handleOrderBy = (order_by: SITableOrder) => {//permette di effettuare l'ordinamento per colonna ASC/DESC
        dispatch({ type: 'SETORDERBYFILTER', orderby_filter: new Order(order_by.table_View_ColumnName, order_by.mode) });
    }
    //END filter handler

    const handleDataChanges = (field: string, event: any, value?: any) => {
        const newDate = event !== null ? (event as Moment).format('YYYY-MM-DD[T]HH:mm:ss') : '';

        setFilters(
            {
                filtersSearch: {
                    ...filters.filtersSearch,
                    [field]: newDate
                },
                customer: filters.customer,
                forceReload: Date.now().toString()
            }
        );

    }

    const containsSpecialChars = (str: string) => {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return specialChars.test(str);
    }

    const onOpenFile = (record: TrackingModel) => {
        if (!enableDownloadPod) return;
        setWait(true);
        const nomeFile = containsSpecialChars(record.id.toString()) ? record.barcode.toString() : `${record.barcode.toString()}-${record.nRifBollaMittente.toString()}`
        RestCall.downloadPdf({}, RestUrl.TRACKING_GETFILE + "?idBolla=" + record.id, 'ok', 'url', `${nomeFile}.pdf`).then(
            result => {
                if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        onOpenFile(record);
                    } else if (result !== 'ok') {
                        notifyError("@NOTFOUND");
                    }
                } else {
                    notifyError("@NOTFOUND")
                }
                setWait(false);
            }
        );
    }

    const onOpenFiles = () => {

        setWait(true);

        let namefile = "tracking.zip";
        if (state.arr_sel !== undefined && state.arr_sel.length === 1) {
            try {
                namefile = state.arr_sel[0].toString() + ".zip";
            } catch { }
        }
        RestCall.downloadPdf(state.arr_sel, RestUrl.TRACKING_GETFILES, 'ok', 'url', namefile).then(
            result => {
                if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        onOpenFiles();
                    } else if (result !== 'ok') {
                        notifyError("@NOTFOUND");
                    }
                } else {
                    notifyError("@NOTFOUND")
                }
                setWait(false);
            }
        ).finally(() => dispatch({ type: "CLEARARRAY" })  );

    }

const selezionaTracking = (e: any, row: TrackingModel) => {
    var arr_sel = state.arr_sel;
    if (e.target.checked) {

        if (arr_sel.indexOf(row.id) < 0) {
            arr_sel.push(row.id);
        }
    } else {
        arr_sel.splice(arr_sel.indexOf(row.id), 1);
    }

    dispatch({ type: 'SETARRSEL', arr_sel });
    e.target.checked = !e.target.checked;
}

const cancellaSelezione = () => {
    dispatch({ type: 'CLEARARRAY' });
    loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
}

const seleziona_tutto = () => {
    if(state.arr_sel.length >= 100) return;
    dispatch({ type: 'SELECT_ALL' });

    loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
}

const columnsMemo = useMemo(() => {
    return TrackingColumns(handleOrderBy, handleFilter, onOpenFile, rifbolla, selezionaTracking, state.arr_sel, seleziona_tutto, enableDownloadPod)
},[filters.forceReload, state.arr_sel, rifbolla, enableDownloadPod]);

return (
    <>
        <div className="container-col desktop-flex height-100">
            {/*<div><h1 className="title-page">{TranslateString("@ANAGRAFICA_TITLE")}</h1></div>*/}
            <div className="scrollable-calc">
                {props.idBolla === undefined &&
                    <div className="desktopBody">
                        <div className="container-row" style={{ paddingBottom: '10px' }}>
                            <div className="column-1D12 container-col margin-right-5">
                                <FormDataPickerInput
                                    onChangeDataPicker={(event: any, value?: any) => handleDataChanges('daData', event, value)}
                                    label_name="DATA_BOLLA_DA"
                                    input_name={"daData"}
                                    date_value={filters.filtersSearch.daData} validate={undefined} mandatory={false} />
                            </div>
                            <div className="column-1D12 container-col margin-right-5">
                                <FormDataPickerInput
                                    onChangeDataPicker={(event: any, value?: any) => handleDataChanges('aData', event, value)}
                                    label_name="DATA_BOLLA_A"
                                    input_name={"aData"}
                                    date_value={filters.filtersSearch.aData} validate={undefined} mandatory={false}
                                />
                            </div>
                            <div className="column-1D4 container-col margin-right-5">
                                {/*  <div className="column-1">
                                    <label className="label-font" htmlFor={'filterCustomer'}>Cliente</label>
                                    <label className="label-font label-red" htmlFor={'filterCustomer'}> *</label>
                                </div>
                                <Select
                                    showSearch
                                    style={{ width: 300 }}
                                    placeholder={'Seleziona il ciente'}
                                    optionFilterProp="children"
                                    onChange={handleChangeCodiceClienteFilter}
                                    value={filters.customer}
                                    defaultValue={'ALL'}
                                >
                                    <Option key={'ALL'} value={'ALL'}>TUTTI</Option>
                                    {
                                        codiceClienteList.map(
                                            (item: any) =>
                                                <Option key={item.codiceCliente} value={item.codiceCliente}>
                                                    {item.codiceCliente + ' - ' + item.clienteMetodo?.ragioneSociale}
                                                </Option>
                                        )
                                    }
                                </Select> */}
                            </div>
                            {/*                                 <div className="column-1D8   margin-right-5">

                                </div> */}



                            <div className="column-1D2" style={{ alignSelf: 'center' }}>
                                <Button className="table-reload-button" style={{ width: '150px', marginLeft: '5px' }} onClick={() => loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize)} ><ReloadOutlined />{TranslateString("@RELOAD_TABLE")}</Button>
                                <Button className="table-reload-button" style={{ width: '150px', marginLeft: '5px', marginRight: '5px' }} onClick={handleChangesFilterClear}><ClearOutlined />{TranslateString("@CLEAR")}</Button>
                                {state.arr_sel.length > 0 &&
                                    <>
                                        <Button type="primary" className="table-reload-button" style={{ width: '150px', marginLeft: '5px', marginRight: '5px' }} onClick={onOpenFiles}><DownloadOutlined />{TranslateString("@DOWNLOAD")}</Button>
                                        <Button className="table-reload-button" style={{ width: '150px', marginLeft: '5px', marginRight: '5px' }} onClick={cancellaSelezione}><ClearOutlined />{TranslateString("@CLEAR_ARRAY")}</Button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                }

                <div className="height-100-70">
                    <div className="si-table-container height-100 width-100">
                        <SITableVirtualizedComponent
                            id_univoco={"tracking_filter_transfilm"}
                            page={state.page}
                            page_size={state.pageSize}
                            totalcount={state.totalcount}
                            data_source={state.data_source}
                            columns={columnsMemo}
                            filters={undefined}
                            bordered={true}
                            onPagination={handlePagination}
                            select_rows={undefined}
                            onFilterChange={() => { }}
                            onShiftColumn={() => { }}
                            loading={loading}
                            colorRowClick={'#18D5FF'}
                            onRowClass={(record: any, index) => {
                                if (index % 2 == 0) {
                                    return " light-gray "
                                } else {
                                    return " "
                                }
                            }}
                        />
                    </div>
                </div>
            </div >
        </div >
    </>
);
}