import { PresaPortDTO } from "../Model/PreseModel";
import { FilterStructureModel, Order } from "../Model/FilterModel";
import { TrackingModel } from "../Model/TrackingModel";

var _ = require('lodash');


interface actionTypeCreateVisible {
    type: "SETVISIBLECREATE",
    visible: boolean
}
interface actionTypeUpdateVisible {
    type: "SETVISIBLEUPDATE",
    visible: boolean
}
interface actionTypeDeleteVisible {
    type: "SETVISIBLEDELETE",
    visible: boolean
}
interface actionTypeSetValidator {
    type: "SETVALIDATOR",
    validator: any
}
interface actionTypeSetDataSource {
    type: "SETDATASOURCE",
    value: any
}
interface actionTypeSetPagination {
    type: "SETPAGINATION",
    page: number | undefined,
    pageSize: number | undefined
}
interface actionTypeSetTotalcount {
    type: "SETTOTALCOUNT",
    totalcount: number,
}
interface actionTypeSetDataSourceTotalcount {
    type: "SETDATASOURCETOTALCOUNT",
    value: any,
    totalcount: number,
}
interface actionTypeSetRecord {
    type: "SETRECORD",
    value: any
}
interface actionTypeSetFilterWhere {
    type: "SETFILTERWHERE",
    where_filter: FilterStructureModel,
}
interface actionTypeSetOrderbyFilter {
    type: "SETORDERBYFILTER",
    orderby_filter: Order,
}
interface actionTypeSetColumns {
    type: "SETCOLUMNS",
    value: any[],
}
interface actionTypeSetUploadVisible {
    type: 'SETUPLOADVISIBLE',
    visible: boolean
}
interface actionTypeSetImageCall {
    type: 'SETIMAGECALL',
    value: boolean
}
interface actionTypeSetArraySelect {
    type: 'SETARRSEL',
    arr_sel: number[],
}
interface actionTypeSetClearArray {
    type: 'CLEARARRAY',
}
interface actionTypeSetSelectAllArray {
    type: 'SELECT_ALL',
}

interface actionTypeSetWhereClear {
    type: 'SETFILTERWHERE_CLEAR',
}

export type actions = actionTypeCreateVisible | actionTypeSetValidator | actionTypeSetFilterWhere
    | actionTypeSetDataSource | actionTypeDeleteVisible | actionTypeUpdateVisible
    | actionTypeSetPagination | actionTypeSetTotalcount | actionTypeSetDataSourceTotalcount
    | actionTypeSetRecord
    | actionTypeSetOrderbyFilter
    | actionTypeSetColumns
    | actionTypeSetUploadVisible
    | actionTypeSetImageCall
    | actionTypeSetArraySelect
    | actionTypeSetClearArray
    | actionTypeSetSelectAllArray
    | actionTypeSetWhereClear
    ;


interface IReducer {
    create_visible: boolean,
    update_visible: boolean,
    delete_visible: boolean,
    record: TrackingModel,
    data_source: TrackingModel[],
    totalcount: number,
    page: number | undefined,
    pageSize: number | undefined,
    set_date: string,
    where_filter: FilterStructureModel[],
    orderby_filter: Order,
    columns: any[],
    first_call: boolean,
    timer_image_finished: boolean,
    arr_sel: number[],
}

export function TrackingReducer(state: IReducer, action: actions): IReducer {

    switch (action.type) {
        case 'SETIMAGECALL':
            return { ...state, timer_image_finished: action.value }
        case 'SETCOLUMNS':
            return { ...state, columns: action.value }
        case 'SETARRSEL':
            return { ...state, arr_sel: _.clone(action.arr_sel) }
        case 'CLEARARRAY':
            return { ...state, arr_sel: [] }
        case 'SELECT_ALL':
            let array = state.arr_sel;

            if (state.data_source !== undefined && state.data_source.length > 0) {
                for (var i = 0; i < state.data_source.length; i++) {
                    if (array.indexOf(state.data_source[i].id) < 0) {
                        array.push(state.data_source[i].id);
                    }

                    if (array.length >= 100) {
                        break;
                    }
                };
            }

            return { ...state, arr_sel: array }
        case 'SETRECORD':
            return { ...state, record: action.value }
        case 'SETVISIBLECREATE':
            return { ...state, create_visible: action.visible }
        case 'SETVISIBLEUPDATE':
            return { ...state, update_visible: action.visible }
        case 'SETVISIBLEDELETE':
            return { ...state, delete_visible: action.visible }
        case 'SETDATASOURCE':
            return { ...state, data_source: action.value }
        case 'SETTOTALCOUNT':
            return { ...state, totalcount: action.totalcount }
        case 'SETPAGINATION':
            return { ...state, page: action.page, pageSize: action.pageSize }
        case 'SETDATASOURCETOTALCOUNT':
            return { ...state, totalcount: action.totalcount, data_source: action.value, first_call: false }
        case 'SETFILTERWHERE':
            const where_array: FilterStructureModel[] = _.filter(state.where_filter, (item: FilterStructureModel) => item.table_View_ColumnName !== action.where_filter.table_View_ColumnName);
            if (action.where_filter.value !== "") {
                const s = {
                    ...state, where_filter: [
                        ...where_array,
                        action.where_filter
                    ], first_call: false
                }
                return s;
            }

            return { ...state, where_filter: where_array, first_call: false }
        case 'SETFILTERWHERE_CLEAR':
            

            return { ...state, where_filter: [], first_call: false, page: 1 }
        case 'SETORDERBYFILTER':
            return { ...state, orderby_filter: action.orderby_filter, first_call: false }
        /*         case 'SETORDERBYFILTER':
                    const order_filter: Order[] = _.filter(state.orderby_filter, (item: Order) => item.table_View_ColumnName !== action.orderby_filter.table_View_ColumnName);
        
                    return { ...state, orderby_filter: [...order_filter, action.orderby_filter], first_call: false } */
        default:
            return { ...state }

    }
}

