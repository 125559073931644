import { Alert, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { BannerModel } from "../Model/Banner";
import Marquee from "react-fast-marquee";
import { useLocation } from 'react-router-dom'
import { getRouteID } from "../../utils/GetRouteID";
const { Option } = Select;

interface TimedBannerComponentProps {
    banners: BannerModel[]
}

interface BannerComponentProps {
    banners: BannerModel[]
}

const TimedBanner: React.FC<TimedBannerComponentProps> = ({ banners }) => {
    const [current, setCurrent] = React.useState<number>(0);
    useEffect(() => {
        const tick = () => setCurrent((i: number) => {
            if(i > banners.length) return 0;
            else return i + 1;
        });

        const id = setInterval(tick, 30000);
        return () => clearInterval(id);
    }, []);


    const bannerCurrentMemo = useMemo(() => {
        return banners[current % banners.length];
    }, [banners, current]);

    return <>
        <Alert
            style={{ width: "700px" }}
            message={
                <Marquee
                    pauseOnHover
                    gradient={false}
                >
                    <strong>{bannerCurrentMemo.titolo}</strong>&nbsp;
                    {bannerCurrentMemo.descrizione}&nbsp;&nbsp;&nbsp;
                </Marquee>
            }
            type={bannerCurrentMemo.tipo === 'Warning' ? 'warning' : 'info'}
            showIcon 
        />
    </>
}


export const BannerComponent: React.FC<BannerComponentProps> = props => {
    const location = useLocation();


    const currentBannersMemo = useMemo(() => {
        const findBanner = props.banners.filter(banner => banner.sezione === getRouteID(location.pathname));
        if(findBanner.length > 0) return findBanner;
        else return props.banners.filter(banner => banner.sezione === 0);
    }, [location.pathname, props.banners]);


    if (props.banners.length <= 0 || currentBannersMemo.length <= 0) return <></>;
    return <TimedBanner banners={currentBannersMemo} />;
}