import { Alert, Button, Descriptions, Empty, Form, Input, Spin, Table } from "antd"
import { TranslateString } from "../../i18n/translate";
import React, { useEffect, useState } from "react";
import store from "../Redux/Store";
import { SearchOutlined } from '@ant-design/icons';
import { Link, useHistory, useParams } from "react-router-dom";
import { SearchShippingReducer } from "./SearchShippingType";
import { RestCall } from "../Rest/Rest_Call/RestCall";
import { notifyError } from "../CustomToast/customToast";
import RestUrl from "../../RestUrl.json";
import { SearchShippingColumns } from "./SearchShippingColumns";
import { FormateDateTimeWith00 } from "../../utils/FormatDate";
import Title from "antd/lib/typography/Title";
import { useLocation } from "react-router-dom";

export const SearchShippingComponent = () => {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const barcode = queryParams.get("barcode");

    const history = useHistory();
    const [barcodeValue, setBarcodeValue] = useState(barcode ?? "");
    const [isLogged] = React.useState(store.getState().isLogged);
    const [state, dispatch] = React.useReducer(SearchShippingReducer, {
        loading: false,
        barcode: null,
        header: null,
        list: []
    });

    const getShippingState = (barcode: any) => {
        dispatch({ type: "SETLOADING", loading: true });
        RestCall.getWithEasyAccess(`${RestUrl.GET_SHIPPING_STATUS_BY_BARCODE}/${barcode}`, "url", (res) => {
            dispatch({ type: "SETDATA", data: res });
            dispatch({ type: "SETLOADING", loading: false });
        }, (message) => {
            dispatch({ type: "SETDATA", data: null });
            dispatch({ type: "SETLOADING", loading: false });
        });
    }

    useEffect(() => {
        setBarcodeValue(barcode ?? "");
        if (barcode) getShippingState(barcode);
        dispatch({ type: "SETDATA", data: null });
    }, [barcode])


    const handleSubmit = () => {//gestisce la chiamata da tastiera
        //queryParams.set("barcode", barcodeValue);
        history.push("/CercaSpedizione?barcode=" + barcodeValue);
    }
    const handleChangeInputValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setBarcodeValue(value);
    }




    return <>
                <div
                    className={`${isLogged ? "" : "LoginForm"} height-100 width-100`}
                    style={{
                        backgroundColor: 'white',
                        overflowY: "auto"
                    }}
                >
                    <Form
                        className="container-row margin-0"
                        style={{
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            alignItems: "center",
                            borderBottom: "4px #e5e7eb solid",
                            padding: "1rem",
                            marginBottom: "10px"
                        }}
                        onFinish={handleSubmit}
                    >
                        <h1 className="title-page margin-0 container-row c-ctr">{TranslateString("@TITLE_SEARCH_TRACKING")}</h1>
                        <div className="container-row c-ctr " >
                            <Form.Item label={TranslateString("@LABEL_SEARCH_TRACKING")} className="margin-0">
                                <Input.Group compact>
                                    <Input
                                        style={{ width: 'calc(100% - 50px)' }}
                                        value={barcodeValue}
                                        onChange={handleChangeInputValue}
                                        disabled={state.loading}
                                        placeholder="Inserisci Barcode"
                                        allowClear
                                    />
                                    <Button
                                        loading={state.loading}
                                        icon={<SearchOutlined />}
                                        type="primary"
                                        htmlType="submit"
                                    />
                                </Input.Group>
                            </Form.Item>


                        </div>
                    </Form>
                    <div className="height-100-150 padding-x-20">
                        <Spin spinning={state.loading}>
                            {(state.barcode) ?
                                <div>
                                    <Title
                                        level={4}
                                        style={{ padding: "10px" }}>
                                        {TranslateString("@LABEL_SEARCH_TRACKING")}
                                        <span style={{ fontStyle: "italic", fontSize: "0.8em", fontWeight: "lighter" }}> {state.barcode}</span>
                                    </Title>
                                    {state.header &&
                                        <Descriptions
                                            labelStyle={{ fontWeight: "700" }}
                                            layout="vertical"
                                            column={{ xs: 6, md: 6, lg: 12 }}
                                            bordered
                                        >
                                            <Descriptions.Item span={3} label={TranslateString("@NRIFBOLLAMITTENTE_LABEL_SEARCH_TRACKING")}>{state.header.nrifbollamittente}</Descriptions.Item>
                                            <Descriptions.Item span={3} label={TranslateString("@DATAINSERIMENTO_LABEL_SEARCH_TRACKING")}>{FormateDateTimeWith00(state.header.datainserimento)}</Descriptions.Item>
                                            <Descriptions.Item span={3} label={TranslateString("@RAGIONESOCIALEMITTENTE_LABEL_SEARCH_TRACKING")}>{state.header.ragionesocialemittente}</Descriptions.Item>
                                            <Descriptions.Item span={3} label={TranslateString("@RAGIONESOCIALEDESTINATARIO_LABEL_SEARCH_TRACKING")}>{state.header.ragionesocialedestinatario}</Descriptions.Item>
                                        </Descriptions>
                                    }

                                    <div style={{
                                        overflowY: "auto",
                                        marginTop: "20px"
                                    }}>
                                        <Table
                                            dataSource={state.list}
                                            columns={SearchShippingColumns()}
                                            pagination={false}
                                            loading={state.loading}
                                            scroll={{ y: 400 }}
                                        />
                                    </div>
                                </div>
                                :
                                <div className="container-row height-100 c-ctr">
                                    <Empty description={TranslateString("@EMPTY_MESSAGE_SEARCH_TRACKING")} />
                                </div>
                            }
                        </Spin>
                    </div>
                </div>
    </>
}