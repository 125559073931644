import { Avatar, Button, Modal, Popover } from "antd";
import React from "react";
import { TranslateString } from "../../../i18n/translate";
import store from "../../Redux/Store";
import {
    UserSwitchOutlined, LeftOutlined,FilePdfOutlined
} from '@ant-design/icons';
import { ChangePasswordModel } from "../../Model/UsersModel";
import { ChangePswValidator, checkChangePswValidField, setChangePswValidField } from "../../Users/UsersInterface";
import { ChangePswForm } from "../../Users/ChangePasswordForm";
import { RestCall } from "../../Rest/Rest_Call/RestCall";
import RestUrl from '../../../RestUrl.json';
import { notifyError, notifySucces } from "../../CustomToast/customToast";
import { ChangeUrl } from "../../../utils/ChangeUrl";

interface IAvatarComponent {
    className?: string,
    mobile?: boolean,
    mobileCallback?: () => void
}

export const AvatarComponent: React.FC<IAvatarComponent> = props => {
    const [visible, setVisible] = React.useState(false);
    const [color, setColor] = React.useState("#00b126");
    const [user, setUser] = React.useState(ChangePasswordModel.getDefault());
    const [validator_change_psw, setValidatorChangePsw] = React.useState(ChangePswValidator.getDefault());
    const [change_psw_visible, setChangePswVisible] = React.useState(false);

    const handleVisibleChange = (visible: boolean) => {//rende visibile/invisibile il popover
        setVisible(visible);
    };

    const handleLogout = () => {
        RestCall.getAll(RestUrl.AUTHENTICACTION_LOGOUT, 'login_url').then(
            result => {
                if (typeof result === 'string') {
                    if (result === '') {//le API ritornano una stringa vuota se va tutto bene
                        store.dispatch({ type: 'SETLOGIN', isLogged: false });
                        store.dispatch({ type: 'SETPERMISSIONS', user_type: undefined });
                        ChangeUrl('');
                    } else {
                        notifyError(result);
                    }
                }
            }
        );

    }

    //HANDLE CHANGE PSW MODAL
    const showChangePasswod = () => {
        setChangePswVisible(true);
        setUser(ChangePasswordModel.getDefault());
        setValidatorChangePsw(ChangePswValidator.getDefault());
    }
    const handleChangePswOk = () => {
       
       // attenzione non è l'id ma lo userID, da fixare se riattivata
        setValidatorChangePsw(setChangePswValidField(user));
        if (checkChangePswValidField(setChangePswValidField(user))) {
            RestCall.post_with_result_no_pagination(user, RestUrl.USER_CHANGE_PSW, 'login_url').then(
                result => {
                    if (typeof result !== 'undefined' && typeof result !== 'string') {
                        if (typeof result.data === 'string') {
                            if (result.data === '') {
                                setChangePswVisible(false);
                                notifySucces("@V_CHANGE_PSW");
                            } else {
                                notifyError(result.data);
                            }
                        }
                    }
                    if (typeof result === 'string') {
                        if (result === 'VALID_TOKEN') {
                            handleChangePswOk();
                        } else {
                            notifyError(result);
                        }
                    }
                }
            )
        }
    }
    const handleChagePswCancel = () => {
        setChangePswVisible(false);
    }
    const handleChangePswChange = (value: ChangePasswordModel) => {
        setUser(value);
    }
    //END CHANGE PSW MODAL

    //START MOBILE MODAL
    const showMobileModal = () => {
        if (typeof props.mobileCallback !== 'undefined')
            props.mobileCallback();
    }


    //END MOBILE MODAL

    const handleDownload = () => {
        // using Java Script method to get PDF file
        fetch('guida.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'TRANSFILM - Presentazione Portale Prese.pdf';
                alink.click();
            })
        })
    }

    return (
        <>
            {
                typeof props.mobile !== 'undefined' ?
                    <Avatar className={props.className} style={{ backgroundColor: 'white', color: color, fontSize: "20px", float: 'right' }}> <UserSwitchOutlined onClick={showMobileModal} /></Avatar>
                    :
                    <Popover
                        placement='topLeft'
                        content={
                            <div className="container-col">
                                <Button style={{marginBottom:30}} onClick={handleDownload} ><FilePdfOutlined />{TranslateString("@DOWNLOAD_GUIDE")}</Button>

                                <Button style={{marginBottom:5}} onClick={handleLogout} >{TranslateString("@LOGOUT")}</Button>
                                <Button onClick={showChangePasswod} >{TranslateString("@CHANGE_PASSWORD")}</Button>
                            </div>
                        }
                        title={undefined}
                        trigger="click"
                        visible={visible}
                        onVisibleChange={handleVisibleChange}
                    >
                        <Avatar className={props.className} style={{ backgroundColor: 'white', color: color, fontSize: "20px", float: 'right' }}> <UserSwitchOutlined /></Avatar>
                    </Popover>
            }
            {change_psw_visible &&
                <Modal
                    wrapClassName="modal-xm"
                    title={TranslateString("@CHANGE_PASSWORD")}
                    visible={change_psw_visible}
                    onOk={handleChangePswOk}
                    onCancel={handleChagePswCancel}
                    closeIcon={<LeftOutlined className="mobile-body" />}
                    keyboard={false}
                    maskClosable={false}
                    width="400px"
                >
                    <ChangePswForm show_old_psw={true} user={ChangePasswordModel.getDefault()} validator={validator_change_psw} onChange={handleChangePswChange} />
                </Modal>
            }

        </>
    )
}