export const getRouteID = (route: string): number => {
    switch (route) {
        case "/CercaSpedizione":
            return 1;
        case "/Tracking":
            return 2;
        case "/Prese":
            return 3;
        case "/RubricaMittenti":
            return 4;
        case "/RubricaOperatori":
            return 5;
        case "/Users":
            return 6;
        default:
            return 0;
    }
}