
import axios, { AxiosError, ResponseType } from 'axios';
import store from '../../Redux/Store';
import { getTranslateData } from '../../../utils/GetPublicUrl';
import RestUrl from '../../../RestUrl.json';

export type DownloadType = ResponseType | undefined;

export type ServerUrlType = 'login_url' | 'url';

let semaphore = false;
export class RestCall {


    static PostRefreshToken = async (): Promise<any> => {
        if (semaphore) {
            return Promise.resolve("VALID_TOKEN");
        }

        semaphore = true;

        const config = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        if (localStorage.getItem("login_url") !== null) {
            return axios.get(
                localStorage.getItem("login_url") + RestUrl.AUTHENTICATION_REFRESH,
                config
            ).then(result => {
                semaphore = false;
                return "VALID_TOKEN";
            }
            ).catch(function (error) {
                if (typeof error.response === 'undefined') {
                    semaphore = false;
                    return "@UNHANDLE_ERROR";
                } else if (error.response!.status === 500) {
                    semaphore = false;
                    return "VALID_TOKEN";
                } else {
                    store.dispatch({ type: 'SETLOGIN', isLogged: false });
                    semaphore = false;
                    return "@NO_VALID_TOKEN";
                }
            });
        } else {
            getTranslateData();
            return axios.get(
                localStorage.getItem("login_url") + RestUrl.AUTHENTICATION_REFRESH,
                config
            ).then(result => {
                semaphore = false;
                return "VALID_TOKEN";
            }
            ).catch(function (error) {
                if (typeof error.response === 'undefined') {
                    semaphore = false;
                    return "@UNHANDLE_ERROR";
                } else if (error.response!.status === 500) {
                    semaphore = false;
                    return "VALID_TOKEN";
                } else {
                    store.dispatch({ type: 'SETLOGIN', isLogged: false });
                    semaphore = false;
                    return "@NO_VALID_TOKEN";
                }

            });
        }

    }


    static getAll = (Url: string, server_url: ServerUrlType): Promise<any> => {

        const config = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',

            }
        }
        if (localStorage.getItem(server_url) !== null) {

            return axios.get(
                localStorage.getItem(server_url) + Url,
                config
            ).then(

                (response) => {

                    const result = typeof response !== 'undefined' ? response.data : [];
                    return result;
                }
            ).catch(
                function (error: AxiosError) {
                    console.log(error);

                    if (typeof error.response === 'undefined') {
                        return undefined;
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }

                }
            );
        } else {

            getTranslateData();
            return axios.get(
                localStorage.getItem(server_url) + Url,
                config
            ).then(

                (response) => {

                    const result = typeof response !== 'undefined' ? response.data : [];
                    return result;
                }
            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return undefined;
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }

                }
            );
        }

    }

    static getAll_pagination = (Url: string, page: number | undefined, pageSize: number | undefined, server_url: ServerUrlType): Promise<any> => {

        const PageSize = typeof pageSize === 'undefined' ? 10 : pageSize;
        const PageNumber = typeof page === 'undefined' ? 1 : page;

        const config = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',

            }
        }
        if (localStorage.getItem(server_url) !== null) {

            return axios.get(
                localStorage.getItem(server_url) + Url + `?Page=${PageNumber}&Size=${PageSize}`,
                config
            ).then(

                (response) => {

                    const result = response;
                    return result;
                }
            ).catch(
                function (error: AxiosError) {

                    if (typeof error.response === 'undefined') {
                        return undefined;
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }

                }
            );
        } else {

            getTranslateData();
            return axios.get(
                localStorage.getItem(server_url) + Url + `?Page=${PageNumber}&Size=${PageSize}`,
                config
            ).then(

                (response) => {

                    const result = response;
                    return result;
                }
            ).catch(
                function (error: AxiosError) {

                    if (typeof error.response === 'undefined') {
                        return undefined;
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }

                }
            );
        }

    }

    static getToken = (bodyParameters: any, Url: string, server_url: ServerUrlType): Promise<any> => {

        const config = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        }

        if (localStorage.getItem(server_url) !== null) {

            return axios.post(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(

                (response) => {

                    const result = typeof response !== 'undefined' ? response.data : [];
                    return result;
                }
            ).catch(
                function (error: AxiosError) {

                    if (typeof error.response === 'undefined') {
                        return undefined;
                    } else if (error.response!.status === 401) {
                        return "@WRONG_PASSWORD";
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.post(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(

                (response) => {

                    const result = typeof response !== 'undefined' ? response.data : [];
                    return result;
                }
            ).catch(
                function (error: AxiosError) {

                    if (typeof error.response === 'undefined') {
                        return undefined;
                    } else if (error.response!.status === 401) {
                        return "@WRONG_PASSWORD";
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        }
    }

    static getById = (Url: string, id: number | string, server_url: ServerUrlType): Promise<any> => {
        const config = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        }

        if (localStorage.getItem(server_url) !== null) {
            return axios.get(
                localStorage.getItem(server_url) + Url + `${id}`,
                config
            ).then(

                (response) => {

                    const result = typeof response !== 'undefined' ? response.data : [];

                    return result;
                }
            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return undefined;
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.get(
                localStorage.getItem(server_url) + Url + `${id}`,
                config
            ).then(

                (response) => {

                    const result = typeof response !== 'undefined' ? response.data : [];

                    return result;
                }
            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return undefined;
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        }
    }

    static post = async (bodyParameters: any, Url: string, successMessage: string | undefined, server_url: ServerUrlType): Promise<any> => {

        const config = {
            withCredentials: true,
            headers: {
            }
        }
        if (localStorage.getItem(server_url) !== null) {
            return axios.post(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(function (result) { return successMessage !== undefined ? successMessage : result; }
            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return "@UNHANDLE_ERROR";
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return { status: handleError(error.response!.status), body: error.response };
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.post(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(function (result) { return successMessage !== undefined ? successMessage : result; }
            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return "@UNHANDLE_ERROR";
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return { status: handleError(error.response!.status), body: error.response };
                    }
                }
            );
        }
    }


    static download = async (bodyParameters: any, Url: string, successMessage: string, server_url: ServerUrlType): Promise<string> => {
        const downloadtype: DownloadType = 'blob'
        const config = {
            withCredentials: true,
            headers: {
            },
            responseType: downloadtype
        }
        if (localStorage.getItem(server_url) !== null) {
            return axios.post(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(
                result => {
                    try {
                        const url = window.URL.createObjectURL(new Blob([result.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'incassi.csv'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        return successMessage;
                    } catch {
                        return "@UNHANDLE_ERROR";
                    }

                }

            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return "@UNHANDLE_ERROR";
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.post(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(
                result => {

                    try {
                        const url = window.URL.createObjectURL(new Blob([result.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'leads.csv'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        return successMessage;
                    } catch {
                        return "@UNHANDLE_ERROR";
                    }

                }
            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return "@UNHANDLE_ERROR";
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        }
    }

    static post_with_result_no_pagination = (bodyParameters: any, Url: string, server_url: ServerUrlType): Promise<any> => {

        const config = {
            withCredentials: true,
            headers: {
            }
        }
        if (localStorage.getItem(server_url) !== null) {
            return axios.post(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(
                result => {
                    return result;
                }
            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return undefined;
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.post(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(
                result => {
                    return result;
                }
            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return undefined;
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        }
    }

    static postWithReturn = async (bodyParameters: any, Url: string, page: number | undefined, pageSize: number | undefined, server_url: ServerUrlType): Promise<any> => {
        const PageSize = typeof pageSize === 'undefined' ? 10 : pageSize;
        const PageNumber = typeof page === 'undefined' ? 1 : page;

        const config = {
            withCredentials: true,
            headers: {
            }
        }
        if (localStorage.getItem(server_url) !== null) {
            return axios.post(
                localStorage.getItem(server_url) + Url + `?Page=${PageNumber}&Size=${PageSize}`,
                bodyParameters,
                config
            ).then(
                result => {
                    return result;
                }
            ).catch(
                function (error: AxiosError) {

                    if (typeof error.response === 'undefined') {

                        return "@UNHANDLE_ERROR";
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.post(
                localStorage.getItem(server_url) + Url + `?Page=${PageNumber}&Size=${PageSize}`,
                bodyParameters,
                config
            ).then(
                result => {
                    return result;
                }
            ).catch(
                function (error: AxiosError) {

                    if (typeof error.response === 'undefined') {

                        return "@UNHANDLE_ERROR";
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        }
    }

    static Put = async (bodyParameters: any, Url: string, successMessage: string | undefined, server_url: ServerUrlType): Promise<any> => {
        const config = {
            withCredentials: true,
            headers: {
            }
        }
        if (localStorage.getItem(server_url) !== null) {
            return axios.put(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(function (result) {
                return successMessage !== undefined ? successMessage : result;
            }
            ).catch(
                function (error: AxiosError) {

                    if (typeof error.response === 'undefined') {
                        return "@UNHANDLE_ERROR";
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return { status: handleError(error.response!.status), body: error.response };
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.put(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(function (result) {
                return successMessage !== undefined ? successMessage : result;
            }
            ).catch(
                function (error: AxiosError) {

                    if (typeof error.response === 'undefined') {
                        return "@UNHANDLE_ERROR";
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return { status: handleError(error.response!.status), body: error.response };
                    }
                }
            );
        }
    }
    static put_with_result_no_pagination = (bodyParameters: any, Url: string, server_url: ServerUrlType): Promise<any> => {

        const config = {
            withCredentials: true,
            headers: {
            }
        }
        if (localStorage.getItem(server_url) !== null) {
            return axios.put(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(
                result => {
                    return result;
                }
            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return undefined;
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.put(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(
                result => {
                    return result;
                }
            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return undefined;
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        }
    }

    static Delete = async (bodyParameters: any, Url: string, successMessage: string, server_url: ServerUrlType): Promise<string> => {
        const config = {
            withCredentials: true,
            headers: {
            }
        }
        if (localStorage.getItem(server_url) !== null) {
            return axios.put(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(function (result) {
                return successMessage;
            }
            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return "@UNHANDLE_ERROR";
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.put(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(function (result) {
                return successMessage;
            }
            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return "@UNHANDLE_ERROR";
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        }
    }


    static DeleteByID = async (Url: string, id: number | string, successMessage: string, server_url: ServerUrlType): Promise<string> => {
        const config = {
            withCredentials: true,
            headers: {
            }
        }
        if (localStorage.getItem(server_url) !== null) {
            return axios.delete(
                localStorage.getItem(server_url) + Url + id,
                config
            ).then(function (result) {
                return successMessage;
            }
            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return "@UNHANDLE_ERROR";
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.delete(
                localStorage.getItem(server_url) + Url + id,
                config
            ).then(function (result) {
                return successMessage;
            }
            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return "@UNHANDLE_ERROR";
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        }
    }


    /* EasyAccess */

    static PostRefreshTokenEasyAccess = async (onValidToken: () => void, onError: (message: string, error: AxiosError) => void): Promise<any> => {
        const config = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        if (localStorage.getItem("login_url") !== null) {
            return axios.get(
                localStorage.getItem("login_url") + RestUrl.AUTHENTICATION_REFRESH,
                config
            ).then(result => {
                onValidToken();
            }
            ).catch(function (error) {
                if (typeof error.response === 'undefined') {
                    store.dispatch({ type: 'SETLOGIN', isLogged: false });
                    onError("@UNHANDLE_ERROR", error);
                } else if (error.response!.status === 500) {
                    store.dispatch({ type: 'SETLOGIN', isLogged: false });
                    onError("@UNHANDLE_ERROR", error);
                } else {
                    store.dispatch({ type: 'SETLOGIN', isLogged: false });
                    onError("@NO_VALID_TOKEN", error);
                }
            });
        } else {
            getTranslateData();
            return axios.get(
                localStorage.getItem("login_url") + RestUrl.AUTHENTICATION_REFRESH,
                config
            ).then(result => {
                onValidToken();
            }
            ).catch(function (error) {
                if (typeof error.response === 'undefined') {
                    store.dispatch({ type: 'SETLOGIN', isLogged: false });
                    onError("@UNHANDLE_ERROR", error);
                } else if (error.response!.status === 500) {
                    store.dispatch({ type: 'SETLOGIN', isLogged: false });
                    onError("@UNHANDLE_ERROR", error);
                } else {
                    store.dispatch({ type: 'SETLOGIN', isLogged: false });
                    onError("@NO_VALID_TOKEN", error);
                }
            });
        }
    }

    static getWithEasyAccess = (
        Url: string,
        server_url: ServerUrlType,
        onResult: (result_data: any, result_headers: any) => void,
        onError: (message: string, result_error: AxiosError) => void
    ) => {
        const config = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        }
        if (localStorage.getItem(server_url) !== null) {
            return axios.get(
                localStorage.getItem(server_url) + Url,
                config
            ).then(
                response => response && onResult(response.data, response.headers)
            ).catch(
                (error: AxiosError) => {
                    if (typeof error.response === 'undefined') {
                        onError("@UNHANDLE_ERROR", error);
                    } else if (error.response!.status === 401) {
                        RestCall.PostRefreshTokenEasyAccess(
                            () => RestCall.getWithEasyAccess(Url, server_url, onResult, onError),
                            (message, error) => onError(message, error)
                        );
                    }
                    else {
                        if (typeof error.response.data === 'string') {
                            onError(error.response.data, error);
                        }
                        else {
                            onError(handleError(error.response!.status), error);
                        }
                    }

                }
            );
        } else {
            getTranslateData();
            return axios.get(
                localStorage.getItem(server_url) + Url,
                config
            ).then(
                response => response && onResult(response.data, response.headers)
            ).catch(
                (error: AxiosError) => {
                    if (typeof error.response === 'undefined') {
                        onError("@UNHANDLE_ERROR", error);
                    } else if (error.response!.status === 401) {
                        RestCall.PostRefreshTokenEasyAccess(
                            () => RestCall.getWithEasyAccess(Url, server_url, onResult, onError),
                            (message, error) => onError(message, error)
                        );
                    }
                    else {
                        onError(handleError(error.response!.status), error);
                    }
                }
            );
        }
    }

    static getAllPaginationEasyAccess = (
        Url: string,
        page: number | undefined,
        pageSize: number | undefined,
        server_url: ServerUrlType,
        onResult: (result_data: any, result_headers: any) => void,
        onError: (message: string, result_error: AxiosError) => void
    ): Promise<any> => {
        const PageSize = typeof pageSize === 'undefined' ? 10 : pageSize;
        const PageNumber = typeof page === 'undefined' ? 1 : page;
        const config = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        }
        if (localStorage.getItem(server_url) !== null) {
            return axios.get(
                localStorage.getItem(server_url) + Url + `?Page=${PageNumber}&Size=${PageSize}`,
                config
            ).then(
                response => response && onResult(response.data, response.headers)
            ).catch(
                (error: AxiosError) => {
                    if (typeof error.response === 'undefined') {
                        onError("@UNHANDLE_ERROR", error);
                    } else if (error.response!.status === 401) {
                        RestCall.PostRefreshTokenEasyAccess(
                            () => RestCall.getAllPaginationEasyAccess(
                                Url,
                                page,
                                pageSize,
                                server_url,
                                onResult,
                                onError),
                            (message, error) => onError(message, error)
                        );
                    }
                    else {
                        onError(handleError(error.response!.status), error);
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.get(
                localStorage.getItem(server_url) + Url + `?Page=${PageNumber}&Size=${PageSize}`,
                config
            ).then(
                response => response && onResult(response.data, response.headers)
            ).catch(
                (error: AxiosError) => {
                    if (typeof error.response === 'undefined') {
                        onError("@UNHANDLE_ERROR", error);
                    } else if (error.response!.status === 401) {
                        RestCall.PostRefreshTokenEasyAccess(
                            () => RestCall.getAllPaginationEasyAccess(
                                Url,
                                page,
                                pageSize,
                                server_url,
                                onResult,
                                onError),
                            (message, error) => onError(message, error)
                        );
                    }
                    else {
                        onError(handleError(error.response!.status), error);
                    }
                }
            );
        }

    }

    static postWithReturnEasyAccess = async (
        bodyParameters: any,
        Url: string,
        page: number | undefined,
        pageSize: number | undefined,
        server_url: ServerUrlType,
        onResult: (result_data: any, result_headers: any) => void,
        onError: (message: string, result_error: AxiosError) => void
    ): Promise<any> => {
        const PageSize = typeof pageSize === 'undefined' ? 10 : pageSize;
        const PageNumber = typeof page === 'undefined' ? 1 : page;

        const config = {
            withCredentials: true,
            headers: {
            }
        }
        if (localStorage.getItem(server_url) !== null) {
            return axios.post(
                localStorage.getItem(server_url) + Url + `?Page=${PageNumber}&Size=${PageSize}`,
                bodyParameters,
                config
            ).then(
                response => response && onResult(response.data, response.headers)
            ).catch(
                (error: AxiosError) => {
                    if (typeof error.response === 'undefined') {
                        onError("@UNHANDLE_ERROR", error);
                    } else if (error.response!.status === 401) {
                        RestCall.PostRefreshTokenEasyAccess(
                            () => RestCall.postWithReturnEasyAccess(bodyParameters, Url, page, pageSize, server_url, onResult, onError),
                            (message, error) => onError(message, error)
                        );
                    }
                    else {
                        onError(handleError(error.response!.status), error);
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.post(
                localStorage.getItem(server_url) + Url + `?Page=${PageNumber}&Size=${PageSize}`,
                bodyParameters,
                config
            ).then(
                response => response && onResult(response.data, response.headers)
            ).catch(
                (error: AxiosError) => {
                    if (typeof error.response === 'undefined') {
                        onError("@UNHANDLE_ERROR", error);
                    } else if (error.response!.status === 401) {
                        RestCall.PostRefreshTokenEasyAccess(
                            () => RestCall.postWithReturnEasyAccess(bodyParameters, Url, page, pageSize, server_url, onResult, onError),
                            (message, error) => onError(message, error)
                        );
                    }
                    else {
                        onError(handleError(error.response!.status), error);
                    }
                }
            );
        }
    }

    static postWithResultNoPaginationEasyAccess = (
        bodyParameters: any,
        Url: string,
        server_url: ServerUrlType,
        onResult: (result_data: any, result_headers: any) => void,
        onError: (message: string, result_error: AxiosError) => void,
        headers_param?: {}
    ): Promise<any> => {
        const config = {
            withCredentials: true,
            headers: headers_param ?? {}
        }
        if (localStorage.getItem(server_url) !== null) {
            return axios.post(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(
                response => response && onResult(response.data, response.headers)
            ).catch(
                (error: AxiosError) => {
                    if (typeof error.response === 'undefined') {
                        onError("@UNHANDLE_ERROR", error);
                    } else if (error.response!.status === 401) {
                        RestCall.PostRefreshTokenEasyAccess(
                            () => RestCall.postWithResultNoPaginationEasyAccess(bodyParameters, Url, server_url, onResult, onError, headers_param),
                            (message, error) => onError(message, error)
                        );
                    } else if (error.response!.status === 403) {
                        if (typeof error.response.data!.message !== 'undefined') {
                            return onError(error.response.data.message, error);
                        } else {
                            return handleError(error.response!.status);
                        }
                    }
                    else {
                        onError(handleError(error.response!.status), error);
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.post(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(
                response => response && onResult(response.data, response.headers)
            ).catch(
                (error: AxiosError) => {
                    if (typeof error.response === 'undefined') {
                        onError("@UNHANDLE_ERROR", error);
                    } else if (error.response!.status === 401) {
                        RestCall.PostRefreshTokenEasyAccess(
                            () => RestCall.postWithResultNoPaginationEasyAccess(bodyParameters, Url, server_url, onResult, onError),
                            (message, error) => onError(message, error)
                        );
                    }
                    else {
                        onError(handleError(error.response!.status), error);
                    }
                }
            );
        }
    }

    static putWithReturnEasyAccess = async (
        bodyParameters: any,
        Url: string,
        page: number | undefined,
        pageSize: number | undefined,
        server_url: ServerUrlType,
        onResult: (result_data: any, result_headers: any) => void,
        onError: (message: string, result_error: AxiosError) => void
    ): Promise<any> => {
        const PageSize = typeof pageSize === 'undefined' ? 10 : pageSize;
        const PageNumber = typeof page === 'undefined' ? 1 : page;

        const config = {
            withCredentials: true,
            headers: {
            }
        }
        if (localStorage.getItem(server_url) !== null) {
            return axios.put(
                localStorage.getItem(server_url) + Url + `?Page=${PageNumber}&Size=${PageSize}`,
                bodyParameters,
                config
            ).then(
                response => response && onResult(response.data, response.headers)
            ).catch(
                (error: AxiosError) => {
                    if (typeof error.response === 'undefined') {
                        onError("@UNHANDLE_ERROR", error);
                    } else if (error.response!.status === 401) {
                        RestCall.PostRefreshTokenEasyAccess(
                            () => RestCall.putWithReturnEasyAccess(bodyParameters, Url, page, pageSize, server_url, onResult, onError),
                            (message, error) => onError(message, error)
                        );
                    }
                    else {
                        onError(handleError(error.response!.status), error);
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.put(
                localStorage.getItem(server_url) + Url + `?Page=${PageNumber}&Size=${PageSize}`,
                bodyParameters,
                config
            ).then(
                response => response && onResult(response.data, response.headers)
            ).catch(
                (error: AxiosError) => {
                    if (typeof error.response === 'undefined') {
                        onError("@UNHANDLE_ERROR", error);
                    } else if (error.response!.status === 401) {
                        RestCall.PostRefreshTokenEasyAccess(
                            () => RestCall.putWithReturnEasyAccess(bodyParameters, Url, page, pageSize, server_url, onResult, onError),
                            (message, error) => onError(message, error)
                        );
                    }
                    else {
                        onError(handleError(error.response!.status), error);
                    }
                }
            );
        }
    }

    static putWithResultNoPaginationEasyAccess = (
        bodyParameters: any,
        Url: string,
        server_url: ServerUrlType,
        onResult: (result_data: any, result_headers: any) => void,
        onError: (message: string, result_error: AxiosError) => void
    ): Promise<any> => {
        const config = {
            withCredentials: true,
            headers: {
            }
        }
        if (localStorage.getItem(server_url) !== null) {
            return axios.put(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(
                response => response && onResult(response.data, response.headers)
            ).catch(
                (error: AxiosError) => {
                    if (typeof error.response === 'undefined') {
                        onError("@UNHANDLE_ERROR", error);
                    } else if (error.response!.status === 401) {
                        RestCall.PostRefreshTokenEasyAccess(
                            () => RestCall.putWithResultNoPaginationEasyAccess(bodyParameters, Url, server_url, onResult, onError),
                            (message, error) => onError(message, error)
                        );
                    }
                    else {
                        onError(handleError(error.response!.status), error);
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.put(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(
                response => response && onResult(response.data, response.headers)
            ).catch(
                (error: AxiosError) => {
                    if (typeof error.response === 'undefined') {
                        onError("@UNHANDLE_ERROR", error);
                    } else if (error.response!.status === 401) {
                        RestCall.PostRefreshTokenEasyAccess(
                            () => RestCall.putWithResultNoPaginationEasyAccess(bodyParameters, Url, server_url, onResult, onError),
                            (message, error) => onError(message, error)
                        );
                    }
                    else {
                        onError(handleError(error.response!.status), error);
                    }
                }
            );
        }
    }


    static downloadPdf = async (bodyParameters: any, Url: string, successMessage: string, server_url: ServerUrlType, namefile?: string): Promise<string> => {
        const downloadtype: DownloadType = 'blob'
        const config = {
            withCredentials: true,
            headers: {
            },
            responseType: downloadtype
        }
        if (localStorage.getItem(server_url) !== null) {
            return axios.post(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(
                result => {
                    try {
                        const url = window.URL.createObjectURL(new Blob([result.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        if (namefile === undefined || namefile === null || namefile.trim() === '') {
                            namefile = 'download.pdf';
                        }

                        link.setAttribute('download', namefile); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        return successMessage;
                    } catch {
                        return "@UNHANDLE_ERROR";
                    }
                }

            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return "@UNHANDLE_ERROR";
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        } else {
            getTranslateData();
            return axios.post(
                localStorage.getItem(server_url) + Url,
                bodyParameters,
                config
            ).then(
                result => {

                    try {
                        const url = window.URL.createObjectURL(new Blob([result.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'download.pdf'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        return successMessage;
                    } catch {
                        return "@UNHANDLE_ERROR";
                    }

                }
            ).catch(
                function (error: AxiosError) {
                    if (typeof error.response === 'undefined') {
                        return "@UNHANDLE_ERROR";
                    } else if (error.response!.status === 401) {
                        return RestCall.PostRefreshToken();
                    }
                    else {
                        return handleError(error.response!.status);
                    }
                }
            );
        }
    }

}




export const handleError = (errorCode: number): string => {//metodo che gestisce gli errori
    let errorMessage: string = "";
    switch (errorCode) {
        case 400:
            errorMessage = "@ERROR_400";
            break;
        case 403:
            errorMessage = "@ERROR_403";
            break;
        case 404:
            errorMessage = "@ERROR_404";
            break;
        case 405:
            errorMessage = "@ERROR_405";
            break;
        case 408:
            errorMessage = "@ERROR_408";
            break;
        case 500:
            errorMessage = "@ERROR_500";
            break;
        case 502:
            errorMessage = "@ERROR_502";
            break;
        case 505:
            errorMessage = "@ERROR_505";
            break;
        default:
            errorMessage = "@UNHANDLE_ERROR";
            break;
    }

    return errorMessage;
}
