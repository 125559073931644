export class RubricaItemModel {
    constructor(
        public id: number,
        public ragioneSociale: string,
        public perContoDi: string,
        public indirizzo: string,
        public localita: string,
        public provincia: string,
        public cap: string,
        public codiceNazione: string,
        public nazione: string,
        public telefono: string,
        public mail: string,
        public orarioApertura1: string | null,
        public orarioChiusura1: string | null,
        public orarioApertura2: string | null,
        public orarioChiusura2: string | null,
        public isDefault: boolean,
        public orarioContinuato: boolean
    ) { }
    static getDefault = (): RubricaItemModel => {
        return (new RubricaItemModel(0, '', '', '', '', '', '', '', '', '', '', null, null, null, null, false, false))
    }
}

export class OperatoreItemModel {
    constructor(
        public id: number,
        public idClientePort: number,
        public codiceCliente: string,
        public nome: string,
        public mail: string,
        public mailTarga: string,
        public telefono: string
    ) { }
    static getDefault = (): OperatoreItemModel => {
        return (new OperatoreItemModel(0, 0, '', '', '', '', ''))
    }
}

export class ProvLocCapDTO{
    constructor(
        public provincia: string | null,
        public localita: string| null,
        public cap: string | null
    ){}
}
