import { Form } from 'antd';
import React from 'react';
import '../../CustomCss/InputTypeCss/InputType.scss';
import { FormPasswordInput } from '../Utils_Component/InputForm';
import { ChangePasswordModel } from '../Model/UsersModel';
import { ChangePswValidator } from './UsersInterface';
import { TranslateString } from '../../i18n/translate';

interface UserFormProps {
    user: ChangePasswordModel,
    validator: ChangePswValidator,
    onChange:(user:ChangePasswordModel)=>void,
    show_old_psw:boolean
}

export const ChangePswForm:React.FC<UserFormProps> = props =>{

    const[user,setUser] = React.useState(props.user);


    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>| React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]
        if(props.show_old_psw === false){
            if(user.oldpassword === ""){
                const newUser = {
                    ...user,
                    [name] : value,
                    oldpassword: "_"
                };
                setUser(newUser);//aggiunge i nuovi valori all'interno della form
                
                //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
                props.onChange(newUser);
            }else{
                const newUser = {
                    ...user,
                    [name] : value
                };
                setUser(newUser);//aggiunge i nuovi valori all'interno della form
                
                //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
                props.onChange(newUser);
            }
        }else{
            const newUser = {
                ...user,
                [name] : value
            };
            setUser(newUser);//aggiunge i nuovi valori all'interno della form
            
            //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
            props.onChange(newUser);
        }
    }


    return(
        <div className="container-col">
            <Form>
                {props.show_old_psw === false?null:<FormPasswordInput onChange={handleChanges} label_name="OLD_PASSWORD"  mandatory={true} input_name={"oldpassword"} input_value={user.oldpassword} validate={props.validator.oldPsw}/>}
                <FormPasswordInput onChange={handleChanges} label_name="NEW_PASSWORD"  mandatory={true} input_name={"newpassword"} input_value={user.newpassword} validate={props.validator.newPassword}/>
                <FormPasswordInput onChange={handleChanges} label_name="VERIFY_PASSWORD"  
                    mandatory={true} input_name={"confpassword"} input_value={user.confpassword} 
                    validate={props.validator.cfmPassword} helpMessage={user.confpassword !== ""?TranslateString("@NO_VALID_PSW"):undefined}/>   
            </Form>
        </div>
        );
}

