import { Modal } from "antd";
import { useState } from "react";
import { RestCall } from "../Rest/Rest_Call/RestCall";
import RestUrl from '../../RestUrl.json';
import { notifyError, notifySucces } from "../CustomToast/customToast";
import { TranslateString } from "../../i18n/translate";
import {
    LeftOutlined
} from '@ant-design/icons';
import { FormTextInput_new } from "../Utils_Component/InputForm";

interface PreseCancelModalProps {
    visible: boolean,
    record: any | undefined,
    onChangeVisibility: (visible: boolean, record: any | undefined, type: 'ok' | '') => void
}

export const PreseCancelModal: React.FC<PreseCancelModalProps> = props => {
    const [motivo, setMotivo] = useState<string>('')

    const handleCancelModalOk = () => {
        if (motivo !== '') {
            const payload = {
                id: props.record.id,
                reason: motivo
            }
            RestCall.put_with_result_no_pagination(payload, RestUrl.PRESE_PUT_CANCEL + props.record.id , 'url').then(
                result => {  
                    //console.log(JSON.stringify(result))                  
                    if (typeof result !== 'string' && result.data?.responseType.toLowerCase() === 'ok') {
                        notifySucces("@V_PRESE_CANCEL");
                        props.onChangeVisibility(false, undefined, 'ok')

                    } else if (result === 'VALID_TOKEN') {
                        handleCancelModalOk();
                    } else if (typeof result === 'string') {
                        notifyError(result);
                    }
                }
            );
        }
        else{
            notifyError("@V_PRESE_CANCEL_MOTIVO_ERROR"); 
        }
    }

    const handleCustomerChange = (event: React.ChangeEvent<HTMLInputElement>| React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;
        setMotivo(value);
    }

    return (
        <>
            {props.visible &&
                <Modal
                    wrapClassName="modal-s"
                    title={TranslateString("@RICHIESTA_ANNULLAMENTO")}
                    visible={props.visible}
                    closeIcon={<LeftOutlined className="mobile-body" />}
                    onCancel={() => { props.onChangeVisibility(false, undefined, '') }}
                    onOk={handleCancelModalOk}
                    keyboard={false}
                    maskClosable={false}
                    width="500px"
                >
                    <div>
                    <FormTextInput_new
                        onChange={handleCustomerChange}
                        label_name="MOTIVO"
                        mandatory={true}
                        input_name={"motivo"}
                        input_value={motivo}
                        validate={undefined}
                    />
                    </div>
                </Modal>
            }
        </>
    );
}