import React from 'react';
import './App.css';
import store from './Component/Redux/Store';
import { I18nProvider, LOCALES } from './i18n';
import 'react-toastify/dist/ReactToastify.min.css';
import 'antd/dist/antd.css';
import './CustomCss/Toast/toastcss.scss';
import './CustomCss/UtilsCss.scss';
import locale from 'antd/lib/locale/it_IT';
import moment from 'moment';
import 'moment/locale/it';
import { ConfigProvider, Spin } from 'antd';
import { ToastContainer } from 'react-toastify';
import { HashRouter } from 'react-router-dom';
import { Structure } from './Component/Layout/Structure';
import { getTranslateData } from './utils/GetPublicUrl';
import '@si/si-react-components/dist/si-react-components.css';
import RestUrl from './RestUrl.json';
import { RestCall } from './Component/Rest/Rest_Call/RestCall';
import { ChangeUrl } from './utils/ChangeUrl';
import { RoutersNoAuth } from './Component/Route/RoutersNoAuth';
import { notifyError } from './Component/CustomToast/customToast';

moment.locale('it');

function App() {
  const [state, setState] = React.useState(store.getState());
  const [wait, setWait] = React.useState(false);
  React.useEffect(
    () => {
      getTranslateData();
      isUserLogged();
    }, []
  );

  const handleLogout = () => {
    RestCall.getAll(RestUrl.AUTHENTICACTION_LOGOUT, 'login_url').then(
      result => {
        if (typeof result === 'string') {
          if (result === '') {//le API ritornano una stringa vuota se va tutto bene
            store.dispatch({ type: 'SETLOGIN', isLogged: false });
            store.dispatch({ type: 'SETPERMISSIONS', user_type: undefined });
            ChangeUrl('');
          } else {
            notifyError(result);
          }
        }
      }
    );
  }

  const visibleSections = () => {
    setWait(true);

    RestCall.getWithEasyAccess(RestUrl.USER_SETTING, 'url', result => {
      if (result !== undefined && result !== null) {
        store.dispatch({ type: 'SETISVISIBLE', enableTracking: result.data.enableTracking ?? false, enablePrese: result.data.enablePrese ?? false, enableDownloadPod: result.data.enableDownloadPod ?? false });

          /*if (result.data.enablePrese) {
            ChangeUrl('Prese');
          } else if (result.data.enableTracking) {
            ChangeUrl('Tracking');
          }
        */
        
      }
      setWait(false);
    },
      message => {
        if (message === "@ERROR_403") {
          handleLogout();
          notifyError("Utente Bloccato");
          setWait(false);

          return;
        }

        //notifyError(message);
        store.dispatch({ type: 'SETISVISIBLE', enableTracking: false, enablePrese: false, enableDownloadPod: false });
        setWait(false);
      }
    );


  }

  const isUserLogged = () => {
    setWait(true);
    RestCall.getAll(RestUrl.CURRENT_USER, 'login_url').then(
      result => {
        if (typeof result !== 'undefined' && typeof result !== 'string') {
          store.dispatch({ type: 'SETLOGIN', isLogged: true });
          if (result.superuser === true) {
            store.dispatch({ type: 'SETPERMISSIONS', user_type: 'superuser' });
          } else {
            store.dispatch({ type: 'SETPERMISSIONS', user_type: result.usertypedescription.toLowerCase() === 'cliente' ? 'normal' : 'backoffice' });
          }

          visibleSections();


          //setWait(false);
        } else if (typeof result === 'string') {
          if (result === 'VALID_TOKEN') {
            isUserLogged();
          } else {
            store.dispatch({ type: 'SETLOGIN', isLogged: false });
            setWait(false);
          }
        } else {
          store.dispatch({ type: 'SETLOGIN', isLogged: false });
          setWait(false);
        }
      }
    )
    setWait(false);
  }

  const handletranslate = () => {
    setState(store.getState());
  }
  store.subscribe(handletranslate);

  return (
    <I18nProvider locale={LOCALES.ITALIAN} translate={undefined} >
      <HashRouter>
        <Spin spinning={wait} >
          {
            wait ?
              <ConfigProvider locale={locale}>
                <Structure />
              </ConfigProvider>
              :
              state.isLogged ?
                <ConfigProvider locale={locale}>
                  <Structure />
                </ConfigProvider>
                :
                <RoutersNoAuth />
          }
        </Spin>
      </HashRouter>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </I18nProvider>
  );
}

export default App;
