import { SITableFilterController, SITableFilterTypeValues } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterController';
import { SITableOrder } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterModel';
import { SITableColumnStructure } from "@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableModels";
import { TranslateString } from "../../i18n/translate";
import { FormateDateTimeWith00, FormateDateWith00 } from "../../utils/FormatDate";
import { Button, Checkbox, Tag } from 'antd';
import { PaperClipOutlined, SelectOutlined } from '@ant-design/icons';
import { TrackingModel } from '../Model/TrackingModel';
import { FormattedTime } from 'react-intl';
import { Link } from 'react-router-dom';


export const TrackingColumns = (
  onOrderBy: (order_by: SITableOrder) => void,
  onFilter: (value: string, sql_name: string, operator: string, filter_type: SITableFilterTypeValues) => void,
  onOpenFile: (record: TrackingModel) => void,
  rifbolla: number | undefined,
  selezionaTracking: (e: any, row: TrackingModel) => void,
  arr_sel: number[],
  selezionaTutto: () => void,
  enableDownloadPod: boolean
): any[] => {

  const columns: SITableColumnStructure[] = [
    {
      title: <SITableFilterController
        sql_name={"seleziona"}
        alias={<Button type='ghost' style={{ border: '0px', padding: '0px', margin: '0px' }} onClick={selezionaTutto} title='Verranno selezionati solo i record visibili e un massimo di 100 record.' ><SelectOutlined /></Button>}
          /* <small style={{ fontStyle: '' }}>{TranslateString("@SELECT")}</small></Button>} */
/*         onOrderBy={onOrderBy}
        filter_type={'undefined'}
        onSearch={onFilter} */
        titleAlign={'center'}
      />,
      dataIndex: 'seleziona',
      key: 'seleziona',
      fixed: 'left',
      width: 90,
      column_type: 'String',
      padding: "3px",
      render: (text: string, record: TrackingModel, index: any) => (
        <div className={'container-col height-100 c-ctr '} key={index}> 
          {(typeof record !== undefined)  &&
            <Checkbox checked={arr_sel.find(item => item === record.id) !== undefined ? true : false } onChange={(e) => selezionaTracking(e, record)}></Checkbox>
          }
        </div>),
    },
    {
      title: <SITableFilterController
        sql_name={"numero"}
        alias={TranslateString('@NUMEROPRESA')}
        onOrderBy={onOrderBy}
        filter_type={'Number'}
        onSearch={onFilter}
        titleAlign='end'
      />,
      key: 'numero',
      column_type: 'Number',
      dataIndex: 'numero',
      width: 90,
      padding: "0",
      render: (record: any) => (<div className="container-row c-ctrbottom-b padding-right-10 ">{record}</div>)
    },
    {
      title: <SITableFilterController
        sql_name={"ragioneSocialeMittente"}
        alias={TranslateString('@MITTENTE_RAGIONE_SOCIALE')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      />,
      key: 'ragioneSocialeMittente',
      column_type: 'String',
      dataIndex: 'ragioneSocialeMittente',
      width: 200,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"localitaMittente"}
        alias={TranslateString('@LOCALITA_MITTENTE')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      />,
      key: 'localitaMittente',
      column_type: 'String',
      dataIndex: 'localitaMittente',
      width: 150,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"provinciaMittente"}
        alias={TranslateString('@PROVINCIA_MITTENTE')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
        titleAlign='center'
      />,
      key: 'provinciaMittente',
      column_type: 'String',
      dataIndex: 'provinciaMittente',
      width: 130,
      padding: "0",
      render: (record: string) => (<div className="container-row c-ctr ">{record}</div>)
    },
    {
      title: <SITableFilterController
        sql_name={"ragioneSocialeDestinatario"}
        alias={TranslateString('@DESTINAZIONE_RAGIONE_SOCIALE')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      />,
      key: 'ragioneSocialeDestinatario',
      column_type: 'String',
      dataIndex: 'ragioneSocialeDestinatario',
      width: 200,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"localitaDestinatario"}
        alias={TranslateString('@LOCALITA_DESTINATARIO')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      />,
      key: 'localitaDestinatario',
      column_type: 'String',
      dataIndex: 'localitaDestinatario',
      width: 150,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"provinciaDestinatario"}
        alias={TranslateString('@PROVINCIA_DESTINATARIO')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
        titleAlign='center'
      />,
      key: 'provinciaDestinatario',
      column_type: 'String',
      dataIndex: 'provinciaDestinatario',
      width: 100,
      padding: "0",
      render: (record: string) => (<div className="container-row c-ctr ">{record}</div>)
    },
    {
      title: <SITableFilterController
        sql_name={"nRifBollaMittente"}
        alias={TranslateString('@RIFERIMENTO_BOLLA_MITTENTE')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      //initialValue={rifbolla !== undefined ? rifbolla.toString() : undefined}
      />,
      key: 'nRifBollaMittente',
      column_type: 'String',
      dataIndex: 'nRifBollaMittente',
      width: 110,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"dataDocumento"}
        alias={TranslateString('@DATA_AFFIDAMENTO')}
        onOrderBy={onOrderBy}
        /*         filter_type={'DateTime'}
                onSearch={onFilter} */
        titleAlign='center'
      />,
      key: 'dataDocumento',
      column_type: 'DateTime',
      dataIndex: 'dataDocumento',
      width: 120,
      padding: "0",
      render: (record: string) => (<div className="container-row c-ctr ">{FormateDateWith00(record)}</div>)
    },
    {
      title: <SITableFilterController
        sql_name={"dataStatoConsegna"}
        alias={TranslateString('@DATA_CONSEGNA')}
        onOrderBy={onOrderBy}
        filter_type={'DateTime'}
        onSearch={onFilter} 
        titleAlign='center'
      />,
      key: 'dataStatoConsegna',
      column_type: 'DateTime',
      dataIndex: 'dataStatoConsegna',
      width: 140,
      padding: "0",
      render: (data: string, record: TrackingModel) => (record.statoBolla === 100 ? <div className="container-row c-ctr ">{FormateDateTimeWith00(data)}</div> : <div className="container-row c-ctr "> - </div>)
    },
    /*     {
          title: <SITableFilterController
            sql_name={"dataStatoConsegna"}
            alias={TranslateString('@DATA_CONSEGNA')}
            onOrderBy={onOrderBy}
            filter_type={'DateTime'}
            onSearch={onFilter}
            titleAlign='center'
          />,
          key: 'oraConsegna',
          column_type: 'DateTime',
          dataIndex: 'oraConsegna',
          width: 120,
          padding: "0",
          render: (data: string, record: TrackingModel) => (record.statoBolla === 100 ? <div className="container-row c-ctr ">{FormateTimeWith00(data)}</div> : <div className="container-row c-ctr "> - </div> )
        },
     */
    {
      title: <SITableFilterController
        sql_name={"statoBolla"}
        alias={TranslateString('@STATOPRESA')}
        onOrderBy={onOrderBy}
        /*         filter_type={'String'}
                onSearch={onFilter} */
        titleAlign='center'
      />,
      key: 'statoBolla',
      column_type: 'String',
      dataIndex: 'statoBolla',
      width: 140,
      padding: "0",
      //cellClassName: ' '
      render: (stato: number) => {
        if (stato === 20 || stato === 80) {
          //"In Consegna"
          return <div className="container-row c-ctr"><Tag color='lime' key={'stato_' + stato}>In Consegna</Tag></div>
        }

        if (stato === 30) {
          //"In Transito"
          return <div className="container-row c-ctr"><Tag color='blue' key={'stato_' + stato}>In Transito</Tag></div>
        }

        if (stato === 40) {
          //"Prenotata"
          return <div className="container-row c-ctr"><Tag color='purple' key={'stato_' + stato}>Prenotata</Tag></div>
        }

        if (stato === 50) {
          //"In Giacenza"
          return <div className="container-row c-ctr"><Tag color='orange' key={'stato_' + stato}>In Giacenza</Tag></div>
        }

        if (stato === 100) {
          //"Consegnata"
          return <div className="container-row c-ctr"><Tag color='green' key={'stato_' + stato}>Consegnata</Tag></div>
        }
      }
    },
    {
      title: <SITableFilterController
        sql_name={"provacons"}
        alias={<PaperClipOutlined />}
        titleAlign={'center'}
      />,
      dataIndex: 'provacons',
      key: 'provacons',
      fixed: 'right',
      width: 50,
      column_type: 'String',
      padding: "0px",
      render: (value: string, record: TrackingModel) => (
        <div className="container-col c-ctr"> <Button disabled={!enableDownloadPod} type='link' onClick={() => onOpenFile(record)}> <PaperClipOutlined /> </Button> </div>
      )
    },
    {
      title: <SITableFilterController
        sql_name={"barcode"}
        alias={TranslateString('@BARCODE')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      />,
      key: 'barcode',
      column_type: 'String',
      dataIndex: 'barcode',
      width: 110,
      padding: "0",
      render: (record) => <Link to={{pathname:"/CercaSpedizione" , search:`?barcode=${record}`}}  target="_blank" rel="noopener noreferrer" >{record}</Link>
    },

  ];

  return columns;
}

