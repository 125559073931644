import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import { Moment } from "moment";
import { TFestivi } from "../Model/Festivi";

export const DateFormatJson = (date: any): string => {

    const jsonDate = JSON.stringify(date);
    const jsonDateS = jsonDate.split("\"")[1];


    return (jsonDateS);
}


export const splitWithChar = (value: string, char: string): any => {
    const split = value.split(char);
    return split;
}

export const GraterThanToday = (value: string | null): boolean => {
    if (value !== "" && value !== null) {
        const D = new Date();
        const newDate = new Date(split_DD_MM_YYYY_with_slash_no_null(value));
        if (D.getFullYear() > newDate.getFullYear()) {
            return false;
        } else if (D.getFullYear() === newDate.getFullYear() && D.getMonth() + 1 > newDate.getMonth() + 1) {
            return false;
        } else if (D.getFullYear() === newDate.getFullYear() && D.getMonth() + 1 === newDate.getMonth() + 1 && D.getDate() > newDate.getDate()) {
            return false;
        }
        return true;
    }
    return false;
}
export const isNotToday = (value: string | null): boolean => {
    if (value !== "" && value !== null) {
        const D = new Date();
        const newDate = new Date(split_DD_MM_YYYY_with_slash_no_null_Record(value));
        if (D.getFullYear() !== newDate.getFullYear()) {
            return false;
        } else if (D.getFullYear() === newDate.getFullYear() && D.getMonth() + 1 !== newDate.getMonth() + 1) {
            return false;
        } else if (D.getFullYear() === newDate.getFullYear() && D.getMonth() + 1 === newDate.getMonth() + 1 && D.getDate() !== newDate.getDate()) {
            return false;
        }
        return true;
    }
    return false;
}


export const split_DD_MM_YYYY_with_slash = (value: string | null): string | null => {
    try {
        if (value === null) { return null; }
        const split = value.split("/");
        if (typeof split[2] === 'undefined' || typeof split[1] === 'undefined') { return value }
        const date = split[2] + "-" + split[1] + "-" + split[0];
        return date;
    } catch {
        return value;
    }

}
export const split_DD_MM_YYYY_with_slash_no_null = (value: any): string => {
    try {
        const split = value.split("/");
        if (typeof split[2] === 'undefined' || typeof split[1] === 'undefined') { return value }
        const date = split[2] + "-" + split[1] + "-" + split[0];
        return date;
    } catch {
        return value;
    }

}

export const split_DD_MM_YYYY_with_slash_no_null_Record = (value: any): string => {
    try {
        const split = value.split("-");
        if (typeof split[2] === 'undefined' || typeof split[1] === 'undefined') { return value }
        const slit2 = split[2].split("T");
        const date = slit2[0] + "-" + split[1] + "-" + split[0];
        return date;
    } catch {
        return value;
    }
}

export const split_YYYY_MM_YYYY_with_slash_no_null_Record = (value: any): string => {
    try {
        const split = value.split("-");
        if (typeof split[2] === 'undefined' || typeof split[1] === 'undefined') { return value }
        const slit2 = split[2].split("T");
        const date = split[0] + "-" + split[1] + "-" + slit2[0];
        return date;
    } catch {
        return value;
    }

}



export const cutDate = (value: any, arrayNumber: number): string => {

    const split = value.split(";");
    if (typeof split[1] === 'undefined') { return value }


    return split[arrayNumber];
}

export const formatTime = (datetime: string): string => {
    return moment(datetime).format("HH:mm")
}

export const checkOrariApertura = (
    data_orarioApertura1: string | null,
    data_orarioChiusura1: string | null,
    data_orarioApertura2: string | null,
    data_orarioChiusura2: string | null,
    data_orarioContinuato: boolean
): boolean => {
    const orarioApertura1 = data_orarioApertura1 === null ? null : formatTime(data_orarioApertura1);
    const orarioChiusura1 = data_orarioChiusura1 === null ? null : formatTime(data_orarioChiusura1);
    const orarioApertura2 = data_orarioApertura2 === null ? null : formatTime(data_orarioApertura2);
    const orarioChiusura2 = data_orarioChiusura2 === null ? null : formatTime(data_orarioChiusura2);

    

    if (orarioApertura1 === null || orarioChiusura1 === null || orarioApertura1 > orarioChiusura1) {
        return false;
    }

    if (!data_orarioContinuato) {
        if (orarioApertura2 === null ||
            orarioChiusura2 === null ||
            orarioApertura2 > orarioChiusura2 ||
            orarioChiusura1 > orarioApertura2 //se il pomeriggio apre prima del mattino...
        ) {
            return false;
        }
    }

    if (orarioApertura1 < '06:00' || orarioChiusura1 > '19:00') {
        return false;
    }

    if (!data_orarioContinuato && orarioChiusura2 !== null && orarioChiusura2 > '19:00') {
        return false;
    }

    return true;

}

// eslint-disable-next-line arrow-body-style
export const disabledDate/*: RangePickerProps['disabledDate']*/ = (current: moment.Moment,holidays:TFestivi[]) => {
    let bcontrolla = false;
    const limitDate = moment().add(1, 'months');
    if (!current || current < moment().startOf('day')  || current > limitDate) {
        bcontrolla = true;
    }

    if (!bcontrolla) {
        for (var i = 0; i < holidays.length; i++) {
            if (!current || current.isBetween(holidays[i].datada, holidays[i].dataa)) {
                bcontrolla = true;
                break;
            }
        };
    }

    return bcontrolla;
};