import React from "react";
import { PresaPortDTO } from "../Model/PreseModel";
import { FilterStructureModel, Order } from "../Model/FilterModel";
import { PreseValidator } from "./PreseInterface";

var _ = require('lodash');


interface actionTypeCreateVisible {
    type: "SETVISIBLECREATE",
    visible: boolean
}
interface actionTypeUpdateVisible {
    type: "SETVISIBLEUPDATE",
    visible: boolean
}
interface actionTypeDeleteVisible {
    type: "SETVISIBLEDELETE",
    visible: boolean
}
interface actionTypeSetValidator {
    type: "SETVALIDATOR",
    validator: any
}
interface actionTypeSetDataSource {
    type: "SETDATASOURCE",
    value: any
}
interface actionTypeSetPagination {
    type: "SETPAGINATION",
    page: number | undefined,
    pageSize: number | undefined
}
interface actionTypeSetTotalcount {
    type: "SETTOTALCOUNT",
    totalcount: number,
}
interface actionTypeSetDataSourceTotalcount {
    type: "SETDATASOURCETOTALCOUNT",
    value: any,
    totalcount: number,
}
interface actionTypeSetRecord {
    type: "SETRECORD",
    value: any
}
interface actionTypeSetFilterWhere {
    type: "SETFILTERWHERE",
    where_filter: FilterStructureModel,
}
interface actionTypeSetOrderbyFilter {
    type: "SETORDERBYFILTER",
    orderby_filter: Order,
}
interface actionTypeSetColumns {
    type: "SETCOLUMNS",
    value: any[],
}
/*interface actionTypeSetFilterVisibility{
    type:"SETFILTERVISIBILITY",
    visible:boolean
}
interface actionTypeSetFilterDisplay{
    type:"SETFILTERDISPLAY",
    visible:boolean
}*/
interface actionTypeSetShowCreate {
    type: 'SETSHOWCREATE',
    visible: boolean,
    value: PresaPortDTO,
    validator: PreseValidator
}
interface actionTypeSetShowUpdate {
    type: 'SETSHOWUPDATE',
    visible: boolean,
    value: PresaPortDTO,
    validator: PreseValidator
}
interface actionTypeSetUploadVisible {
    type: 'SETUPLOADVISIBLE',
    visible: boolean
}
interface actionTypeSetImageCall {
    type: 'SETIMAGECALL',
    value: boolean
}
interface actionTypeSetShowTracking {
    type: 'SETSHOWTRACKING',
    tracking_visible: boolean,
    record: PresaPortDTO,
}
export type actions = actionTypeCreateVisible | actionTypeSetValidator | actionTypeSetFilterWhere
    | actionTypeSetDataSource | actionTypeDeleteVisible | actionTypeUpdateVisible
    | actionTypeSetPagination | actionTypeSetTotalcount | actionTypeSetDataSourceTotalcount
    | actionTypeSetRecord | actionTypeSetOrderbyFilter | actionTypeSetShowUpdate
    | actionTypeSetColumns //| actionTypeSetFilterVisibility | actionTypeSetFilterDisplay 
    | actionTypeSetShowCreate
    | actionTypeSetUploadVisible | actionTypeSetImageCall | actionTypeSetShowTracking
    ;


interface IReducer {
    create_visible: boolean,
    update_visible: boolean,
    delete_visible: boolean,
    tracking_visible: boolean,
    //upload_visible:boolean,
    record: PresaPortDTO,
    validator: PreseValidator,
    data_source: any[],
    totalcount: number,
    page: number | undefined,
    pageSize: number | undefined,
    set_date: string,
    where_filter: FilterStructureModel[],
    orderby_filter: Order[],
    columns: any[],
    first_call: boolean,
    timer_image_finished: boolean,

    //mobile filter visible
    //filter_visible:boolean,
    //filter_display:boolean
}

export function PreseReducer(state: IReducer, action: actions): IReducer {

    switch (action.type) {
        case 'SETIMAGECALL':
            return { ...state, timer_image_finished: action.value }
        /*case 'SETUPLOADVISIBLE':
            return {...state,upload_visible:action.visible}*/
        case 'SETSHOWTRACKING':
            return { ...state, tracking_visible: action.tracking_visible, record: action.record }
        case 'SETSHOWCREATE':
            return { ...state, create_visible: action.visible, validator: action.validator, record: action.value }
        case 'SETSHOWUPDATE':
            return { ...state, update_visible: action.visible, validator: action.validator, record: action.value }
        /*case 'SETFILTERDISPLAY':
            return {...state,filter_display:action.visible}
        case 'SETFILTERVISIBILITY':
            return {...state,filter_visible:action.visible}*/
        case 'SETCOLUMNS':
            return { ...state, columns: action.value }
        case 'SETRECORD':
            return { ...state, record: action.value }
        case 'SETVISIBLECREATE':
            return { ...state, create_visible: action.visible }
        case 'SETVISIBLEUPDATE':
            return { ...state, update_visible: action.visible }
        case 'SETVISIBLEDELETE':
            return { ...state, delete_visible: action.visible }
        case 'SETVALIDATOR':
            return { ...state, validator: action.validator }
        case 'SETDATASOURCE':
            return { ...state, data_source: action.value }
        case 'SETTOTALCOUNT':
            return { ...state, totalcount: action.totalcount }
        case 'SETPAGINATION':
            return { ...state, page: action.page, pageSize: action.pageSize }
        case 'SETDATASOURCETOTALCOUNT':
            return { ...state, totalcount: action.totalcount, data_source: action.value, first_call: false }
        case 'SETFILTERWHERE':
            const where_array: FilterStructureModel[] = _.filter(state.where_filter, (item: FilterStructureModel) => item.table_View_ColumnName !== action.where_filter.table_View_ColumnName);
            if (action.where_filter.value !== "") {
                const s = {
                    ...state, where_filter: [
                        ...where_array,
                        action.where_filter
                    ], first_call: false
                }
                return s;
            }

            return { ...state, where_filter: where_array, first_call: false }
        case 'SETORDERBYFILTER':
            const order_filter: Order[] = _.filter(state.orderby_filter, (item: Order) => item.table_View_ColumnName !== action.orderby_filter.table_View_ColumnName);

            return { ...state, orderby_filter: [...order_filter, action.orderby_filter], first_call: false }
        default:
            return { ...state }

    }
}

