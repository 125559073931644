import { Redirect, Route, Switch } from "react-router-dom";
import { UsersPage } from "../Users/UsersPage";
import store from "../Redux/Store";
import { PreseList } from "../Prese/PreseList";
import { RubricaList } from "../Rubrica/RubricaList";
import { OperatoreList } from "../Rubrica/OperatoreList";
import { TrackingList } from "../Tracking/TrackingList";
import React from "react";
import { Button } from "antd";
import { SearchShipping } from "../CercaSpedizione/SearchShipping";
import { PreventiviPage } from "../Preventivi/PreventiviPage";

interface RoutesProps {
}

export const Routes: React.FC<RoutesProps> = props => {


  return (
    store.getState().user_type === 'normal' ?
      <Switch>
        <Route exact path="/CercaSpedizione" component={() => <SearchShipping />} />
        <Route exact path="/Tracking" component={() => <TrackingList />} />
        <Route exact path="/Prese" component={() => <PreseList />} />
        <Route exact path="/RubricaMittenti" component={() => <RubricaList type='mittenti' />} />
        <Route exact path="/RubricaOperatori" component={() => <OperatoreList />} />
        <Route path="/ConfermaPreventivo/:guid" component={() => <PreventiviPage  />} />


        {store.getState().enablePrese ?
          <Route path="/" component={() => <Redirect to="/Prese" />} />
          :
          store.getState().enableTracking ?
            <Route path="/" component={() => <Redirect to="/Tracking" />} />
            :
            store.getState().user_type === 'superuser' ?
              <Route path="/" component={() => <Redirect to="/Users" />} />
              :
              <Route path="/" component={() => <Redirect to="/RubricaOperatori" />

              } />
        }
      </Switch>
      :
      <Switch>
        <Route exact path="/CercaSpedizione" component={() => <SearchShipping />} />
        <Route exact path="/Tracking" component={() => <TrackingList />} />
        <Route exact path="/Prese" component={() => <PreseList />} />
        <Route exact path="/Users" component={() => <UsersPage />} />
        <Route path="/ConfermaPreventivo/:guid" component={() => <PreventiviPage  />} />

        {store.getState().enablePrese ?
          <Route path="/" component={() => <Redirect to="/Prese" />} />
          :
          store.getState().enableTracking ?
            <Route path="/" component={() => <Redirect to="/Tracking" />} />
            :
            store.getState().user_type === 'superuser' ?
              <Route path="/" component={() => <Redirect to="/Users" />} />
              :
              <Route path="/" component={() => <Redirect to="/RubricaOperatori" />} />
        }

      </Switch>


  );
}