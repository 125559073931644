export const Today = (): string => {
    const D = new Date();
    let today :string="";
    if(D.getDate() < 10){
        if((D.getMonth()+1) < 10){
            today = D.getFullYear()+'-0'+(D.getMonth()+1)+'-0'+D.getDate();
        }else{
            today = D.getFullYear()+'-'+(D.getMonth()+1)+'-0'+D.getDate();
        }
    }else{
        if((D.getMonth()+1) < 10){
            today = D.getFullYear()+'-0'+(D.getMonth()+1)+'-'+D.getDate();
        }else{
            today = D.getFullYear()+'-'+(D.getMonth()+1)+'-'+D.getDate();
        }
    }

    return today;
}