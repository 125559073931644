import { Button, Checkbox, Divider, Form, Select } from 'antd';
import React from 'react';
import '../../CustomCss/InputTypeCss/InputType.scss';
import RestUrl from '../../RestUrl.json';
import { FormPasswordInput, FormTextInput_old } from '../Utils_Component/InputForm';
import { notifyError } from '../CustomToast/customToast';
import { TranslateString } from '../../i18n/translate';
import { UserPortDto } from '../Model/UsersModel';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { UsersValidator } from './UsersInterface';
import { RestCall } from '../Rest/Rest_Call/RestCall';
import {
    KeyOutlined, ReloadOutlined, PhoneOutlined, MailOutlined, CheckOutlined, CloseOutlined, ExclamationCircleOutlined, PlusOutlined, EyeOutlined, ClearOutlined, ApartmentOutlined, LeftOutlined, EditOutlined, DeleteOutlined, CopyOutlined
} from '@ant-design/icons';

interface UserFormProps {
    user: UserPortDto,
    validator: UsersValidator,
    onChange: (user: UserPortDto) => void
}

const { Option } = Select;

export const UserForm: React.FC<UserFormProps> = props => {

    const [user, setUser] = React.useState(props.user);
    const [ragioneSociale, setRagioneSociale] = React.useState<string>('');
    const [users, setUsersType] = React.useState([]);

    React.useEffect(//the first time the component is generated, it loads the dashboard data from the database if it hasn't the default id value (-1)
        () => {
            if (props.user.id > 0) {
                loadByID();
            }

        }, []
    )


    const loadByID = () => {
        RestCall.getById(RestUrl.USER_GET_BY_ID, props.user.id, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setUser(result.data);
                } else if (typeof result === 'string') {
                    if (result === "VALID_TOKEN") {
                        loadByID();
                    } else {
                        notifyError(result);
                    }
                } else {
                    notifyError("@UNHANDLE_ERROR");
                }
            }
        );
    }

    const loadCliente = (code: string) => {
        if (code !== null && code !== undefined && code.length > 0) {
            RestCall.post_with_result_no_pagination({ codiceCliente: code }, RestUrl.CLIENTE_POST_SEARCHCLIENTIMETODO, 'url').then(
                result => {

                    if (typeof result !== 'undefined' && typeof result !== 'string') {

                        if (result.data !== null && result.data.data !== null) {
                            setRagioneSociale(result.data.data.ragioneSociale);
                            setCodiceCliente(result.data.data.codice);

                            const newUser = {
                                ...user,
                                codiceCliente: result.data.data.codice,
                                userName: result.data.data.codice.replace('C', '').trim()
                            };
                            setUser(newUser);

                            props.onChange(newUser);
                        }
                        else {
                            notifyError('@SEARCH_BP_NOT_FOUND');
                            setRagioneSociale('');
                        }
                    }
                    if (typeof result === 'string') {
                        if (result === 'VALID_TOKEN') {
                            loadCliente(code);
                        } else {
                            notifyError(result);
                            setRagioneSociale('');
                        }
                    }
                }
            )
        }
        else {
            setRagioneSociale('');
            setCodiceCliente('');
        }
    }

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>| React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]

        loadCliente(value);

    }

    const setCodiceCliente = (code: string) => {
        const newUser = {
            ...user,
            codiceCliente: code
        };
        setUser(newUser);

        props.onChange(newUser);
    }

    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>| React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]
        const newUser = {
            ...user,
            [name]: value
        };
        setUser(newUser);//aggiunge i nuovi valori all'interno della form

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newUser);

        if (name === 'codiceCliente') {
            setRagioneSociale('');
        }
    }

    const handleCheckChanges = (evt: CheckboxChangeEvent) => {
        const name = evt.target.name;
        const checked = evt.target.checked;

        if (typeof name !== 'undefined') {
            let newUser;
            if (name === "enableTracking" && checked === false) {
                newUser = {
                    ...user,
                    [name]: checked,
                    enableDownloadPod: false
                };
            }
            else {
                newUser = {
                    ...user,
                    [name]: checked,
                };
            }

            setUser(newUser);//aggiunge i nuovi valori all'interno della form

            props.onChange(newUser);//informa la classe padre che la form ha cambiato il suo stato.
        }
    }

    const generatePassword = () => {
        RestCall.getById(RestUrl.CREATE_PASSWORD, 10, 'login_url').then(
            result => {
                if (typeof result === 'string') {

                    const newUser = {
                        ...user,
                        userPassword: result
                    };
                    setUser(newUser);//aggiunge i nuovi valori all'interno della form

                    //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
                    props.onChange(newUser);
                }
                else {
                    notifyError(result);
                }
            }
        );
    }

    return (
        <div className="container-col">
            <Form>
                <FormTextInput_old onChange={handleChanges} label_name="CLIENTE_METODO" mandatory={false} input_name={"codiceCliente"} input_value={user.codiceCliente!} validate={undefined} onBlur={handleBlur} />
                <div style={{ height: 60 }}>{ragioneSociale}</div>
                <FormTextInput_old onChange={handleChanges} label_name="USERNAME" mandatory={true} input_name={"userName"} input_value={user.userName} validate={props.validator.username} />
                {props.user.id !== 0 ?
                    null :
                    <div>

                        <FormPasswordInput onChange={handleChanges} label_name="PASSWORD" mandatory={true} input_name={"userPassword"} input_value={user.userPassword} validate={props.validator.password} />

                        <Button
                            className={"table-new-button"}
                            title={''}
                            style={{ marginLeft: "5px" }}
                            size={'small'}
                            icon={<KeyOutlined />}
                            onClick={generatePassword}
                        >Genera password</Button>
                    </div>
                }
                {/*<div className="container-row">
                    <Checkbox defaultChecked={props.user.hideAlerts!} name="hideAlerts" onChange={(evt) => handleCheckChanges(evt)} >
                        {TranslateString("@HIDE_ALERTS")}
                    </Checkbox>
    </div>*/}
                <div className="container-row">
                    <Checkbox defaultChecked={user.isBackoffice!} name="isBackoffice" onChange={(evt) => handleCheckChanges(evt)} >
                        {TranslateString("@USER_BACKOFFICE")}
                    </Checkbox>
                </div>
                <div className="container-row margin-top-15">
                    <Checkbox defaultChecked={user.enableTracking!} name="enableTracking" onChange={(evt) => handleCheckChanges(evt)} >
                        {TranslateString("@VISIBLE_TRACKING")}
                    </Checkbox>

                </div>
                <div className="container-row margin-bottom-15 margin-left-15">
                    <Checkbox disabled={user.enableTracking === false} checked={user.enableDownloadPod} name="enableDownloadPod" onChange={(evt) => handleCheckChanges(evt)} >
                        {TranslateString("@VISIBLE_DOWNLOAD")}
                    </Checkbox>
                </div>

                <div className="container-row">
                    <Checkbox defaultChecked={user.enablePrese!} name="enablePrese" onChange={(evt) => handleCheckChanges(evt)} >
                        {TranslateString("@VISIBILE_PRESE")}
                    </Checkbox>
                </div>
            </Form>
        </div>
    );
}

