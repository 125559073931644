import { Badge, Layout, Menu } from 'antd';
import '../../CustomCss/LayoutCss/Layout.scss';
import { Link } from 'react-router-dom';
import { TranslateString } from '../../i18n/translate';
import React, { useMemo } from 'react';
import { AvatarComponent } from '../Utils_Component/AvatarComponent/AvatarComponent';
import { Routes } from '../Route/Routes';
import { GetUrlId } from '../../utils/ChangeUrl';
import store from '../Redux/Store';
import { MenuOutlined, IdcardOutlined, SendOutlined, TeamOutlined, HomeOutlined, DesktopOutlined, BarcodeOutlined, SearchOutlined } from '@ant-design/icons';
import { Footer } from 'antd/lib/layout/layout';
import { BannerModel } from '../Model/Banner';
import { RestCall } from '../Rest/Rest_Call/RestCall';
import RestUrl from '../../RestUrl.json';
import { BannerComponent } from './BannerComponent';

var _ = require('lodash');

const { SubMenu } = Menu;
const { Header, Sider, Content } = Layout;

export interface MenuProps {
}

export const DeskTopSider: React.FC<MenuProps> = props => {

  const [selected_menu, setSelectedMenu] = React.useState('');
  const [state, setState] = React.useState(store.getState());
  const [collapse, setCollapse] = React.useState(false);
  const [banners, setBanners] = React.useState<BannerModel[]>([]);

  const [notification_count, setNotificationCount] = React.useState(1);

  const handletranslate = () => {
    setState(store.getState());
  }
  store.subscribe(handletranslate);

  React.useEffect(
    () => {
      handleMenuSelected();
      loadBanners();
    }, []
  );

  React.useEffect(
    () => {
      handleMenuSelected();
      loadBanners();
    }, [store]
  );

  const loadBanners = () => {
    RestCall.getAll(RestUrl.BANNER_GET_ALL, 'url').then(
      result => {
        if (typeof result !== 'undefined' && typeof result !== 'string') {
          setBanners(result.data);
        } else if (typeof result === 'string') {
          if (result === 'VALID_TOKEN') {
            loadBanners();
          } else {
          }
        }
      }
    );
  }

  const handleMenuSelected = () => {
    setSelectedMenu(GetUrlId("#/"));
  }
  const handleMenuCLick = (key: string) => {
    setSelectedMenu(key);
  }

  const handleCollapseToggle = () => {
    setCollapse(!collapse);
  }

  const memoRoutes = useMemo(() => {
    return <Routes />
  }, [selected_menu])


  return (
    <Layout style={{ height: '100vh' }}>
      <Header className="header">
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

          <div className="Application-Title" style={{ display: 'flex', alignItems: 'center' }}>
            <MenuOutlined onClick={handleCollapseToggle} style={{ marginLeft: '-30px', marginRight: 10 }} /><div className='logoIMGHeader' />

          </div>
          <div style={{ maxWidth: '50%' }}>
            <BannerComponent banners={banners} />
          </div>
          <AvatarComponent className="cursor-pointer" />
        </div>
      </Header>
      <Layout  >
        <Sider collapsedWidth={"50px"} width="200px" collapsed={collapse}>
          <Menu
            mode="inline"
            selectedKeys={[selected_menu]}
            openKeys={['submenuRubrica']}
            style={{ height: '100%', borderRight: 0 }}
          >
            {store.getState().user_type === 'superuser' ?
              <Menu.Item icon={<HomeOutlined />} onClick={() => handleMenuCLick("Users")} key="Users"><Link to="/Users">{TranslateString("@USER_TITLE")}</Link></Menu.Item> :
              <></>
            }

            {store.getState().enablePrese ?
              <Menu.Item icon={<TeamOutlined />} onClick={() => handleMenuCLick("Prese")} key="Prese"><Link to="/Prese">{TranslateString("@PRESE")}</Link></Menu.Item>
              :
              <></>
            }
            {store.getState().enableTracking ?
              <Menu.Item icon={<BarcodeOutlined />} onClick={() => handleMenuCLick("Tracking")} key="Tracking"><Link to="/Tracking">{TranslateString("@TRACKING")}</Link></Menu.Item>
              :
              <></>
            }
            {store.getState().user_type === 'normal' ?
              <SubMenu className='submenu-no-icon' key="submenuRubrica" icon={<IdcardOutlined />} title={TranslateString("@RUBRICHE")}>
                <Menu.Item icon={<SendOutlined />} onClick={() => handleMenuCLick("RubricaMittenti")} key="RubricaMittenti"><Link to="/RubricaMittenti">{TranslateString("@RUBRICA")}</Link></Menu.Item>
                {/*<Menu.Item icon={<DownloadOutlined />} onClick={() => handleMenuCLick("RubricaDestinatari")} key="RubricaDestinatari"><Link to="/RubricaDestinatari">{TranslateString("@RUBRICA_DESTINATARI")}</Link></Menu.Item>*/}
                <Menu.Item icon={<DesktopOutlined />} onClick={() => handleMenuCLick("RubricaOperatori")} key="RubricaOperatori"><Link to="/RubricaOperatori">{TranslateString("@RUBRICA_OPERATORI")}</Link></Menu.Item>
              </SubMenu>
              : <></>
            }
            <Menu.Item icon={<SearchOutlined />} onClick={() => handleMenuCLick("CercaSpedizione")} key="CercaSpedizione"><Link to="/CercaSpedizione">{TranslateString("@CERCA_SPEDIZIONE")}</Link></Menu.Item>

          </Menu>
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            className="site-layout-background "
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {
              state.isLogged !== false && memoRoutes
            }
          </Content>
        </Layout>
      </Layout>
      <Footer className='footer' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', color: '#FFF' }}><div >{TranslateString('@DISCLAIMER')}</div></Footer>
    </Layout>
  );
}
