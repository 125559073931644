import { Alert, Button, Card, Drawer, Empty, Form, Input, Select, TimePicker, Tooltip } from "antd";
import React, { ReactElement, useState } from "react";
import { TranslateString } from "../../i18n/translate";
import { notifyError } from "../CustomToast/customToast";
import { DestinazionePortDTO, MittentePortDTO, PresaPortDTO } from "../Model/PreseModel";
import { checkStringOnlyNumbers, FormDataPickerInput, FormTextInput_new, FormTimeInput } from "../Utils_Component/InputForm"
import { checkOrariApertura, split_DD_MM_YYYY_with_slash_no_null } from "../Utils_Component/FormatDate";
import { SingleUpload } from "../Utils_Component/UploadFIle";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { PreseFormDestinazione } from "./PreseFormDestinazioni";
import moment from "moment";
import { Moment } from "moment";
import {
    PhoneOutlined, MailOutlined, HomeOutlined, IdcardOutlined, ReloadOutlined, PlusOutlined, ApartmentOutlined, LeftOutlined, EditOutlined, DeleteOutlined, CopyOutlined
} from '@ant-design/icons';
import { RestCall } from "../Rest/Rest_Call/RestCall";
import RestUrl from '../../RestUrl.json';
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import Search from "antd/lib/input/Search";
import { RangePickerProps } from "antd/lib/date-picker";
import { TFestivi } from "../Model/Festivi";
import { ProvLocCapDTO } from "../Model/RubricaModel";
import { FormSelectTime } from "../Utils_Component/FormSelect";
var _ = require('lodash');
const { Option } = Select;


interface FormProps {
    record: PresaPortDTO,
    onChange: (record: PresaPortDTO) => void,
    readonly?: boolean,
}


export const PreseForm: React.FC<FormProps> = props => {
    //const [rubricaDestinatari, setRubricaDestinatari] = React.useState<any[]>([]);
    const [visibleRubricaDestinatari, setVisibleRubricaDestinatari] = useState(false);
    const [rubricaMittenti, setRubricaMittenti] = React.useState<any[]>([]);
    const [visibleRubricaMittenti, setVisibleRubricaMittenti] = useState(false);
    const [rubricaOperatori, setRubricaOperatori] = React.useState<any[]>([]);

    //const [searchRubricaText, setSearchRubricaText] = React.useState<any>(undefined);
    const [listProvince, setListProvince] = React.useState<any[]>([]);
    const [listCap, setListCap] = React.useState<any[]>([]);
    const [listLocalita, setListLocalita] = React.useState<any[]>([]);
    const [holidays, setHolidays] = React.useState<TFestivi[]>([]);

    const isFormReadOnly = props.readonly;

    const showDrawerRubricaDestinatari = (load: boolean = false) => {
        if (load) {
            loadRubricaMittenti();
        }
        setVisibleRubricaDestinatari(true);
    };

    const showDrawerRubricaMittenti = (load: boolean = false) => {
        if (load) {
            loadRubricaMittenti();
        }
        setVisibleRubricaMittenti(true);
    };
    const onCloseRubricaMittenti = () => {
        setVisibleRubricaMittenti(false);
        setVisibleRubricaDestinatari(false);
    };

    React.useEffect(
        () => {
            loadRubricaOperatori();
            if (!props.readonly) {
                loadRubricaMittenti();
                reloadListaIndirizzi();
                getHolidays();
            }
        }, []
    )

    React.useEffect(
        () => {
            reloadListaIndirizzi();
        }, [props.record.mittente.cap, props.record.mittente.localita, props.record.mittente.provincia]
    );

    const reloadListaIndirizzi = () => {
        loadListaProvince();
        loadListaCap();
        loadListaLocalita();
    }

    const loadListaProvince = () => {
        const payload: ProvLocCapDTO = {
            provincia: null,//props.record.provincia,
            localita: props.record.mittente.localita,
            cap: props.record.mittente.cap
        }

        RestCall.post_with_result_no_pagination(payload, RestUrl.RUBRICA_PROVINCE_NEW, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setListProvince(result.data.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaProvince();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    }

    const loadListaCap = () => {
        const payload: ProvLocCapDTO = {
            provincia: props.record.mittente.provincia,
            localita: props.record.mittente.localita,
            cap: null,//props.record.cap
        }

        RestCall.post_with_result_no_pagination(payload, RestUrl.RUBRICA_CAPS_NEW, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setListCap(result.data.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaCap();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    }

    const loadListaLocalita = () => {
        const payload: ProvLocCapDTO = {
            provincia: props.record.mittente.provincia,
            localita: null,//props.record.localita,
            cap: props.record.mittente.cap
        }
        RestCall.post_with_result_no_pagination(payload, RestUrl.RUBRICA_LOCALITA_NEW, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setListLocalita(result.data.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaLocalita();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );

    }

    const loadRubricaMittenti = (search?: string) => {
        getRubricaMittenti(search).then(
            result => {
                if (result !== undefined) {
                    setRubricaMittenti(result);
                }
            }
        );

    }

    const getRubricaMittenti = (search?: string) => {
        return RestCall.getAll(RestUrl.RUBRICA_MITTENTE_GET_ALL + (search !== undefined ? '\\' + search : ''), 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    return result.data;
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        getRubricaMittenti(search);
                    } else {
                        notifyError(result);
                        return undefined;
                    }
                }
            }
        );
    }

    /*const loadRubricaDestinatari = (search?: string) => {
        getRubricaDestinatari(search).then(
            result => {
                if (result !== undefined) {
                    setRubricaDestinatari(result);
                }
            }
        );
    }*/

    /*const getRubricaDestinatari = (search?: string) => {
        return RestCall.getAll(RestUrl.RUBRICA_DESTINATARIO_GET_ALL + (search !== undefined ? '\\' + search : ''), 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    return result.data;
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        getRubricaDestinatari(search);
                    } else {
                        notifyError(result);
                        return undefined;
                    }
                }
            }
        );
    }*/

    const loadRubricaOperatori = () => {
        RestCall.getAll(RestUrl.RUBRICA_OPERATORE_GET_ALL, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setRubricaOperatori(result.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadRubricaOperatori();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    }

    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>| React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]
        const newRecord = {
            ...props.record,
            [name]: value
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const handleChangesOperatore = (value: any) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form

        const newRecord = {
            ...props.record,
            operatorePortale: value
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);

    }

    const handleTimeChanges = (fieldName: string | undefined, time: Moment | null, timeString: string) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form

        if (fieldName !== undefined) {
            const newRecord = {
                ...props.record,
                [fieldName]: time !== null ? time.format('YYYY-MM-DD[T]HH:mm:ss') : ''
            };

            //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
            props.onChange(newRecord);
        }
    }

    const handleTimeMittenteChanges = (fieldName: string | undefined, time: Moment | null, timeString: string) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form

        if (fieldName !== undefined) {
            const newRecord = {
                ...props.record,
                mittente: {
                    ...props.record.mittente,
                    [fieldName]: time !== null ? time.format('YYYY-MM-DD[T]HH:mm:ss') : ''
                }
            };
            //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
            props.onChange(newRecord);
        }
    }

    
    const onSearchSelect = (fieldName: string | undefined, timeString: string) => {
        if (fieldName !== undefined) {
            const newRecord = {
                ...props.record,
               [fieldName]: timeString
            };
            props.onChange(newRecord);
        }
    }

    const onSearchMittente = (fieldName: string | undefined, timeString: string) => {
        if (fieldName !== undefined) {
            const newRecord = {
                ...props.record,
                mittente: {
                    ...props.record.mittente,
                    [fieldName]: timeString
                }
            };
            //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
            props.onChange(newRecord);
        }
    }

    const handleSearchMittente = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Ensure it is only this code that runs

            getRubricaMittenti(props.record.mittente.ragioneSociale).then(
                result => {
                    if (result !== undefined) {
                        if (result.length === 1) {
                            updateMittente(result[0]);
                        } else {
                            loadRubricaMittenti(props.record.mittente.ragioneSociale);
                            showDrawerRubricaMittenti();
                        }
                    }
                }
            );

        }
    }

    const handleSearchDestinatario = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Ensure it is only this code that runs

            /*getRubricaDestinatari(props.record.destinazioni[0].ragioneSociale).then(
                result => {
                    if (result !== undefined) {
                        if (result.length === 1) {
                            updateDestinatario(result[0]);
                        } else {
                            loadRubricaDestinatari(props.record.destinazioni[0].ragioneSociale);
                            showDrawerRubricaDestinatari();
                        }
                    }
                }
            );*/

            getRubricaMittenti(props.record.destinazioni[0].ragioneSociale).then(
                result => {
                    if (result !== undefined) {
                        if (result.length === 1) {
                            updateDestinatario(result[0]);
                        } else {
                            loadRubricaMittenti(props.record.destinazioni[0].ragioneSociale);
                            showDrawerRubricaMittenti();
                        }
                    }
                }
            );

        }
    }

    const handleChangesMittentePortDTO = (event: React.ChangeEvent<HTMLInputElement>| React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]
        const newRecord = {
            ...props.record,
            mittente: {
                ...props.record.mittente,
                [name]: value
            }
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const handleChangesDestinazione = (updated: DestinazionePortDTO) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        let newDestinazioni = props.record.destinazioni.filter(p => p.id != updated.id);

        newDestinazioni.unshift(updated); //push element at 0

        const newRecord = {
            ...props.record,
            destinazioni: newDestinazioni
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    /*const handleSelect = (value: any, name: string) => {
        const newRecord = {
            ...props.record,
            [name]: value
        };
        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }*/

    const handleDataChanges = (event: any, value?: any) => {
        const newDate = event !== null ? (event as Moment).format('YYYY-MM-DD[T]HH:mm:ss') : '';

        const newRecord = {
            ...props.record,
            dataRitiro: newDate
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const handleCheckChanges = (evt: CheckboxChangeEvent) => {
        const name = evt.target.name;
        if (typeof name !== 'undefined') {
            let newRecord = {
                ...props.record
            }

            if (name === 'orarioContinuato') {
                newRecord = {
                    ...newRecord,
                    mittente:
                    {
                        ...newRecord.mittente,
                        orarioContinuato: evt.target.checked
                    }
                };

                if (evt.target.checked) {
                    newRecord = {
                        ...newRecord,
                        mittente: {
                            ...newRecord.mittente,
                            orarioApertura2: null,
                            orarioChiusura2: null
                        }
                    }
                }
            }
            else {
                newRecord = {
                    ...newRecord,
                    [name]: evt.target.checked
                };

                //se pronto devo svuotare l'eventuale ora di ritiro
                if (name === 'pronto' && evt.target.checked) {
                    newRecord = {
                        ...newRecord,
                        oraRitiro: ''
                    }
                }
            }


            //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
            props.onChange(newRecord);
        }
    }

    const handleChangesProvincia = (value: any) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const newRecord = {
            ...props.record,
            mittente: {
                ...props.record.mittente,
                provincia: value,
                //cap: '',
                //localita: ''
            }
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);

        //loadListaCap(value);
        //loadListaLocalita(value, '');
    }

    const handleChangesCap = (value: any) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const newRecord = {
            ...props.record,
            mittente: {
                ...props.record.mittente,
                cap: value,
                //localita: ''
            }
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);

        //loadListaLocalita(props.record.mittente.provincia, value);
    }

    const handleChangesLocalita = (value: any) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form



        const newRecord = {
            ...props.record,
            mittente: {
                ...props.record.mittente,
                localita: value,
                //cap: loc.cap
            }
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);

    }

    const setFromRubricaMittente = (element: any) => {
        onCloseRubricaMittenti();
        updateMittente(element);
    };

    const setFromRubricaDestinatario = (element: any) => {
        onCloseRubricaMittenti(); //onCloseRubricaDestinatari();
        updateDestinatario(element);
    };

    const updateMittente = (data: any) => {
        let newMittente = MittentePortDTO.getDefault();
        newMittente = {
            ...newMittente,
            ragioneSociale: data.ragioneSociale,
            indirizzo: data.indirizzo,
            provincia: data.provincia,
            cap: data.cap,
            localita: data.localita,
            telefono: data.telefono,
            mail: data.mail,
            perContoDi: data.perContoDi,
            codiceNazione: data.codiceNazione,
            orarioApertura1: data.orarioApertura1,
            orarioChiusura1: data.orarioChiusura1,
            orarioApertura2: data.orarioApertura2,
            orarioChiusura2: data.orarioChiusura2,
            orarioContinuato: data.orarioContinuato
        };

        const newRecord = {
            ...props.record,
            mittente: newMittente
        }

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const updateDestinatario = (data: any) => {
        /*let newDestinatario = DestinazionePortDTO.getDefault();
        newDestinatario = {
            ...newDestinatario,
            ragioneSociale: data.ragioneSociale,
            indirizzo: data.indirizzo,
            provincia: data.provincia,
            cap: data.cap,
            localita: data.localita,
            telefono: data.telefono,
            codiceNazione: data.codiceNazione
        };*/

        let newDestinatario = {
            ...props.record.destinazioni[0],
            ragioneSociale: data.ragioneSociale,
            indirizzo: data.indirizzo,
            provincia: data.provincia,
            cap: data.cap,
            localita: data.localita,
            telefono: data.telefono,
            perContoDi: data.perContoDi,
            codiceNazione: data.codiceNazione
        };

        const newRecord = {
            ...props.record,
            destinazioni: [newDestinatario]
        }

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const getMyData = () => {
        RestCall.getAll(RestUrl.RUBRICA_MITTENTE_GET_DEFAULT, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    updateMittente(result.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        getMyData();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    };
    const getHolidays = () => {
        RestCall.getAll(RestUrl.PRESE_GET_FESTIVI, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setHolidays(result.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        getHolidays();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    };

    const getMyDataDestinatari = () => {
        RestCall.getAll(/*RestUrl.RUBRICA_DESTINATARIO_GET_DEFAULT*/RestUrl.RUBRICA_MITTENTE_GET_DEFAULT, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    updateDestinatario(result.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        getMyDataDestinatari();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    };

    const onSearchRubrica = (value: string, type: 'mittenti' | 'destinatari') => {
        //type === 'mittenti' ? loadRubricaMittenti(value) : loadRubricaDestinatari(value);
        loadRubricaMittenti(value)
    }

    /*const clearSearch = (type: 'mittenti' | 'destinatari') => {
        let el = document.getElementById('search_'+type);
        (el as any).value = '';
        onSearchRubrica('', type);
    }*/

    const getDrawerBody = (type: 'mittenti' | 'destinatari'): JSX.Element => {

        const list = rubricaMittenti;//type === 'mittenti' ? rubricaMittenti : rubricaDestinatari;

        return <div className="drawer-body-rubrica">
            <Search id={'search_' + type} placeholder="Cerca in rubrica" onSearch={(value) => onSearchRubrica(value, type)} allowClear />
            {
                list.length > 0 ? list.map(p =>
                    <Button className="drawer-body-rubrica-item" onClick={() => { type === 'mittenti' ? setFromRubricaMittente(p) : setFromRubricaDestinatario(p) }}>
                        <div className="drawer-body-rubrica-item-row drawer-body-rubrica-item-title">{p.ragioneSociale}</div>
                        <div className="drawer-body-rubrica-item-row">{p.indirizzo}</div>
                        <div className="drawer-body-rubrica-item-row">{p.localita} {p.cap} ({p.provincia}) - {p.codiceNazione}</div>
                        <div className="drawer-body-rubrica-item-row"><PhoneOutlined /> {p.telefono}</div>
                        <div className="drawer-body-rubrica-item-row"><MailOutlined /> {p.mail}</div>
                    </Button>
                ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
        </div>;
    }

    const getTimeMemo = React.useMemo(() => {
        return _.isEmpty(props.record.oraRitiro) ? undefined : moment(props.record.oraRitiro!)
    }, [props.record.oraRitiro, props.record.pronto]);

    const checkDataRitiroValidate = React.useMemo(() => {
        return _.isEmpty(props.record.dataRitiro) || moment(props.record.dataRitiro) < moment().startOf('day') ? 'error' : undefined
    }, [props.record.dataRitiro]);

    const checkTimeValidate = React.useMemo(() => {
        const orario = moment(props.record.oraRitiro).format("HHmm");
        const validInterval = orario >= '0730' && orario <= '1630'
        return validInterval ? undefined : 'error';
    }, [props.record.oraRitiro]);

    const getTimeMittenteMattinoAperturaMemo = React.useMemo(() => {
        return _.isEmpty(props.record.mittente.orarioApertura1) ? undefined : moment(props.record.mittente.orarioApertura1!)
    }, [props.record.mittente.orarioApertura1]);

    const getTimeMittenteMattinoChiusuraMemo = React.useMemo(() => {
        return _.isEmpty(props.record.mittente.orarioChiusura1) ? undefined : moment(props.record.mittente.orarioChiusura1!)
    }, [props.record.mittente.orarioChiusura1]);

    const getTimeMittentePomeriggioAperturaMemo = React.useMemo(() => {
        return _.isEmpty(props.record.mittente.orarioApertura2) ? undefined : moment(props.record.mittente.orarioApertura2!)
    }, [props.record.mittente.orarioApertura2]);

    const getTimeMittentePomeriggioChiusuraMemo = React.useMemo(() => {
        return _.isEmpty(props.record.mittente.orarioChiusura2) ? undefined : moment(props.record.mittente.orarioChiusura2!)
    }, [props.record.mittente.orarioChiusura2]);

    


    const checkPhoneValidate = React.useMemo(() => {
        return _.isEmpty(props.record.mittente.telefono) || !checkStringOnlyNumbers(props.record.mittente.telefono) ? 'error' : undefined;
    }, [props.record.mittente.telefono]);



    //todo: non usata?
    const checkOrarioRitiro = () => {
        if (!_.isEmpty(props.record.mittente.provincia) && !_.isEmpty(props.record.dataRitiro) && (props.record.pronto || !_.isEmpty(props.record.oraRitiro))) {
            const query = props.record.mittente.provincia + '/' + props.record.pronto + '/' + props.record.dataRitiro + (props.record.pronto ? '' : '/' + props.record.oraRitiro);

            RestCall.getAll(RestUrl.CHECK_ORARIO_RITIRO + query, 'url').then(
                result => {
                    if (typeof result !== 'undefined' && typeof result !== 'string') {
                        if (result.responseType === 'error') {
                            notifyError(result.messages.join('; '));
                        }
                    } else if (typeof result === 'string') {
                        if (result === 'VALID_TOKEN') {
                            checkOrarioRitiro();
                        } else {
                            notifyError(result);
                        }
                    }
                }
            );
        }
    }

    // eslint-disable-next-line arrow-body-style
    const disabledDate: RangePickerProps['disabledDate'] = current => {
        let bcontrolla = false;
        const limitDate = moment().add(1, 'months');
        if (!current || current < moment().startOf('day')  || current > limitDate) {
            bcontrolla = true;
        }

        if (!bcontrolla) {
            for (var i = 0; i < holidays.length; i++) {
                if (!current || current.isBetween(holidays[i].datada, holidays[i].dataa)) {
                    bcontrolla = true;
                    break;
                }
            };
        }

        return bcontrolla;
    };

    return (
        <>
            <Drawer title="Rubrica" placement="right" onClose={onCloseRubricaMittenti} visible={visibleRubricaMittenti}>
                {getDrawerBody('mittenti')}
            </Drawer>
            {<Drawer title="Rubrica destinatari" placement="right" onClose={onCloseRubricaMittenti} visible={visibleRubricaDestinatari}>
                {getDrawerBody('destinatari')}
            </Drawer>}
            <Form>
                <Card title="Dati generali" size="small" headStyle={{ backgroundColor: '#fafafa' }}>
                    <div className="container-row">
                        <div className="column-1D2 container-col margin-right-5">
                            <div className="column-1">
                                <label className="label-font" htmlFor={'filterCustomer'}>Operatore</label>
                                <label className="label-font label-red" htmlFor={'filterCustomer_obbligatorio'}> *</label>
                            </div>
                            <Select
                                showSearch
                                placeholder={'Seleziona l\'operatore'}
                                optionFilterProp="children"
                                onChange={handleChangesOperatore}
                                value={props.record.operatorePortale!}
                                disabled={isFormReadOnly}
                            >
                                {
                                    rubricaOperatori.map(
                                        (item: any) =>
                                            <Option key={'opeartore_' + item.id} value={item.nome}>
                                                {item.nome}
                                            </Option>
                                    )
                                }
                            </Select>

                        </div>
                        <div className="column-1D2 container-col">
                            <FormTextInput_new
                                onChange={handleChanges}
                                label_name="RIFERIMENTO_FATTURAZIONE"
                                mandatory={false}
                                input_name={"riferimentoFatturazione"}
                                input_value={props.record.riferimentoFatturazione!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                    </div>
                </Card>
                <Card
                    title="Mittente"
                    size="small"
                    className=""
                    headStyle={{ backgroundColor: '#fafafa' }}
                    style={{ marginTop: '20px' }}
                    extra={isFormReadOnly ? <></> : <><Button className="table-new-button" onClick={() => showDrawerRubricaMittenti(true)}><IdcardOutlined />{TranslateString("@RUBRICA")}</Button><Button className="table-new-button margin-right-5" onClick={getMyData}><HomeOutlined />{TranslateString("@RUBRICA_MY_DATA")}</Button></>}>
                    <div className="container-row">
                        <div className="column-1D2 container-col margin-right-5">
                            <FormTextInput_new
                                onChange={handleChangesMittentePortDTO}
                                label_name="RAGSOC"
                                mandatory={true}
                                input_name={"ragioneSociale"}
                                input_value={props.record.mittente.ragioneSociale!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                                onKeyPress={handleSearchMittente}
                            />
                        </div>
                        <div className="column-1D2 container-col ">
                            <FormTextInput_new
                                onChange={handleChangesMittentePortDTO}
                                label_name="C/O"
                                mandatory={false}
                                input_name={"perContoDi"}
                                input_value={props.record.mittente.perContoDi!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                    </div>
                    <div className="container-row">
                        <div className="column-1D2 container-col ">
                            <FormTextInput_new
                                onChange={handleChangesMittentePortDTO}
                                label_name="INDIRIZZO"
                                mandatory={true}
                                input_name={"indirizzo"}
                                input_value={props.record.mittente.indirizzo!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                    </div>
                    <div className="container-row margin-bottom-15">
                        <div className="column-1D4 container-col margin-right-5">
                            <div className="column-1">
                                <label className="label-font" htmlFor={'filterCustomer'}>Provincia</label>
                                <label className="label-font label-red" htmlFor={'filterCustomer_obbligatorio'}> *</label>
                            </div>
                            <Select
                                showSearch
                                placeholder={'Seleziona...'}
                                optionFilterProp="children"
                                onChange={handleChangesProvincia}
                                value={props.record.mittente.provincia!}
                                allowClear
                            >
                                {
                                    listProvince.map(
                                        (item: any) =>
                                            <Option key={'provincia_' + item} value={item}>
                                                {item}
                                            </Option>
                                    )
                                }
                            </Select>
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            <div className="column-1">
                                <label className="label-font" htmlFor={'filterCustomer'}>Località</label>
                            </div>
                            <Select
                                showSearch
                                placeholder={'Seleziona...'}
                                optionFilterProp="children"
                                onChange={handleChangesLocalita}
                                value={props.record.mittente.localita!}
                                allowClear
                            >
                                {
                                    listLocalita.map(
                                        (item: any) =>
                                            <Option key={'localita_' + item} value={item}>
                                                {item}
                                            </Option>
                                    )
                                }
                            </Select>
                        </div>
                        <div className="column-1D5 container-col margin-right-5">
                            <div className="column-1">
                                <label className="label-font" htmlFor={'filterCustomer'}>CAP</label>
                            </div>
                            <Select
                                showSearch
                                placeholder={'Seleziona...'}
                                optionFilterProp="children"
                                onChange={handleChangesCap}
                                value={props.record.mittente.cap!}
                                allowClear
                            >
                                {
                                    listCap.map(
                                        (item: any) =>
                                            <Option key={'cap_' + item} value={item}>
                                                {item}
                                            </Option>
                                    )
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="container-row">
                        <div className="column-1D2 container-col margin-right-5">
                            <FormTextInput_new
                                onChange={handleChangesMittentePortDTO}
                                label_name="TELEFONO"
                                mandatory={true}
                                input_name={"telefono"}
                                input_value={props.record.mittente.telefono!}
                                validate={checkPhoneValidate}
                                helpMessage={TranslateString('@TELEFONO_VALIDATE_ERROR')}
                                readonly={isFormReadOnly}
                            />
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            <FormTextInput_new
                                onChange={handleChangesMittentePortDTO}
                                label_name="RIFERIMENTO_RITIRO"
                                mandatory={false}
                                input_name={"riferimentoRitiro"}
                                input_value={props.record.mittente.riferimentoRitiro!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                        <div className="column-1D2 container-col ">
                            <FormTextInput_new
                                onChange={handleChangesMittentePortDTO}
                                label_name="RIFERIMENTO"
                                mandatory={false}
                                input_name={"nome"}
                                input_value={props.record.mittente.nome!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                    </div>
                    <div className="container-row">
                        <div className="column-1D2 container-col margin-right-5">
                            {/*                             <FormTimeInput
                                onTimeChange={handleTimeMittenteChanges}
                                label_name="ORA_MITTENTE_MATTINO_APERTURA"
                                mandatory={true}
                                input_name={"orarioApertura1"}
                                time_value={getTimeMittenteMattinoAperturaMemo}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            /> */}
                            <FormSelectTime
                                record={props.record.mittente}
                                object_name={"orarioApertura1"}
                                label_name={"ORA_MITTENTE_MATTINO_APERTURA"}
                                validate={undefined}
                                mandatory={true}
                                onRecordChange={(e) => onSearchMittente('orarioApertura1', e.orarioApertura1)}
                                da={6}
                                a={19}
                            />
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            {/*                             <FormTimeInput
                                onTimeChange={handleTimeMittenteChanges}
                                label_name="ORA_MITTENTE_MATTINO_CHIUSURA"
                                mandatory={true}
                                input_name={"orarioChiusura1"}
                                time_value={getTimeMittenteMattinoChiusuraMemo}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            /> */}
                            <FormSelectTime
                                record={props.record.mittente}
                                object_name={"orarioChiusura1"}
                                label_name={"ORA_MITTENTE_MATTINO_CHIUSURA"}
                                validate={undefined}
                                mandatory={true}
                                onRecordChange={(e) => onSearchMittente('orarioChiusura1', e.orarioChiusura1)}
                                da={6}
                                a={19}
                            />
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            {/*                             <FormTimeInput
                                onTimeChange={handleTimeMittenteChanges}
                                label_name="ORA_MITTENTE_POMERIGGIO_APERTURA"
                                mandatory={true}
                                input_name={"orarioApertura2"}
                                time_value={getTimeMittentePomeriggioAperturaMemo}
                                validate={undefined}
                                readonly={(isFormReadOnly || props.record.mittente.orarioContinuato) ? true : undefined}
                            /> */}
                            <FormSelectTime
                                record={props.record.mittente}
                                object_name={"orarioApertura2"}
                                label_name={"ORA_MITTENTE_POMERIGGIO_APERTURA"}
                                validate={undefined}
                                mandatory={true}
                                readOnly={(isFormReadOnly || props.record.mittente.orarioContinuato) ? true : undefined}
                                onRecordChange={(e) => onSearchMittente('orarioApertura2', e.orarioApertura2)}
                                da={6}
                                a={19}
                            />
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            {/*                             <FormTimeInput
                                onTimeChange={handleTimeMittenteChanges}
                                label_name="ORA_MITTENTE_POMERIGGIO_CHIUSURA"
                                mandatory={true}
                                input_name={"orarioChiusura2"}
                                time_value={getTimeMittentePomeriggioChiusuraMemo}
                                validate={undefined}
                                readonly={(isFormReadOnly || props.record.mittente.orarioContinuato) ? true : undefined}
                            /> */}
                            <FormSelectTime
                                record={props.record.mittente}
                                object_name={"orarioChiusura2"}
                                label_name={"ORA_MITTENTE_POMERIGGIO_CHIUSURA"}
                                validate={undefined}
                                mandatory={true}
                                readOnly={(isFormReadOnly || props.record.mittente.orarioContinuato) ? true : undefined}
                                onRecordChange={(e) => onSearchMittente('orarioChiusura2', e.orarioChiusura2)}
                                da={6}
                                a={19}
                            />
                        </div>
                        <div className="column-1D2 container-col ">
                            <div className="column-1">
                                <label className="label-font" htmlFor={''}>&nbsp;</label>
                            </div>
                            <Checkbox checked={props.record.mittente.orarioContinuato} name="orarioContinuato" onChange={(evt) => handleCheckChanges(evt)} >
                                Continuato
                            </Checkbox>
                        </div>
                    </div>
                    {!checkOrariApertura(props.record.mittente.orarioApertura1, props.record.mittente.orarioChiusura1, props.record.mittente.orarioApertura2, props.record.mittente.orarioChiusura2, props.record.mittente.orarioContinuato) && <Alert message={TranslateString("@ERR_INSER_ORARI")} type="warning" showIcon style={{ marginBottom: 20 }} />}
                    <div className="container-row">
                        <div className="column-1D2 container-col margin-right-5">
                            <FormDataPickerInput
                                disabledDate={disabledDate}
                                onChangeDataPicker={handleDataChanges}
                                label_name="DATA_RITIRO"
                                mandatory={true}
                                input_name={"dataRitiro"}
                                date_value={props.record.dataRitiro!}
                                validate={isFormReadOnly ? undefined : checkDataRitiroValidate}
                                readonly={isFormReadOnly}
                                helpMessage={'Data obbl. e futura'}
                            />
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            {/*                             <FormTimeInput
                                onTimeChange={handleTimeChanges}
                                label_name="ORA_RITIRO"
                                mandatory={true}
                                input_name={"oraRitiro"}
                                time_value={getTimeMemo}
                                validate={isFormReadOnly || props.record.pronto ? undefined : checkTimeValidate}
                                readonly={isFormReadOnly || (props.record.pronto ? true : undefined)}
                                helpMessage={'Dalle 7:30 alle 16:30 (ogni 15\')'}
                            /> */}
                            <FormSelectTime
                                record={props.record}
                                object_name={"oraRitiro"}
                                label_name={"ORA_RITIRO"}
                                validate={isFormReadOnly || props.record.pronto ? undefined : checkTimeValidate}
                                readOnly={isFormReadOnly || (props.record.pronto ? true : undefined)}
                                onRecordChange={(e) => onSearchSelect('oraRitiro', e.oraRitiro)}
                                da={7}
                                a={16}
                                mandatory={true}
                                mezza={true}
                                helpMessage={'Dalle 7:30 alle 16:30 (ogni 15\')'}
                            />
                        </div>
                        <div className="column-1D2 container-col ">
                            <div className="column-1">
                                <label className="label-font" htmlFor={''}>&nbsp;</label>
                            </div>
                            <Checkbox disabled={isFormReadOnly} checked={props.record.pronto} name="pronto" onChange={(evt) => handleCheckChanges(evt)} >
                                {TranslateString("@FLAG_PRONTO")}
                            </Checkbox>
                        </div>
                    </div>
                </Card>
                <Card title="Altri dati" size="small" style={{ marginTop: '20px' }} headStyle={{ backgroundColor: '#fafafa' }}>
                    <div className="container-row">
                        <div className="column-1D2 container-col margin-right-5">
                            <Checkbox disabled={isFormReadOnly} checked={props.record.flagMercePericolosa} name="flagMercePericolosa" onChange={(evt) => handleCheckChanges(evt)} >
                                {TranslateString("@FLAG_MERCEPERICOLOSA")}
                            </Checkbox>
                        </div>
                        <div className="column-1D2 container-col margin-right-5">
                            <Checkbox disabled={isFormReadOnly} checked={props.record.flagPreavvisoTelefonico} name="flagPreavvisoTelefonico" onChange={(evt) => handleCheckChanges(evt)} >
                                {TranslateString("@FLAG_PREAVVISOTELEFONICO")}
                            </Checkbox>
                        </div>
                        <div className="column-1D2 container-col ">
                            <Checkbox disabled={isFormReadOnly} checked={props.record.flagSpondaIdraulica} name="flagSpondaIdraulica" onChange={(evt) => handleCheckChanges(evt)} >
                                {TranslateString("@FLAG_SPONDAIDRAULICA")}
                            </Checkbox>
                        </div>
                    </div>
                    <div className="container-row" style={{ marginTop: '10px' }}>
                        <div className="column-1D2 container-col">
                            <FormTextInput_new
                                onChange={handleChanges}
                                label_name="NOTE_RITIRO"
                                mandatory={false}
                                input_name={"noteRitiro"}
                                input_value={props.record.noteRitiro!}
                                validate={undefined}
                                readonly={isFormReadOnly}
                            />
                        </div>
                    </div>
                </Card>
                <Card
                    title="Destinazione"
                    size="small"
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                    headStyle={{ backgroundColor: '#fafafa' }}
                    extra={isFormReadOnly ? <></> : <><Button className="table-new-button" onClick={() => showDrawerRubricaDestinatari(true)}><IdcardOutlined />{TranslateString("@RUBRICA")}</Button><Button className="table-new-button margin-right-5" onClick={getMyDataDestinatari}><HomeOutlined />{TranslateString("@RUBRICA_MY_DATA")}</Button></>}
                >
                    <div className="container-row">
                        <PreseFormDestinazione readonly={isFormReadOnly} destinazione={props.record.destinazioni[0]} onChange={handleChangesDestinazione} onKeyPress={handleSearchDestinatario} />
                    </div>
                </Card>
            </Form>
        </>
    )
}
