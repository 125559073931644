
import { toast } from 'react-toastify';
import { ExclamationCircleOutlined, CheckCircleOutlined  } from '@ant-design/icons';
import translate, { TranslateString } from '../../i18n/translate';

export const  notifyError = (msg: string) => {
  toast.error(translate(msg), {//notifica di errore, all'interno di msg viene inserito il messaggio da tradurre
    icon:<ExclamationCircleOutlined style={{fontSize:"25px"}}/>,
    position: "bottom-right",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    
    })
}

export const  notifyErrorJSX = (msg: JSX.Element) => {
  toast.error(msg, {//notifica di errore, all'interno di msg viene inserito il messaggio da tradurre
    icon:<ExclamationCircleOutlined style={{fontSize:"25px"}}/>,
    position: "bottom-right",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    
    })
}


export const  notifyErrorPlain = (msg: string) => {
  toast.error(msg, {//notifica di errore, all'interno di msg viene inserito il messaggio da tradurre
    icon:<ExclamationCircleOutlined style={{fontSize:"25px"}}/>,
    position: "bottom-right",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    
    })
}


export const notifySucces = (msg: string) => { //notifica di successo, all'interno di msg viene inserito il messaggio da tradurre
  toast.success(translate(msg), {
    icon:<CheckCircleOutlined style={{fontSize:"25px"}}/>,
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
}

export const notifyInfo = (msg:string) => {
  toast.info(translate(msg), {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
}



