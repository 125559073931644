export class TrackingModel {
    constructor(
        public id: number,
        public numero: number,
        public ragioneSocialeMittente: string,
        public localitaMittente: string,
        public provinciaMittente: string,
        public ragioneSocialeDestinatario: string,
        public localitaDestinatario: string,
        public provinciaDestinatario: string,
        public nRifBollaMittente: string,
        public dataBordereau: string,
        public dataStatoConsegna: string,
        public statoBolla: number,
        public barcode: string,
    ) { }
    static getDefault = (): TrackingModel => {
        return (new TrackingModel(0, 0, '', '', '', '', '', '', '', '', '', -1, ''))
    }
}

/* 

barcode: "220230000340"
dataBordereau: "2023-04-11T00:00:00"
dataStatoConsegna: "2023-04-11T00:00:00"
id: 722237
localitaDestinatario: "CASTELGOFFREDO"
localitaMittente: "MEDOLE"
nRifBollaMittente: "123456"
numero: "34"
provinciaDestinatario: "MN"
provinciaMittente: "MN"
ragioneSocialeDestinatario: "VERDELUNA DI NICOLINI SILVIA"
ragioneSocialeMittente: "BLU ADVANCE SRL"
soggettoCodice: "C 10280"
statoBolla: 100
tipodocumen 

*/