import { actionsRedux } from "./Action"

export type UserType = "normal" | "backoffice" | "superuser" | undefined

interface Reduxstate {
    translations: any,
    serverUrl: string,
    isLogged: boolean,
    user_type: UserType,
    fab_timer: any,
    enableTracking: boolean,
    enablePrese: boolean,
    enableDownloadPod: boolean
}

const initialState: Reduxstate = {
    translations: { "": "" },
    serverUrl: "",
    isLogged: false,
    user_type: undefined,
    fab_timer: undefined,
    enableTracking: false,
    enablePrese: false,
    enableDownloadPod: false,
}

export function ReduxReducer(state: Reduxstate = initialState, action: actionsRedux): Reduxstate {

    switch (action.type) {
        case 'SETTRANSLATION':
            return { ...state, translations: action.payload }
        case 'SETSERVERURL':
            return { ...state, serverUrl: action.serverUrl }
        case 'SETLOGIN':
            if (action.isLogged)
                return { ...state, isLogged: action.isLogged }
            else
                return { ...state, isLogged: action.isLogged, enablePrese: false, enableTracking: false, enableDownloadPod: false }
        case 'SETPERMISSIONS':
            return { ...state, user_type: action.user_type }
        case 'SETFABTIMER':
            return { ...state, fab_timer: action.fab_timer }
        case 'SETISVISIBLE':
            return { ...state, enableTracking: action.enableTracking, enablePrese: action.enablePrese, enableDownloadPod: action.enableDownloadPod }
        default:
            return { ...state }

    }
}