import React, { Fragment } from 'react';
import {IntlProvider} from 'react-intl';

import {LOCALES} from './locales';

import TranslateJson from '../Translate.json';

const Provider = ({children, locale = LOCALES.ITALIAN, translate}) => (

    <IntlProvider
        locale = {locale}
        textComponent = {Fragment}
        messages = {TranslateJson}
    >
        {children}
    </IntlProvider>
);

export default Provider;