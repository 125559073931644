import { SITableFilterController, SITableFilterTypeValues } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterController';
import { SITableOrder } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterModel';
import { SITableColumnStructure } from "@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableModels";
import { TranslateString } from "../../i18n/translate";
import { Button, Tag } from 'antd';
import {
  CheckOutlined, EditOutlined, DeleteOutlined
} from '@ant-design/icons';


export const OperatoreColumns = (
  onOrderBy: (order_by: SITableOrder) => void,
  onFilter: (value: string, sql_name: string, operator: string, filter_type: SITableFilterTypeValues) => void,
  showUpdate: (row: any) => void,
  showDelete: (row: any) => void,
): any[] => {

  const columns: SITableColumnStructure[] = [
    {
      title: <SITableFilterController
        sql_name={"id"}
        alias={'ID'}
        onOrderBy={onOrderBy}
        filter_type={'Number'}
        onSearch={onFilter}
        titleAlign='end'
      />,
      key: 'id',
      column_type: 'Number',
      dataIndex: 'id',
      width: 110,
      padding: "0",
      render: (record: any) => (<div className="container-row c-ctrbottom-b padding-right-10 ">{record}</div>)
    },
    {
      title: <SITableFilterController
        sql_name={"nome"}
        alias={TranslateString('@NOME')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
        titleAlign='center'
      />,
      key: 'nome',
      column_type: 'String',
      dataIndex: 'nome',
      width: 220,
      padding: "0",
      //render: (record: string) => (<div className="container-row c-ctr ">{FormateDateWith00(record)}</div>)
    },
    {
      title: <SITableFilterController
        sql_name={"perContoDi"}
        alias={TranslateString('@PRESSO')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
        titleAlign='center'
      />,
      key: 'perContoDi',
      column_type: 'String',
      dataIndex: 'perContoDi',
      width: 220,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"mail"}
        alias={TranslateString('@MAIL')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      />,
      key: 'mail',
      column_type: 'String',
      dataIndex: 'mail',
      width: 220,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"mailTarga"}
        alias={TranslateString('@MAILTARGA')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      />,
      key: 'mailTarga',
      column_type: 'String',
      dataIndex: 'mailTarga',
      width: 200,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"telefono"}
        alias={TranslateString('@TELEFONO')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      />,
      key: 'telefono',
      column_type: 'String',
      dataIndex: 'telefono',
      width: 120,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={""}
        alias={"Action"}
        titleAlign='center'
      />,
      key: 'action',
      column_type: 'String',
      fixed: 'right',
      dataIndex: 'action',
      width: 110,
      padding: "0",
      render: (text: any, record: any) => {
        return (
          <div className="container-col">
            <div className="container-row c-ctr">
              <Button
                className="button-modify"
                title={TranslateString("@MODIFY")}
                style={{ marginRight: "5px" }}
                type="primary"
                shape="round"
                size={'small'}
                icon={<EditOutlined />}
                onClick={() => showUpdate(record)}
              />
              <Button
                className="red-background-ghost"
                title={TranslateString("@DELETE")}
                shape="round"
                size={'small'}
                icon={<DeleteOutlined />}
                onClick={() => showDelete(record)}
              />
            </div>
          </div>
        );
      },
    }

  ];

  return (columns);
}

