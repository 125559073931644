export class ShippingTrackingStatusModel {
    [x: string]: any;
    constructor(
        public dataStatoBolla: string | null,
        public datainserimento: string | null,
        public descrStato: string | null,
        public nrifbollamittente: string | null,
        public ragionesocialedestinatario: string | null,
        public ragionesocialemittente: string | null,
        public statoBolla: number | null,
        public barcode: string | null
    ) { }
    static getDefault = (): ShippingTrackingStatusModel => {
        return (new ShippingTrackingStatusModel(null,null,null,null,null,null,null,null));
    }
}


export class ShippingTrackingStatusHeadModel {
    constructor(
        public datainserimento: string | null,
        public nrifbollamittente: string | null,
        public ragionesocialedestinatario: string | null,
        public ragionesocialemittente: string | null,
    ) { }
    static getInstance = (item: ShippingTrackingStatusModel): ShippingTrackingStatusHeadModel => {
        return (new ShippingTrackingStatusHeadModel(item.datainserimento,item.nrifbollamittente,item.ragionesocialedestinatario,item.ragionesocialemittente));
    }
}

export class ShippingTrackingStatusLineModel {
    constructor(
        public dataStatoBolla: string | null,
        public descrStato: string | null,
        public statoBolla: number | null
    ) { }
    static getInstance = (item: ShippingTrackingStatusModel): ShippingTrackingStatusLineModel => {
        return (new ShippingTrackingStatusLineModel(item.dataStatoBolla, item.descrStato, item.statoBolla));
    }
}
