import { FilterStructureModel, Order } from "../Model/FilterModel";
import { OperatoreItemModel } from "../Model/RubricaModel";

var _ = require('lodash');

interface actionTypeCreateVisible {
    type: "SETVISIBLECREATE",
    visible: boolean,
    value?: OperatoreItemModel;
}
interface actionTypeUpdateVisible {
    type: "SETVISIBLEUPDATE",
    visible: boolean
}
interface actionTypeDeleteVisible {
    type: "SETVISIBLEDELETE",
    visible: boolean
}
interface actionTypeSetRubricaItem {
    type: "SETRUBRICAITEM",
    payload: any
}
interface actionTypeSetRubrica {
    type: "SETRUBRICA",
    value: any
}
interface actionTypeSetPagination {
    type: "SETPAGINATION",
    page: number | undefined,
    page_size: number | undefined
}
interface actionTypeSetTotalcount {
    type: "SETTOTALCOUNT",
    totalcount: number
}
interface actionTypeSetValidator {
    type: "SETVALIDATOR",
    validator: any
}
interface actionTypeSetColumns {
    type: "SETCOLUMNSSELECTED",
    columnsSelected: any[]
}
interface actionTypeSetFilterWhere {
    type: "SETFILTERWHERE",
    where_filter: FilterStructureModel,
}
interface actionTypeSetOrderbyFilter {
    type: "SETORDERBYFILTER",
    orderby_filter: Order,
}


export type actions = actionTypeCreateVisible | actionTypeSetRubricaItem | actionTypeSetTotalcount | actionTypeSetValidator
    | actionTypeSetRubrica | actionTypeDeleteVisible | actionTypeUpdateVisible | actionTypeSetPagination
    | actionTypeSetColumns | actionTypeSetFilterWhere | actionTypeSetOrderbyFilter;

interface IReducer {
    create_visible: boolean,
    update_visible: boolean,
    delete_visible: boolean,
    validator: any,
    rubricaItem: OperatoreItemModel,
    rubrica: any[],
    page: number | undefined,
    page_size: number | undefined,
    totalcount: number,
    columnsSelected: any[],
    where_filter: FilterStructureModel[],
    orderby_filter: Order,
    first_call: boolean,
}


export function OperatoreReducer(state: IReducer, action: actions): IReducer {

    switch (action.type) {
        case 'SETVISIBLECREATE':
            return action.value !== undefined ?
                { ...state, create_visible: action.visible, rubricaItem: action.value } :
                { ...state, create_visible: action.visible }
        case 'SETVISIBLEUPDATE':
            return { ...state, update_visible: action.visible }
        case 'SETVISIBLEDELETE':
            return { ...state, delete_visible: action.visible }
        case 'SETRUBRICAITEM':
            return { ...state, rubricaItem: action.payload }
        case 'SETRUBRICA':
            return { ...state, rubrica: action.value }
        case 'SETTOTALCOUNT':
            return { ...state, totalcount: action.totalcount }
        case 'SETPAGINATION':
            return { ...state, page: action.page, page_size: action.page_size }
        case 'SETVALIDATOR':
            return { ...state, validator: action.validator }
        case 'SETCOLUMNSSELECTED':
            return { ...state, columnsSelected: action.columnsSelected }
        case 'SETFILTERWHERE':
            const where_array: FilterStructureModel[] = _.filter(state.where_filter, (item: FilterStructureModel) => item.table_View_ColumnName !== action.where_filter.table_View_ColumnName);
            if (action.where_filter.value !== "") {
                const s = {
                    ...state, where_filter: [
                        ...where_array,
                        action.where_filter
                    ], first_call: false
                }
                return s;
            }

            return { ...state, where_filter: where_array, first_call: false }
        case 'SETORDERBYFILTER':
            return { ...state, orderby_filter: action.orderby_filter, first_call: false }
        default:
            return { ...state }

    }
}


