
export const FormateDate = (dateString:string | null ):string => {
   
    if(dateString !== null && dateString !== "" && typeof dateString !== undefined){
        var date = new Date(dateString);
        return date.toLocaleDateString('it-IT');
    }
    else {
        return "";
    }
    
}

export const FormateDateWith00 = (dateString: string | null): string => {

    if (dateString !== null && dateString !== "" && typeof dateString !== typeof undefined) {
        var date = new Date(dateString);
        return date.toLocaleDateString('it-IT', {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    }
    else {
        return "";
    }

}

export const FormateDateTimeWith00 = (dateString: string | null): string => {

    if (dateString !== null && dateString !== "" && typeof dateString !== typeof undefined) {
        var date = new Date(dateString);
        return date.toLocaleTimeString('it-IT',
            {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: '2-digit',
                minute: '2-digit'
            });
    }
    else {
        return "";
    }

}

export const FormateTimeWith00 = (dateString: string | null): string => {

    if (dateString !== null && dateString !== "" && typeof dateString !== typeof undefined) {
        var date = new Date(dateString);
        return date.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' });
    }
    else {
        return "";
    }

}