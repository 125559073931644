import { Validate } from "../AntDesignType";
import { MittentePortDTO, PresaPortDTO } from "../Model/PreseModel";
import moment from "moment";
import { checkStringOnlyNumbers } from "../Utils_Component/InputForm";
import { checkOrariApertura } from "../Utils_Component/FormatDate";
import { notifyError, notifyErrorJSX, notifyErrorPlain, notifyInfo, notifySucces } from "../CustomToast/customToast";

export interface PreseProps {
}

export class PreseValidator {
    constructor(
        public data_ritiro: Validate,
        public ora_ritiro: Validate,
        public mittente_ragionesociale: Validate,
        public mittente_indirizzo: Validate,
        public mittente_cap: Validate,
        public mittente_localita: Validate,
        public mittente_provincia: Validate,
        public mittente_telefono: Validate,
        public mittente_orariapertura: Validate,
        //  public riferimento_fatturazione: Validate,
        public operatore: Validate,
        public destinazioni_head: Validate,
        public destinazioni_rows: Validate,
        public destinazioni_rows_imballi: Validate,
        public volume: Validate,
        public pesoTotale: Validate,
        public noteClienteNoPortale: Validate
    ) { }

    static getDefault = () => {
        return (new PreseValidator(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    }
}
export const checkPreseValidField = (valid: PreseValidator): { valid: boolean, message: string[] } => {
    let resValidation = true;
    let resValidationError = [];

    if (valid.operatore !== undefined) { resValidation = false; resValidationError.push('Operatore non valido'); }
    // if (valid.riferimento_fatturazione !== undefined) { resValidation = false; resValidationError.push('Riferimento fatturazione non valido'); }
    if (valid.mittente_ragionesociale !== undefined) { resValidation = false; resValidationError.push('Ragione sociale Mittente non valido'); }

    if (valid.mittente_indirizzo !== undefined) { resValidation = false; resValidationError.push('Indirizzo Mittente non valido'); }
    if (valid.mittente_provincia !== undefined) { resValidation = false; resValidationError.push('Provincia Mittente non valido'); }
    if (valid.mittente_cap !== undefined) { resValidation = false; resValidationError.push('CAP Mittente non valido'); }
    if (valid.mittente_localita !== undefined) { resValidation = false; resValidationError.push('Località Mittente non valido'); }
    if (valid.mittente_telefono !== undefined) { resValidation = false; resValidationError.push('Telefono Mittente non valido'); }

    if (valid.mittente_orariapertura !== undefined) { resValidation = false; resValidationError.push('Orari di apertura del mittente non validi'); }

    if (valid.data_ritiro !== undefined) { resValidation = false; resValidationError.push('Data ritiro non valida'); }
    if (valid.ora_ritiro !== undefined) { resValidation = false; resValidationError.push('Ora ritiro non valida'); }

    if (valid.destinazioni_head !== undefined) { resValidation = false; resValidationError.push('Destinazione - Dati generali non validi'); }
    if (valid.destinazioni_rows !== undefined) { resValidation = false; resValidationError.push('Griglia merce: è necessaria almeno una riga'); }
    if (valid.destinazioni_rows_imballi !== undefined) { resValidation = false; resValidationError.push('Griglia merce: misure non inserite, tutte le misure sono obbligatorie'); }

    if (valid.volume !== undefined) { resValidation = false; resValidationError.push('Il valore del volume é minore di quello calcolato!'); }

    if (valid.pesoTotale !== undefined) { resValidation = false; resValidationError.push('Controllare il peso totale!'); }

    if (valid.noteClienteNoPortale !== undefined) { resValidation = false; resValidationError.push('Le note del cliente devono essere compilate.'); }

    return { valid: resValidation, message: resValidationError }

}

export const uniformaPresa = (presa: PresaPortDTO, isPreventivo: boolean = false): PresaPortDTO => {
    let presaUniformata = { ...presa }; //lo clono, non voglio modificare l'originale

    presaUniformata.oraRitiro = presaUniformata.pronto ? undefined : presaUniformata.oraRitiro;

    if (!isPreventivo) {
        presaUniformata.destinazioni.forEach(dest => {

            dest.imballi = dest.imballi.filter(imballo =>
                imballo.altezza !== undefined ||
                imballo.descrizione !== '' ||
                imballo.larghezza !== undefined ||
                imballo.lunghezza !== undefined ||
                (imballo.nrColli !== undefined && imballo.nrColli > 0) ||
                (imballo.nrPallet !== undefined && imballo.nrPallet > 0) ||
                imballo.peso !== undefined
            );

            dest.imballi.forEach(imballo => {

                const numberGeneric = Number((imballo as any)['numberGeneric']);

                switch ((imballo as any)['rowType']) {
                    case 'pallet':
                        imballo.nrPallet = numberGeneric === Number.NaN ? -1 : numberGeneric;
                        imballo.nrColli = 0;
                        break;
                    case 'colli':
                        imballo.nrPallet = 0;
                        imballo.nrColli = numberGeneric === Number.NaN ? -1 : numberGeneric;
                        break;
                    case undefined:
                    default:
                        imballo.nrPallet = -1;
                        imballo.nrColli = -1;
                        break;
                }
            });
        });
    }

    return presaUniformata;
}

export const setPreseValidField = (value: PresaPortDTO, isPreventivo: boolean = false, isPortalUser: boolean = false): PreseValidator => {
    let valid = PreseValidator.getDefault();

    valid.data_ritiro = checkNotValidString(value.dataRitiro);
    valid.ora_ritiro = value.pronto ? undefined : checkNotValidOraritiro(value.oraRitiro as string);

    if (!(isPreventivo && !isPortalUser)) { //per i preventivi && non utenti portale non faccio questo check
        valid.mittente_ragionesociale = checkNotValidString(value.mittente?.ragioneSociale);
    }

    valid.mittente_indirizzo = checkNotValidString(value.mittente?.indirizzo);
    valid.mittente_cap = checkNotValidStringLenght(value.mittente?.cap, 5);
    valid.mittente_localita = checkNotValidString(value.mittente?.localita);
    valid.mittente_provincia = checkNotValidStringLenght(value.mittente?.provincia, 2);
    valid.mittente_orariapertura = checkNotValidOrariApertura(value.mittente);
    //valid.riferimento_fatturazione = checkNotValidString(value.riferimentoFatturazione);
    valid.operatore = checkNotValidString(value.operatorePortale);

    const validPhoneString = checkNotValidString(value.mittente?.telefono);
    const validPhoneNumber = checkStringOnlyNumbers(value.mittente?.telefono) ? undefined : 'error';
    valid.mittente_telefono = validPhoneString === undefined ? validPhoneNumber : validPhoneString;

    if (isPreventivo && !isPortalUser) {
        valid.noteClienteNoPortale = checkNotValidString(value.noteClienteNoPortale)
    }

    valid.destinazioni_head = undefined;
    valid.destinazioni_rows = undefined;
    valid.destinazioni_rows_imballi = undefined;
    if (value.destinazioni === undefined || value.destinazioni.length != 1) { //al momento viene gestita una sola destinazione
        valid.destinazioni_head = 'error';
    }
    else {

        value.destinazioni.forEach(dest => { //già predisposto per il multipla destinazione
            //verifico i dati nella destinazione
            const validDestPhoneString = checkNotValidString(dest?.telefono);
            const validDestPhoneNumber = checkStringOnlyNumbers(dest?.telefono) ? undefined : 'error';
            const validDestPhone = validDestPhoneString === undefined ? validDestPhoneNumber : validDestPhoneString;

            if (
                /* checkNotValidFn(dest?.personaRiferimento) || */
                validDestPhone ||
                ((isPreventivo && !isPortalUser) ? false : checkNotValidFn(dest?.ragioneSociale)) ||
                checkNotValidFn(dest?.indirizzo) ||
                checkNotValidFn(dest?.cap) || dest?.cap.length != 5 ||
                checkNotValidFn(dest?.localita) ||
                checkNotValidFn(dest?.provincia) || dest?.provincia.length != 2
            ) {
                valid.destinazioni_head = 'error';
            }

            if (!isPreventivo) {
                if (dest?.imballi === undefined || dest?.imballi.length === 0) {
                    valid.destinazioni_rows = 'error';
                }

                //verifico i dati nella lista degli Imballi (cose da ritirare) per la singola destinazione
                let imballiTotInvalid = false;
                dest?.imballi.forEach(element => {
                    const resInvalid =
                        ckeckNotValidNumberPositiveFn(element.altezza) ||
                        ckeckNotValidNumberPositiveFn(element.larghezza) ||
                        ckeckNotValidNumberPositiveFn(element.lunghezza) ||
                        ckeckNotValidNumberPositiveFn(element.nrColli) ||
                        ckeckNotValidNumberPositiveFn(element.nrPallet);

                    imballiTotInvalid = imballiTotInvalid || resInvalid;
                });

                //il peso dell'imballo non deve essere mai valorizzato per le prese inserite da portale: mail sarzi 22/06/2023
                dest?.imballi.forEach(element => { element.peso = 0 });

                if (imballiTotInvalid) {
                    valid.destinazioni_rows_imballi = 'error';
                }

                let volume = 0;
                for (var i = 0; i < dest.imballi.length; i++) {
                    const row = dest.imballi[i];
                    const rowL = row.lunghezza === undefined ? 0 : (+row.lunghezza);
                    const rowW = row.larghezza === undefined ? 0 : (+row.larghezza);
                    const rowH = row.altezza === undefined ? 0 : (+row.altezza);
                    const rowQta = row.nrColli === undefined ? 0 : (+row.nrColli);
                    volume += (rowQta * rowL * rowW * rowH);
                }
                volume /= 1000000;
                if (volume > dest.volumeTotale) {
                    valid.volume = 'error';
                }

                if (ckeckNotValidNumberPositiveFn(dest?.pesoTotale)) {
                    valid.pesoTotale = 'error';
                }
            }
        });
    }

    return (valid);
}

const checkNotValidOraritiro = (field: string) => {

    const valid = !checkNotValidFn(field);
    if (!valid) {
        return 'error';
    }

    const orario = moment(field).format("HHmm");
    const validInterval = orario >= '0730' && orario <= '1630';

    return validInterval && checkValidQuarter(orario) ? undefined : 'error';
}
const ckeckNotValidNumberPositiveFn = (field: number | undefined): boolean => {
    return (typeof field === 'undefined' || field === Number.NaN || field < 0);
}

const ckeckNotValidNumberFn = (field: number | undefined): boolean => {
    return (typeof field === 'undefined' || field === Number.NaN);
}
const checkNotValidFn = (field: string): boolean => {
    return (typeof field === 'undefined' || field === "" || field === null);
}
export const checkNotValidString = (field: string): Validate => {
    return checkNotValidFn(field) ? 'error' : undefined;
}
const checkNotValidNumber = (field: number | undefined): Validate => {
    return ckeckNotValidNumberFn(field) ? 'error' : undefined;
}
const checkNotValidStringLenght = (field: string, length: number): Validate => {
    return checkNotValidFn(field) || field.length != length ? 'error' : undefined;
}
const checkValidQuarter = (orario: string): boolean => {
    const quarterTime = orario.slice(-2);
    return ['00', '15', '30', '45'].includes(quarterTime);
}

const checkNotValidOrariApertura = (mittente: MittentePortDTO): Validate => {
    const orarioApertura1_notValid = checkNotValidFn(mittente.orarioApertura1);
    const orarioChiusura1_notValid = checkNotValidFn(mittente.orarioChiusura1);
    const orarioApertura2_notValid = mittente.orarioContinuato ? false : (mittente.orarioApertura2 === null || checkNotValidFn(mittente.orarioApertura2!));
    const orarioChiusura2_notValid = mittente.orarioContinuato ? false : (mittente.orarioChiusura2 === null || checkNotValidFn(mittente.orarioChiusura2!));
    if (orarioApertura1_notValid || orarioChiusura1_notValid || orarioApertura2_notValid || orarioChiusura2_notValid) {
        return 'error';
    }

    const checkOrari = checkOrariApertura(mittente.orarioApertura1, mittente.orarioChiusura1, mittente.orarioApertura2, mittente.orarioChiusura2, mittente.orarioContinuato);
    if (!checkOrari) {
        return 'error';
    }

    return undefined
}

export const preseValidationErrorMessage = (messages: string[]) => {

    const msg = <div style={{ display: 'flex', flexDirection: 'column' }}>
        {
            messages.map((m: string) => <span style={{ marginBottom: 3 }}>{m}</span>)
        }
    </div>

    notifyErrorJSX(msg);
    //notifyError('@PRESE_VALIDATE_ERROR');
}




