import React from "react";
import RestUrl from '../../RestUrl.json';
import { Button, Modal, Table } from "antd";
import { TranslateString } from "../../i18n/translate";
import {
    ReloadOutlined,
    PlusOutlined,
    LeftOutlined,
    DeleteOutlined,
    LockOutlined,
    EditOutlined
} from '@ant-design/icons';
import { notifyError, notifySucces } from "../CustomToast/customToast";
import { FilterModel, FilterStructureModel, Order, OrderType } from "../Model/FilterModel";
import { UsersReducer } from "./UsersType";
import { ChangePasswordModel, UserPortDto, UserSearchModel } from "../Model/UsersModel";
import { UserForm } from "./UsersForm";
import { ChangePswValidator, checkChangePswValidField, checkUsersValidField, setChangePswValidField, setUsersValidField, UsersValidator } from "./UsersInterface";
import { RightFixed } from "../AntDesignType";
import { RestCall } from "../Rest/Rest_Call/RestCall";
import { buildColumn, buildColumnReturn } from "../../utils/BuildColumn";
import { ChangePswForm } from "./ChangePasswordForm";
import { DeleteactionColumn, UpdateactionColumn } from "../Utils_Component/ActionColumnButton/ActionButton";
import { Checked } from "../Utils_Component/CheckedBoolean";
import { DateFormatJson } from "../Utils_Component/FormatDate";
import { SITableVirtualizedComponent } from "@si/si-react-components/dist/esm/components/SITable/SITableVirtualized/SITableVirtualizedComponent";
import { UserColumns } from "./UserColumns";
import { SITableFilterTypeValues } from "@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterController";
import { SITableOrder } from "@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterModel";
import { TrackingCustomerForm } from "./TrackingCustomerForm";


export const UsersPage: React.FC = props => {
    const [loading, setLoading] = React.useState(false);
    const [wait, setWait] = React.useState(false);

    const [state, dispatch] = React.useReducer(UsersReducer, {
        create_visible: false,
        update_visible: false,
        delete_visible: false,
        change_psw_visible: false,
        change_psw_validator: ChangePswValidator.getDefault(),
        change_psw_record: ChangePasswordModel.getDefault(),
        validator: UsersValidator.getDefault(),
        record: UserPortDto.getDefault(),
        data_source: [],
        page: 1,
        pageSize: 20,
        totalcount: 100,
        set_date: "",
        where_filter: [],
        orderby_filter: new Order("", "ASC"),
        first_call: true,
        columns: [],
        tracking_visible: false,
    });


    React.useEffect(
        () => {
            loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
        }, []
    );
    React.useEffect(
        () => {
            if (state.first_call !== true) {
                loadtable(state.where_filter, state.orderby_filter, undefined, state.pageSize);
                dispatch({ type: 'SETPAGINATION', page: undefined, pageSize: state.pageSize });
            }
        }, [state.where_filter]
    );
    React.useEffect(
        () => {
            if (state.first_call !== true) {
                loadtable(state.where_filter, state.orderby_filter, undefined, state.pageSize);
                dispatch({ type: 'SETPAGINATION', page: undefined, pageSize: state.pageSize });
            }
        }, [state.orderby_filter]
    );


    //START filter handler
    const handleFilter = (value: string, sql_name: string, operator: string, filter_type: SITableFilterTypeValues) => { //gestisce i filtri delle colonne di tipo stringa 
        dispatch({ type: 'SETFILTERWHERE', where_filter: new FilterStructureModel(sql_name, operator, filter_type, value) });
    }
    const handleOrderBy = (order_by: SITableOrder) => {//permette di effettuare l'ordinamento per colonna ASC/DESC
        dispatch({ type: 'SETORDERBYFILTER', orderby_filter: new Order(order_by.table_View_ColumnName, order_by.mode) });
    }
    //END filter handler

    const loadtable = (where: FilterStructureModel[], order: Order, page: number | undefined, pageSize: number | undefined) => {

        setLoading(true);

        //let filter = UserSearchModel.getDefault();
        const filter = new FilterModel(order, where, [])

        RestCall.postWithReturnEasyAccess(filter, RestUrl.CLIENTE_SEARCH_FILTER, page, pageSize, 'url',
            (value, { x_pagination }) => {
                dispatch({ type: 'SETDATASOURCETOTALCOUNT', value, totalcount: x_pagination });
                setLoading(false);
            }
            ,
            message => {
                notifyError("@UNHANDLE_ERROR");
                setLoading(false);
            }
        );

        /*         RestCall.postWithReturn(filter, RestUrl.CLIENTE_SEARCH_FILTER, page, pageSize, 'url').then(
                    result => {
                        if (typeof result !== 'undefined' && typeof result !== 'string') {
                            
                            if (typeof result.data !== 'undefined' && result.data !== 'string') {
                                const ds = result.data.data.map((el: any, index: any) => { return { ...el, key: index } });
                                dispatch({ type: 'SETDATASOURCETOTALCOUNT', value: ds, totalcount: result.headers.x_pagination });
                                setLoading(false);
                            }
                        } else if (typeof result === 'string') {
                            if (result === 'VALID_TOKEN') {
                                loadtable(where, order, page, pageSize);
                            } else {
                                notifyError(result);
                                setLoading(false);
                            }
                        } else {
                            notifyError("@UNHANDLE_ERROR");
                            setLoading(false);
                        }
                    } 
                );*/
    }

    /*     const handlePagination = (page: number, pageSize?: number | undefined) => {//gestisce la paginazione
            dispatch({ type: 'SETPAGINATION', page: page, pageSize: pageSize });
            loadtable(state.where_filter, state.orderby_filter, page, pageSize!); //ricarica la tabella con i valori della pagina successiva
        } */
    const handlePagination = (page: number | undefined, pageSize?: number | undefined) => {//gestisce la paginazione
        dispatch({ type: 'SETPAGINATION', page: page, pageSize: pageSize });
        loadtable(state.where_filter, state.orderby_filter, page, pageSize!); //ricarica la tabella con i valori della pagina successiva
    }

    //Modal create
    const showCreateModal = () => {
        dispatch({ type: 'SETCREATEVISIBLEANDDEFAULT', visible: true, record: UserPortDto.getDefault(), validator: UsersValidator.getDefault() });
    }
    const handleModalOk = () => {
        dispatch({ type: 'SETVALIDATOR', validator: setUsersValidField(state.record) });
        if (checkUsersValidField(setUsersValidField(state.record))) {
            RestCall.post(state.record, RestUrl.USER_POST_INSERT, 'ok', 'url').then(
                result => {
                    if (result === 'ok') {
                        notifySucces("@V_USER_POST");
                        dispatch({ type: 'SETVISIBLECREATE', visible: false });
                        loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
                    } else if (result === 'VALID_TOKEN') {
                        handleModalOk();
                    } else if (typeof result === 'string') {
                        notifyError(result);
                    } else {
                        notifyError(result.status);
                    }
                }
            );
        }
    }
    const handleModalCancel = () => {
        dispatch({ type: 'SETVISIBLECREATE', visible: false });
    }
    const handleChanges = (value: UserPortDto) => {
        dispatch({ type: 'SETRECORD', value: value });
    }
    //END Modal create

    //Modal update
    const showUpdateModal = (value: UserPortDto) => {
        dispatch({ type: 'SETUPDATEVISIBLEANDDEFAULT', visible: true, record: value, validator: UsersValidator.getDefault() });
    }
    const handleUpdateModalOk = () => {
        dispatch({ type: 'SETVALIDATOR', validator: setUsersValidField(state.record) });
        if (checkUsersValidField(setUsersValidField(state.record))) {
            setWait(true);
            RestCall.Put(state.record, RestUrl.USER_PUT_MODIFY, 'ok', 'url').then(
                result => {
                    if (result === 'ok') {
                        notifySucces("@V_USER_PUT");
                        if (state.update_visible)
                            dispatch({ type: 'SETVISIBLEUPDATE', visible: false });
                        if (state.tracking_visible)
                            dispatch({ type: 'SETTRACKINGVISIBLE', visible: false });

                        loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
                    } else if (result === 'VALID_TOKEN') {
                        handleModalOk();
                    } else if (typeof result === 'string') {
                        notifyError(result);
                    }

                    if (state.update_visible)
                        dispatch({ type: 'SETVISIBLEUPDATE', visible: false });
                    if (state.tracking_visible)
                        dispatch({ type: 'SETTRACKINGVISIBLE', visible: false });
                    setWait(false);
                }
            );
        } else {
            setWait(false);
        }

    }
    const handleUpdateModalCancel = () => {
        dispatch({ type: 'SETVISIBLEUPDATE', visible: false });
    }
    //END Modal update

    //Modal delete
    const showDeleteModal = (value: any) => {
        dispatch({ type: 'SETVISIBLEDELETE', visible: true });
        dispatch({ type: 'SETRECORD', value: value });
    }
    const handleDeleteModalOk = () => {
        RestCall.Put(state.record, RestUrl.USER_DELETE_BY_ID, 'ok', 'url').then(
            result => {
                if (result === 'ok') {
                    notifySucces("@V_USER_DELETE");
                    dispatch({ type: 'SETVISIBLEDELETE', visible: false });
                    loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
                } else if (result === 'VALID_TOKEN') {
                    handleModalOk();
                } else if (typeof result === 'string') {
                    notifyError(result);
                }
            }
        );
    }
    const handleDeleteModalCancel = () => {
        dispatch({ type: 'SETVISIBLEDELETE', visible: false });
    }
    //END Modal create


    //HANDLE CHANGE PSW MODAL
    const showChangePasswod = (record: any) => {
        dispatch({ type: 'SETCHANGEPSWVALIDATOR', validator: ChangePswValidator.getDefault() });
        dispatch({ type: 'SETCHANGEPSWRECORDANDVISIBLE', visible: true, value: record.idUser });
    }

    const handleChangePswOk = () => {
        dispatch({ type: 'SETCHANGEPSWVALIDATOR', validator: setChangePswValidField(state.change_psw_record) });
        if (checkChangePswValidField(setChangePswValidField(state.change_psw_record))) {
            RestCall.post_with_result_no_pagination(state.change_psw_record, RestUrl.USER_CHANGE_PSW_TO, 'login_url').then(
                result => {
                    if (typeof result !== 'undefined' && typeof result !== 'string') {
                        if (typeof result.data === 'string') {
                            if (result.data === '') {
                                dispatch({ type: 'SETCHANGEVISIBLE', visible: false });
                                notifySucces("@V_CHANGE_PSW");
                            } else {
                                notifyError(result.data);
                            }
                        }
                    }
                    if (typeof result === 'string') {
                        if (result === 'VALID_TOKEN') {
                            handleChangePswOk();
                        } else {
                            notifyError(result);
                        }
                    }
                }
            )
        }
    }
    const handleChagePswCancel = () => {
        dispatch({ type: 'SETCHANGEVISIBLE', visible: false });
    }
    const handleChangePswChange = (value: ChangePasswordModel) => {
        dispatch({ type: 'SETCHANGEPSWVALUE', value: value });
    }
    //END CHANGE PSW MODAL


    //HANDLETRACKING MODAL
    const showTrackingModal_Record = (record: UserPortDto) => {
        dispatch({ type: 'SETTRACKINGVISIBLE_RECORD', visible: true, record });
    }
    const showTrackingModal = (visible: boolean) => {
        dispatch({ type: 'SETTRACKINGVISIBLE', visible });
    }
    const handleTrackingOK = () => {
        handleUpdateModalOk();
    }


    //END HANDLETRACKING MODAL

    return (
        <>
            <div className="container-col height-100">
                <div><h1 className="title-page">{TranslateString("@USER_TITLE")}</h1></div>
                <div className="container-col scrollable-calc-40">
                    <div className="desktopBody" style={{ paddingBottom: '10px' }}>
                        <Button className="table-new-button" onClick={showCreateModal}><PlusOutlined />{TranslateString("@NEW_USERS")}</Button>
                        <Button className="table-reload-button" onClick={() => loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize)}><ReloadOutlined />{TranslateString("@RELOAD_TABLE")}</Button>
                    </div>
                    <div className="mobile-body container-rowr" style={{ paddingBottom: '10px' }}>
                        <Button className="table-new-button" onClick={showCreateModal}><PlusOutlined /></Button>
                        <Button className="table-reload-button" onClick={() => loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize)}><ReloadOutlined /></Button>
                    </div>
                    <div className="height-100-70">
                        <div className="si-table-container height-100 width-100">
                            <SITableVirtualizedComponent
                                id_univoco={"user_filter_transfilm"}
                                page={state.page}
                                page_size={state.pageSize}
                                totalcount={state.totalcount}
                                data_source={state.data_source}
                                columns={UserColumns(handleOrderBy, handleFilter, showUpdateModal, showChangePasswod, showDeleteModal, Checked, showTrackingModal_Record)}
                                filters={undefined}
                                bordered={true}
                                onPagination={handlePagination}
                                select_rows={undefined}
                                onFilterChange={() => { }}
                                onShiftColumn={() => { }}
                                loading={loading}
                                colorRowClick={'#18D5FF'}
                                onRowClass={(record: any, index) => {
                                    if (index % 2 == 0) {
                                        return " light-gray "
                                    } else {
                                        return " "
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {state.create_visible &&
                <Modal
                    wrapClassName="modal-L"
                    title={TranslateString("@NEW_USERS")}
                    visible={state.create_visible}
                    onOk={handleModalOk}
                    onCancel={handleModalCancel}
                    closeIcon={<LeftOutlined className="mobile-body" />}
                    keyboard={false}
                    maskClosable={false}
                    width="400px"
                >
                    <UserForm user={UserPortDto.getDefault()} validator={state.validator} onChange={handleChanges} />
                </Modal>
            }
            {state.update_visible &&
                <Modal
                    wrapClassName="modal-L"
                    title={TranslateString("@UPDATE_USER")}
                    visible={state.update_visible}
                    onOk={handleUpdateModalOk}
                    onCancel={handleUpdateModalCancel}
                    footer={!wait ? undefined : wait}
                    closeIcon={<LeftOutlined className="mobile-body" />}
                    keyboard={false}
                    maskClosable={false}
                    width="400px"
                >
                    <UserForm user={state.record} validator={state.validator} onChange={handleChanges} />
                </Modal>
            }
            {state.delete_visible &&
                <Modal
                    wrapClassName="modal-s"
                    title={TranslateString("@DELETE_USER")}
                    visible={state.delete_visible}
                    onOk={handleDeleteModalOk}
                    onCancel={handleDeleteModalCancel}
                    closeIcon={<LeftOutlined className="mobile-body" />}
                    keyboard={false}
                    maskClosable={false}
                    width="400px"
                >
                    {TranslateString("@ARE_YOU_SURE")}
                </Modal>
            }
            {state.change_psw_visible &&
                <Modal
                    wrapClassName="modal-xm"
                    title={TranslateString("@CHANGE_PASSWORD")}
                    visible={state.change_psw_visible}
                    onOk={handleChangePswOk}
                    onCancel={handleChagePswCancel}
                    closeIcon={<LeftOutlined className="mobile-body" />}
                    keyboard={false}
                    maskClosable={false}
                    width="400px"
                >
                    <ChangePswForm show_old_psw={false} user={state.change_psw_record} validator={state.change_psw_validator} onChange={handleChangePswChange} />
                </Modal>
            }
            {state.tracking_visible &&
                <Modal
                    wrapClassName="modal-L"
                    title={TranslateString("@TITLE_TRACKING")}
                    visible={state.tracking_visible}
                    onOk={handleTrackingOK}
                    onCancel={() => showTrackingModal(false)}
                    closeIcon={<LeftOutlined className="mobile-body" />}
                    keyboard={false}
                    footer={!wait ? undefined : wait}
                    maskClosable={false}
                    width="400px"
                >
                    <TrackingCustomerForm user={state.record} validator={state.validator} onChange={handleChanges} />
                </Modal>
            }
        </>
    );
}