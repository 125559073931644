import { url } from "inspector";
import store from "../Component/Redux/Store";





export const getPublicUrl = async (): Promise<string | void> => {

    return await fetch('PublicConfig.json')
    .then((response) => 
        response.json())
        .then(
            (settings) => {    
            return settings.SERVER_URL+ settings.VERSION+"/";   
        })
    .catch(
        error => console.warn(error));

}


export const getTranslateData = async (): Promise<string | void> => {

    return await fetch('PublicConfig.json')
    .then((response) => 
        response.json())
        .then(
            (settings) => {    
                //Translations.translations = settings;   
                store.dispatch({type:'SETSERVERURL', serverUrl:settings.SERVER_URL});

                mapUrl("url",settings.SERVER_URL);
                mapUrl("login_url",settings.LOGIN_SERVER_URL);

                /*if(localStorage.getItem("url") === null){
                    localStorage.setItem("url",settings.SERVER_URL);
                }else if (localStorage.getItem("url") !== settings.SERVER_URL){
                    localStorage.setItem("url",settings.SERVER_URL);
                }
                if(localStorage.getItem("login_url") === null){
                    localStorage.setItem("login_url",settings.LOGIN_SERVER_URL);
                }else if (localStorage.getItem("login_url") !== settings.LOGIN_SERVER_URL){
                    localStorage.setItem("login_url",settings.LOGIN_SERVER_URL);
                }*/
                
        })
    .catch(
        error => console.warn(error));

}

const  mapUrl = (url: string, settingsUrl: any ) => {
    if(localStorage.getItem(url) === null){
        localStorage.setItem(url,settingsUrl);
    }else if (localStorage.getItem(url) !== settingsUrl){
        localStorage.setItem(url,settingsUrl);
    }
}
