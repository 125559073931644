export class FilterStructureModel {
    constructor(
        public table_View_ColumnName:string,
        public operator:string,
        public type:string,
        public value:string
    ){}
}

export type OrderType = "ASC" | "DESC" | "undefined";

export class Order{
    constructor(
        public table_View_ColumnName : string,
        public mode: OrderType
    ){}
}

export class FilterModel {
    constructor(
        public orderby: Order | null,
        public columnFilterList: FilterStructureModel [],
        public filtroCodiceCliente: string[],
    ){}
}

export class FilterModelTracking {
    constructor(
        public orderby: Order[] | null,
        public columnFilterList: FilterStructureModel []
    ){}
}
