import { Form, Select } from "antd";
import React from "react";
import { TranslateString } from "../../i18n/translate";
import { notifyError } from "../CustomToast/customToast";
import { OperatoreItemModel } from "../Model/RubricaModel";
import { checkStringOnlyNumbers, FormTextInput_old } from "../Utils_Component/InputForm";
import { RestCall } from '../Rest/Rest_Call/RestCall';
import RestUrl from '../../RestUrl.json';

const { Option } = Select;
var _ = require('lodash');

interface OperatoreFormProps {
    record: OperatoreItemModel,
    onChange: (record: OperatoreItemModel) => void
}


export const OperatoreForm: React.FC<OperatoreFormProps> = props => {



    React.useEffect(
        () => {
            //loadView();
            //loadChartType();
        }, []
    );

    
    const handleTextChanges = (event: React.ChangeEvent<HTMLInputElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]
        const newRecord = {
            ...props.record,
            [name]: value
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const handleViewSelect = (value: string) => {
        const newRecord = {
            ...props.record,
            table_View_Name: value
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const handleChartTypeSelect = (value: number) => {
        const newRecord = {
            ...props.record,
            chartTypeId: +value
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>| React.ChangeEvent<HTMLTextAreaElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]
        const newRecord = {
            ...props.record,
            [name]: value
        };

        //ToDO richiamare una props che informi la classe padre dell'avvenuto cambiamento
        props.onChange(newRecord);
    }

    const checkPhoneValidate = React.useMemo(() => {        
        return _.isEmpty(props.record.telefono) ||  !checkStringOnlyNumbers(props.record.telefono) ? 'error' : undefined;
    }, [props.record.telefono]);



    return (
        <Form>

            <FormTextInput_old
                onChange={handleChanges}
                label_name="NOME_COGNOME"
                mandatory={true}
                input_name={"nome"}
                input_value={props.record.nome!}
                validate={undefined}
            />

            <FormTextInput_old
                onChange={handleChanges}
                label_name="MAIL"
                mandatory={true}
                input_name={"mail"}
                input_value={props.record.mail!}
                validate={undefined}
            />
            <FormTextInput_old
                onChange={handleChanges}
                label_name="MAILTARGA"
                mandatory={false}
                input_name={"mailTarga"}
                input_value={props.record.mailTarga!}
                validate={undefined}
            />
            <FormTextInput_old
                onChange={handleChanges}
                label_name="TELEFONO"
                mandatory={true}
                input_name={"telefono"}
                input_value={props.record.telefono!}
                validate={checkPhoneValidate}
                helpMessage={TranslateString('@TELEFONO_VALIDATE_ERROR')}
            />
        </Form>
    );
}