import { Button, Modal, Table } from 'antd';
import React from 'react';
import {
    PlusOutlined,
    ReloadOutlined,
    LeftOutlined,
} from '@ant-design/icons';
import { notifyError, notifySucces } from '../CustomToast/customToast';
import { OperatoreItemModel } from '../Model/RubricaModel';
import { TranslateString } from '../../i18n/translate';
import { OperatoreForm } from './OperatoreForm';
import { OperatoreProps } from './OperatoreInterface';
import { OperatoreReducer } from './OperatoreType';
import { RestCall } from '../Rest/Rest_Call/RestCall';
import RestUrl from '../../RestUrl.json';
import { checkStringOnlyNumbers } from '../Utils_Component/InputForm';
import { SITableFilterTypeValues } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterController';
import { SITableOrder } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterModel';
import { SITableVirtualizedComponent } from '@si/si-react-components/dist/esm/components/SITable/SITableVirtualized/SITableVirtualizedComponent';
import { FilterModel, FilterStructureModel, Order } from '../Model/FilterModel';
import { OperatoreColumns } from './OperatoreColumns';


export const OperatoreList: React.FC<OperatoreProps> = props => {
    const [loading, setLoading] = React.useState(false);

    const [state, dispatch] = React.useReducer(OperatoreReducer, {
        create_visible: false,
        update_visible: false,
        delete_visible: false,
        validator: undefined,
        rubricaItem: OperatoreItemModel.getDefault(),
        rubrica: [],
        page: 1,
        page_size: 10,
        totalcount: 100,
        columnsSelected: [],
        where_filter: [],
        orderby_filter: new Order("", "ASC"),
        first_call: true,
    });

    React.useEffect(
        () => {
            load();
        }, []
    );
    React.useEffect(
        () => {
            if (state.first_call !== true) {
                load();
                dispatch({ type: 'SETPAGINATION', page: undefined, page_size: state.page_size });
            }
        }, [state.where_filter]
    );
    React.useEffect(
        () => {
            if (state.first_call !== true) {
                load();
                dispatch({ type: 'SETPAGINATION', page: undefined, page_size: state.page_size });
            }
        }, [state.orderby_filter]
    );

    const load = () => {
        loadtable(state.where_filter, state.orderby_filter, undefined, state.page_size);
    }


    const loadtable = (where_filter: FilterStructureModel[], orderby_filter: Order, page: number | undefined, page_size: number | undefined) => {

        const filter = new FilterModel(orderby_filter, where_filter, [])
        setLoading(true);
        RestCall.postWithResultNoPaginationEasyAccess(filter, RestUrl.RUBRICA_OPERATORE_SEARCH, 'url',
            (result: any, header) => {
                dispatch({ type: 'SETRUBRICA', value: result });
                setLoading(false);
            },
            (message) => {
                setLoading(false);
                notifyError(message);
            }
        );


        /*         RestCall.getAll(RestUrl.RUBRICA_OPERATORE_GET_ALL, 'url').then(
                    result => {
                        if (typeof result !== 'string' && typeof result !== 'undefined') {
                            if (typeof result.data !== 'string' && typeof result.data !== 'undefined') {
                                dispatch({ type: 'SETRUBRICA', value: result.data });
                            }
                        } else if (typeof result === 'string') {
                            if (result === "VALID_TOKEN") {
                                loadtable(where_filter, orderby_filter, page, page_size);
                            } else {
                                notifyError(result);
                            }
                        } else {
                            notifyError("@UNHANDLE_ERROR");
                        }
                    }
                ); */
    }

    const handleChanges = (value: OperatoreItemModel) => { //handle the changes done inside the modal
        dispatch({ type: 'SETRUBRICAITEM', payload: value });
    }

    //Modal create a new record
    const showCreateModal = () => {
        dispatch({ type: 'SETVALIDATOR', validator: undefined });
        dispatch({ type: 'SETVISIBLECREATE', visible: true, value: OperatoreItemModel.getDefault() });
    }

    const ControllaCampi = () => {
        if ((typeof state.rubricaItem.nome === 'undefined') || (state.rubricaItem.nome === '')) {
            notifyError("@ERR_INSER_NOME");
            return false;
        }
        if ((typeof state.rubricaItem.mail === 'undefined') || (state.rubricaItem.mail === '')) {
            notifyError("@ERR_INSER_MAIL");
            return false;
        }
        if ((typeof state.rubricaItem.telefono === 'undefined') || (state.rubricaItem.telefono === '') || !checkStringOnlyNumbers(state.rubricaItem.telefono)) {
            notifyError("@ERR_INSER_TELEFONO");
            return false;
        }
        return true;
    }

    const handleCreate = () => {
        if (!ControllaCampi())
            return;

        RestCall.post(state.rubricaItem, RestUrl.RUBRICA_OPERATORE_POST_INSERT, "ok", 'url').then(
            result => {
                if (result === "ok") {
                    load();
                    dispatch({ type: "SETVISIBLECREATE", visible: false });
                    notifySucces("@V_ITEM_INSERT");
                } else {
                    if (result === "VALID_TOKEN") {
                        handleUpdate();
                    } else {
                        notifyError(result.body?.data?.messages?.join('; '));
                    }
                }
            }
        );

    }
    const handleCreateCancel = () => {
        dispatch({ type: 'SETVISIBLECREATE', visible: false });
    }
    //END Modal create a new record    

    //Modal delete a record (it's a logical delete, the data will not be deleted in the database)
    const showDelete = (value: any) => {
        dispatch({ type: 'SETVISIBLEDELETE', visible: true });
        dispatch({ type: 'SETRUBRICAITEM', payload: value });
    }

    const handleDelete = () => {
        RestCall.DeleteByID(RestUrl.RUBRICA_OPERATORE_CANCEL, state.rubricaItem.id, 'ok', 'url').then(
            result => {
                if (result === 'ok') {
                    notifySucces("@V_DELETE_ITEM");
                    dispatch({ type: 'SETVISIBLEDELETE', visible: false });
                    load();
                } else if (result === 'VALID_TOKEN') {
                    handleDelete();
                } else if (typeof result === 'string') {
                    notifyError(result);
                }
            }
        );
    }
    const handleDeleteCancel = () => {
        dispatch({ type: 'SETVISIBLEDELETE', visible: false });
    }

    //END Modal delete

    //Modal update a record
    const showUpdate = (value: any) => {
        dispatch({ type: 'SETRUBRICAITEM', payload: value });
        dispatch({ type: 'SETVALIDATOR', validator: undefined });
        dispatch({ type: "SETVISIBLEUPDATE", visible: true });
    }

    const handleUpdate = () => {
        if (!ControllaCampi())
            return;

        RestCall.Put(state.rubricaItem, RestUrl.RUBRICA_OPERATORE_POST_MODIFY, "ok", 'url').then(
            result => {
                if (result === "ok") {
                    load();
                    dispatch({ type: "SETVISIBLEUPDATE", visible: false });
                    notifySucces("@V_ITEM_MODIFY");
                } else {
                    if (result === "VALID_TOKEN") {
                        handleUpdate();
                    } else {
                        notifyError(result.body?.data?.messages?.join('; '));
                    }
                }
            }
        );
    }
    const handleUpdateCancel = () => {
        dispatch({ type: "SETVISIBLEUPDATE", visible: false });
    }
    //END Modal update

    /*     const RubricaCreatorColumn = [
    
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: translate('@NOME'),
                dataIndex: 'nome',
                key: 'nome',
            },
            {
                title: translate('@MAIL'),
                dataIndex: 'mail',
                key: 'mail',
                width: 200
            },
            {
                title: translate('@MAILTARGA'),
                dataIndex: 'mailTarga',
                key: 'mailTarga',
                width: 200
            },
            {
                title: translate('@TELEFONO'),
                dataIndex: 'telefono',
                key: 'telefono'
            },
            {
                title: "",
                key: 'action',
                fixed: RightFixed,
                width: 120,
                render: (text: any, record: any) => (
                    <div className="container-col">
                        <div className="container-row c-ctr">
                            <Button
                                className="button-modify"
                                title={TranslateString("@MODIFY")}
                                style={{ marginRight: "5px" }}
                                type="primary"
                                shape="round"
                                size={'small'}
                                icon={<EditOutlined />}
                                onClick={() => showUpdate(record)}
                            />
                            <Button
                                className="red-background-ghost"
                                title={TranslateString("@DELETE")}
                                shape="round"
                                size={'small'}
                                icon={<DeleteOutlined />}
                                onClick={() => showDelete(record)}
                            />
                        </div>
                    </div>
                ),
            }
        ]; */

    //START filter handler
    const handleFilter = (value: string, sql_name: string, operator: string, filter_type: SITableFilterTypeValues) => { //gestisce i filtri delle colonne di tipo stringa 
        dispatch({ type: 'SETFILTERWHERE', where_filter: new FilterStructureModel(sql_name, operator, filter_type, value) });
    }
    const handleOrderBy = (order_by: SITableOrder) => {//permette di effettuare l'ordinamento per colonna ASC/DESC
        dispatch({ type: 'SETORDERBYFILTER', orderby_filter: new Order(order_by.table_View_ColumnName, order_by.mode) });
    }
    //END filter handler


    return (
        <>
            <div className="container-col height-100">
                <div><h1 className="title-page">{TranslateString("@RUBRICA_OPERATORI")}</h1></div>
                <div className="container-col scrollable-calc-40">
                    <div className="desktopBody" style={{ paddingBottom: '10px' }}>
                        <Button className="table-new-button" onClick={showCreateModal}><PlusOutlined />{TranslateString("@NEW_ITEM")}</Button>
                        <Button className="table-reload-button" onClick={() => load()}><ReloadOutlined />{TranslateString("@RELOAD_TABLE")}</Button>
                    </div>
                    <div className="mobile-body container-rowr" style={{ paddingBottom: '10px' }}>
                        <Button className="table-new-button" onClick={showCreateModal}><PlusOutlined /></Button>
                        <Button className="table-reload-button" onClick={() => load()}><ReloadOutlined /></Button>
                    </div>
                    {/*                     <div className="height-100-32">
                        <Table size={"small"} pagination={false} dataSource={state.rubrica} columns={RubricaCreatorColumn} scroll={{ x: 'auto', y: 650 }} />
                    </div> */}
                    <div className="height-100-70">
                        <div className="si-table-container height-100 width-100">
                            <SITableVirtualizedComponent
                                id_univoco={"operatori_transfilm"}
                                page={undefined}
                                page_size={undefined}
                                totalcount={1}
                                data_source={state.rubrica}
                                columns={OperatoreColumns(handleOrderBy, handleFilter, showUpdate, showDelete)}
                                filters={undefined}
                                bordered={true}
                                onPagination={() => { }}
                                select_rows={undefined}
                                onFilterChange={() => { }}
                                onShiftColumn={() => { }}
                                loading={loading}
                                colorRowClick={'#18D5FF'}
                                onRowClass={(record: any, index) => {
                                    if (index % 2 == 0) {
                                        return " light-gray "
                                    } else {
                                        return " "
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
                {state.create_visible &&
                    <Modal
                        wrapClassName="mobile-large2-modal"
                        title={TranslateString("@NEW_RUBRICA")}
                        visible={state.create_visible}
                        onOk={handleCreate}
                        onCancel={handleCreateCancel}
                        closeIcon={<LeftOutlined className="mobile-body" />}
                        keyboard={false}
                        maskClosable={false}
                        width="500px"
                    >
                        <OperatoreForm record={state.rubricaItem} onChange={handleChanges} />
                    </Modal>
                }

                {state.update_visible &&
                    <Modal
                        wrapClassName="mobile-large2-modal"
                        title={TranslateString("@UPDATE_RUBRICA")}
                        visible={state.update_visible}
                        onOk={handleUpdate}
                        onCancel={handleUpdateCancel}
                        closeIcon={<LeftOutlined className="mobile-body" />}
                        keyboard={false}
                        maskClosable={false}
                        width="500px"
                    >
                        <OperatoreForm record={state.rubricaItem} onChange={handleChanges} />
                    </Modal>
                }
                {state.delete_visible &&
                    <Modal
                        wrapClassName="modal-s"
                        title={TranslateString("@DELETE_RUBRICA")}
                        visible={state.delete_visible}
                        onOk={handleDelete}
                        onCancel={handleDeleteCancel}
                        closeIcon={<LeftOutlined className="mobile-body" />}
                        keyboard={false}
                        maskClosable={false}
                        width="500px"
                    >
                        {TranslateString("@ARE_YOU_SURE")}
                    </Modal>
                }

            </div>
        </>
    );
}