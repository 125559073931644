import React from "react";
import { Validate } from "../AntDesignType";
import { ChangePasswordModel, UserPortDto } from "../Model/UsersModel";

interface UsersProps {

}

export class UsersValidator {
    constructor(
        public username:Validate,
        public password:Validate,
        public usertype:Validate

    ){}

    static getDefault = () => {
        return (new UsersValidator(undefined,undefined,undefined));
    }
}

export const checkUsersValidField = (valid:UsersValidator): boolean => {

    if (valid.username !== undefined || valid.password !== undefined || valid.usertype !== undefined ){
        return false;
    }else {
        return true;
    }
}

export const setUsersValidField = (value:UserPortDto) : UsersValidator=> {

    let valid = UsersValidator.getDefault();
    if(typeof value.userName === 'undefined' || value.userName === "" ){
        valid.username = 'error';
    }else{
        valid.username = undefined;
    }
    if(typeof value.userPassword === 'undefined' || value.userPassword === "" ){
        valid.password = 'error';
    }else{
        valid.password = undefined;
    }
    return(valid);
}



export class ChangePswValidator {
    constructor(
        public cfmPassword:Validate,
        public newPassword:Validate,
        public oldPsw:Validate
    ){}

    static getDefault = () => {
        return (new ChangePswValidator(undefined,undefined,undefined));
    }
}

export const checkChangePswValidField = (valid:ChangePswValidator): boolean => {

    if (valid.cfmPassword !== undefined || valid.newPassword !== undefined || valid.oldPsw !== undefined){
        return false;
    }else {
        return true;
    }
}

export const setChangePswValidField = (value:ChangePasswordModel) : ChangePswValidator=> {
    
    let valid = ChangePswValidator.getDefault();
    if(typeof value.confpassword === 'undefined' || value.confpassword === "" || value.confpassword  !== value.newpassword){
        valid.cfmPassword = 'error';
    }else{
        valid.cfmPassword = undefined;
    }
    if(typeof value.oldpassword === 'undefined' || value.oldpassword === "" ){
        valid.oldPsw = 'error';
    }else{
        valid.oldPsw = undefined;
    }
    if(typeof value.newpassword === 'undefined' || value.newpassword === "" ){
        valid.newPassword = 'error';
    }else{
        valid.newPassword = undefined;
    }
    
    return(valid);
}


