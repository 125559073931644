import { TranslateString } from "../../i18n/translate";
import { PreventivoPortDTO } from "../Model/PreseModel"
import { Collapse, Descriptions } from "antd";
import { Table, Tabs } from "antd";

import {
    CheckSquareOutlined, BorderOutlined
} from '@ant-design/icons';
const { Panel } = Collapse;
interface PreventiviHeadSummaryProps {
    record: PreventivoPortDTO
}


export const PreventiviHeadSummary: React.FC<PreventiviHeadSummaryProps> = props => {

    const mittente = props.record.presa.mittente;
    const destinatario = props.record.presa.destinazioni[0];

    const columnsProducts = [
        {
            title: 'Descrizione',
            dataIndex: 'descrizione',
            key: 'descrizione'
        },
        {
            title: 'Lung.',
            dataIndex: 'lunghezza',
            key: 'lunghezza'
        },
        {
            title: 'Larg.',
            dataIndex: 'larghezza',
            key: 'larghezza'
        },
        {
            title: 'Alt.',
            dataIndex: 'altezza',
            key: 'altezza'
        },
        {
            title: 'Pallet',
            dataIndex: 'nrPallet',
            key: 'nrPallet'
        },
        {
            title: 'Colli',
            dataIndex: 'nrColli',
            key: 'nrColli'
        },
        {
            title: 'Peso',
            dataIndex: 'peso',
            key: 'peso'
        },
        {
            title: 'Extra Sagoma',
            dataIndex: 'extraSagoma',
            key: 'extraSagoma'
        },
    ];

    return <Collapse style={{marginTop:10, marginBottom: 10}} >
        <Panel header="Dati Preventivo" key="1">
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ width: 'calc(50% - 10px)' }}>
                    <div className="ant-descriptions-title">Mittente</div>
                    <Descriptions bordered column={2} >
                        <Descriptions.Item label={TranslateString("@RAGSOC")} span={2}>{mittente.ragioneSociale}</Descriptions.Item>
                        <Descriptions.Item label={TranslateString("@INDIRIZZO")}>{mittente.indirizzo}</Descriptions.Item>
                        <Descriptions.Item label={'Provincia'}>{mittente.provincia}</Descriptions.Item>
                        <Descriptions.Item label={'Località'}>{mittente.localita}</Descriptions.Item>
                        <Descriptions.Item label={'Nazione'}>{mittente.nazione}</Descriptions.Item>

                    </Descriptions>
                </div>
                <div style={{ width: 'calc(50% - 10px)' }}>
                    <div className="ant-descriptions-title">Destinatario</div>
                    <Descriptions bordered column={2} >
                        <Descriptions.Item label={TranslateString("@RAGSOC")} span={2}>{destinatario.ragioneSociale}</Descriptions.Item>
                        <Descriptions.Item label={TranslateString("@INDIRIZZO")}>{destinatario.indirizzo}</Descriptions.Item>
                        <Descriptions.Item label={'Provincia'}>{destinatario.provincia}</Descriptions.Item>
                        <Descriptions.Item label={'Località'}>{destinatario.localita}</Descriptions.Item>
                        <Descriptions.Item label={'Nazione'}>{destinatario.nazione}</Descriptions.Item>
                    </Descriptions>
                </div>
            </div>
            <Descriptions bordered column={3} style={{ marginTop: 10, marginBottom: 20 }}>
                <Descriptions.Item label={'Preavviso Telefonico'} >{destinatario.flagPreavvisoTelefonico ? <CheckSquareOutlined /> : <BorderOutlined />}</Descriptions.Item>
                <Descriptions.Item label={'Sponda Idraulica'}>{destinatario.flagSpondaIdraulica ? <CheckSquareOutlined /> : <BorderOutlined />}</Descriptions.Item>
                <Descriptions.Item label={'Merce Non Sovrapponibile'}>{destinatario.flagMerceNonSovrapponibile ? <CheckSquareOutlined /> : <BorderOutlined />}</Descriptions.Item>
            </Descriptions>
            <div className="ant-descriptions-title">Merce</div>
            <Table columns={columnsProducts} dataSource={destinatario.imballi} pagination={false} scroll={{ y: 600 }} />

        </Panel>
    </Collapse>
}