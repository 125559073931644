import React from "react";
import { FilterStructureModel, Order } from "../Model/FilterModel";
import { ChangePasswordModel, UserPortDto } from "../Model/UsersModel";
import { ChangePswValidator, UsersValidator } from "./UsersInterface";
var _ = require('lodash');


interface actionTypeCreateVisible {
    type: "SETVISIBLECREATE",
    visible: boolean
}
interface actionTypeUpdateVisible {
    type: "SETVISIBLEUPDATE",
    visible: boolean
}
interface actionTypeDeleteVisible {
    type: "SETVISIBLEDELETE",
    visible: boolean
}
interface actionTypeSetValidator {
    type: "SETVALIDATOR",
    validator: UsersValidator
}
interface actionTypeSetDataSource {
    type: "SETDATASOURCE",
    value: any
}
interface actionTypeSetPagination {
    type: "SETPAGINATION",
    page: number | undefined,
    pageSize: number | undefined
}
interface actionTypeSetTotalcount {
    type: "SETTOTALCOUNT",
    totalcount: number,
}
interface actionTypeSetDataSourceTotalcount {
    type: "SETDATASOURCETOTALCOUNT",
    value: UserPortDto[],
    totalcount: number,
}
interface actionTypeSetRecord {
    type: "SETRECORD",
    value: any
}
interface actionTypeSetDate {
    type: "SETDATE",
    value: string
}
interface actionTypeSetFilterWhere {
    type: "SETFILTERWHERE",
    where_filter: FilterStructureModel,
}
interface actionTypeSetOrderbyFilter {
    type: "SETORDERBYFILTER",
    orderby_filter: Order,
}
interface actionTypeSetCreateVisibleAndDefault {
    type: "SETCREATEVISIBLEANDDEFAULT",
    visible: boolean,
    record: UserPortDto,
    validator: UsersValidator
}
interface actionTypeSetCUpdateVisibleAndDefault {
    type: "SETUPDATEVISIBLEANDDEFAULT",
    visible: boolean,
    record: UserPortDto,
    validator: UsersValidator
}
interface actionTypeSetColumns {
    type: "SETCOLUMNS",
    value: any[],
}
interface actionTypeSetChangePswVisible {
    type: 'SETCHANGEVISIBLE',
    visible: boolean
}
interface actionTypeSetChangePswRecordAndVisible {
    type: 'SETCHANGEPSWRECORDANDVISIBLE',
    visible: boolean,
    value: number
}
interface actionTypeSetChangePswRecord {
    type: 'SETCHANGEPSWVALUE',
    value: ChangePasswordModel
}
interface actionTypeSetChangePswValidator {
    type: 'SETCHANGEPSWVALIDATOR',
    validator: ChangePswValidator
}
interface actionTypeSetTrackingVisibleRecord {
    type: "SETTRACKINGVISIBLE_RECORD",
    visible: boolean,
    record: UserPortDto,
}
interface actionTypeSetTrackingVisible {
    type: 'SETTRACKINGVISIBLE',
    visible: boolean
}

export type actions = actionTypeCreateVisible | actionTypeSetValidator | actionTypeSetFilterWhere
    | actionTypeSetDataSource | actionTypeDeleteVisible | actionTypeUpdateVisible
    | actionTypeSetPagination | actionTypeSetTotalcount | actionTypeSetDataSourceTotalcount
    | actionTypeSetRecord | actionTypeSetDate | actionTypeSetOrderbyFilter
    | actionTypeSetCreateVisibleAndDefault
    | actionTypeSetCUpdateVisibleAndDefault
    | actionTypeSetColumns
    | actionTypeSetChangePswVisible
    | actionTypeSetChangePswRecordAndVisible
    | actionTypeSetChangePswRecord
    | actionTypeSetChangePswValidator
    | actionTypeSetTrackingVisibleRecord
    | actionTypeSetTrackingVisible
    ;


interface IReducer {
    create_visible: boolean,
    update_visible: boolean,
    delete_visible: boolean,
    change_psw_visible: boolean,
    change_psw_validator: ChangePswValidator,
    change_psw_record: ChangePasswordModel,
    validator: UsersValidator,
    record: UserPortDto,
    data_source: any[],
    totalcount: number,
    page: number | undefined,
    pageSize: number | undefined,
    set_date: string,
    where_filter: FilterStructureModel[],
    orderby_filter: Order,
    columns: any[],
    first_call: boolean,
    tracking_visible: boolean,
}

export function UsersReducer(state: IReducer, action: actions): IReducer {

    switch (action.type) {
        case 'SETCHANGEPSWVALIDATOR':
            return { ...state, change_psw_validator: action.validator }
        case 'SETCHANGEPSWVALUE':
            return { ...state, change_psw_record: action.value }
        case 'SETCHANGEPSWRECORDANDVISIBLE':
            return { ...state, change_psw_visible: action.visible, change_psw_record: { ...ChangePasswordModel.getDefault(), userid: action.value } }
        case 'SETCHANGEVISIBLE':
            return { ...state, change_psw_visible: action.visible }
        case 'SETCOLUMNS':
            return { ...state, columns: action.value }
        case 'SETCREATEVISIBLEANDDEFAULT':
            return { ...state, create_visible: action.visible, record: action.record, validator: action.validator }
        case 'SETUPDATEVISIBLEANDDEFAULT':
            return { ...state, update_visible: action.visible, record: action.record, validator: action.validator }
        case 'SETDATE':
            return { ...state, set_date: action.value }
        case 'SETRECORD':
            return { ...state, record: action.value }
        case 'SETVISIBLECREATE':
            return { ...state, create_visible: action.visible }
        case 'SETVISIBLEUPDATE':
            return { ...state, update_visible: action.visible }
        case 'SETVISIBLEDELETE':
            return { ...state, delete_visible: action.visible }
        case 'SETVALIDATOR':
            return { ...state, validator: action.validator }
        case 'SETDATASOURCE':
            return { ...state, data_source: action.value }
        case 'SETTOTALCOUNT':
            return { ...state, totalcount: action.totalcount }
        case 'SETPAGINATION':
            return { ...state, page: action.page, pageSize: action.pageSize }
        case 'SETDATASOURCETOTALCOUNT':
            return { ...state, totalcount: action.totalcount, data_source: action.value }
        case 'SETFILTERWHERE':
            const where_array: FilterStructureModel[] = _.filter(state.where_filter, (item: FilterStructureModel) => item.table_View_ColumnName !== action.where_filter.table_View_ColumnName);
            if (action.where_filter.value !== "") {
                const s = {
                    ...state, where_filter: [
                        ...where_array,
                        action.where_filter
                    ], first_call: false
                }
                return s;
            }

            return { ...state, where_filter: where_array, first_call: false }
        case 'SETORDERBYFILTER':
           /*  const order_filter: Order[] = _.filter(state.orderby_filter, (item: Order) => item.table_View_ColumnName !== action.orderby_filter.table_View_ColumnName);

            return { ...state, orderby_filter: [...order_filter, action.orderby_filter], first_call: false } */
            return { ...state, orderby_filter: action.orderby_filter, first_call: false }

        case 'SETTRACKINGVISIBLE':
            return { ...state, tracking_visible: action.visible }
        case 'SETTRACKINGVISIBLE_RECORD':
            return { ...state, tracking_visible: action.visible, record: action.record }
        default:
            return { ...state }

    }
}

