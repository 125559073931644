//modello utilizzato solo per la login
export class UsersModel {
    constructor(
        public id: number,
        public isactive: string,
        public superuser: boolean,
        public username: string,
        public password: string,
        public surname: string | null,
        public firstname: string | null,
        public description: string | null,
        public rowversion: string,
        public enableTracking: boolean,
        public enablePrese: boolean,
    ) { }

    static getDefault = (): UsersModel => {
        return (
            new UsersModel(-1, 'Y', false, "", "", null, null, null, "", false, false)
        );
    }
}

//modello intermedio utilizzato per il portale delle prese
export class UserPortDto {
    constructor(
        public id: number,
        public idUser: number,
        public userName: string,
        public userPassword: string,
        public codiceCliente: string,
        public hideAlerts: boolean,
        public isBackoffice: boolean,
        public userType: number,
        public userTypeDescription: string,
        public enableTracking: boolean,
        public enablePrese: boolean,
        public enableDownloadPod: boolean,
        public clientiTracking: CustomerListModel[],
    ) { }

    static getDefault = (): UserPortDto => {
        return (
            new UserPortDto(0, -1, '', '', '', false, false, -1, '', false, false, false, [])
        );
    }
}

export class CustomerListModel {
    constructor(
        public idUser: number,
        public codiceCliente: string,
        public ragioneSociale: string,
    ) { }

    static getDefault = (): CustomerListModel => {
        return (
            new CustomerListModel(-1, "", "")
        );
    }
}

export class ChangePasswordModel {
    constructor(
        public userid: number,
        public newpassword: string,
        public confpassword: string,
        public oldpassword: string
    ) { }

    static getDefault = (): ChangePasswordModel => {
        return (
            new ChangePasswordModel(-1, "", "", "")
        );
    }
}


export class UserSearchModel {
    constructor(
        public pageNr: number,
        public pageSize: number,
        public sortOrder: string | undefined,
        public sortDirection: string | undefined
    ) { }
    static getDefault = (): UserSearchModel => {
        return (new UserSearchModel(1, 10, undefined, undefined))
    }
}