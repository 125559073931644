import { Button, DatePicker, Form, Input, TimePicker } from "antd";
import moment from "moment";
import { Moment } from "moment";
import { KeyboardEventHandler, useState } from "react";
import { TranslateString } from "../../i18n/translate";
import { Validate } from "../AntDesignType";
import { split_DD_MM_YYYY_with_slash_no_null } from "./FormatDate";
import {
    FileSearchOutlined
} from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/it_IT';
import { isPropertyAccessOrQualifiedName } from "typescript";
import React from "react";
const { TextArea } = Input;

interface FormInputTextProps {

    label_name?: string,
    input_name?: string,
    input_value?: string | undefined | number,
    time_value?: Moment | undefined,
    validate: Validate,
    onChange?: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void,
    onBlur?: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void,
    onTimeChange?: (fieldname: string | undefined, time: Moment | null, timeString: string) => void,
    mandatory: boolean,
    onChangeDataPicker?: (event: any, value?: any) => void,
    placeholder?: string,
    helpMessage?: string,
    dataSource?: any[]
    date_value?: Date | string | number | null
    defalutValue?: string | number | undefined
    readonly?: boolean | undefined,
    valuta?: string,
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    disabledDate?: (date: moment.Moment) => boolean
    textArea?:number
}





export const FormTimeInput: React.FC<FormInputTextProps> = props => {


    return (
        <>
            <div className="column-1">
                {typeof props.readonly === 'undefined' ?
                    <label className="label-font" htmlFor={props.label_name}>{TranslateString('@' + props.label_name)}</label>
                    :
                    <label className="label-font" style={{ color: "lightgray" }} htmlFor={props.label_name}>{TranslateString('@' + props.label_name)}</label>
                }
                {props.mandatory ? <label className="label-font label-red" htmlFor={props.label_name}> *</label> : null}
            </div>
            <Form.Item
                validateStatus={props.validate}
                help={typeof props.validate !== 'undefined' ? typeof props.helpMessage === 'undefined' ? TranslateString("@MANDATORY_FIELD_MESSAGE") : props.helpMessage : null}
            >
                <TimePicker disabled={props.readonly ?? false} className="custom-ant-picker" name={props.input_name} placeholder={props.placeholder} id={props.validate} value={props.time_value} format={'HH:mm'} onChange={(time: Moment | null, timeString: string) => props.onTimeChange?.(props.input_name, time, timeString)} minuteStep={15} />

            </Form.Item>
        </>
    );
}


export const FormTextInput_old: React.FC<FormInputTextProps> = props => {


    return (
        <>
            <div className="column-1">
                {typeof props.readonly === 'undefined' ?
                    <label className="label-font" htmlFor={props.label_name}>{TranslateString('@' + props.label_name)}</label>
                    :
                    <label className="label-font" style={{ color: "lightgray" }} htmlFor={props.label_name}>{TranslateString('@' + props.label_name)}</label>
                }
                {props.mandatory ? <label className="label-font label-red" htmlFor={props.label_name}> *</label> : null}
            </div>
            <Form.Item
                validateStatus={props.validate}
                help={typeof props.validate !== 'undefined' ? typeof props.helpMessage === 'undefined' ? TranslateString("@MANDATORY_FIELD_MESSAGE") : props.helpMessage : null}
            >
                <Input onKeyPress={props.onKeyPress} readOnly={props.readonly} className="input-lg " type="text" name={props.input_name} placeholder={props.placeholder} id={props.validate} value={props.input_value} onChange={props.onChange} onBlur={props.onBlur} />
            </Form.Item>
        </>
    );
}

export const FormTextInput_new: React.FC<FormInputTextProps> = props => {
    const [localValue, setLocalValue] = useState<string | number | undefined>(props.input_value);

    React.useEffect(
        () => {
            setLocalValue(props.input_value);
        }, [props.input_value]
    )

    const localOnChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setLocalValue(event.currentTarget.value);
    }

    const localOnBlur = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        if (props.onChange)
            props.onChange(event);

        if (props.onBlur)
            props.onBlur(event);
    }

    return (
        <>
            <div className="column-1">
                {(typeof props.readonly === 'undefined' || props.readonly) ?                    
                    <label className="label-font" style={{ color: "lightgray" }} htmlFor={props.label_name}>{TranslateString('@' + props.label_name)}</label>
                    :
                    <label className="label-font" htmlFor={props.label_name}>{TranslateString('@' + props.label_name)}</label>
                }
                {props.mandatory ? <label className="label-font label-red" htmlFor={props.label_name}> *</label> : null}                
            </div>
            <Form.Item
                validateStatus={props.validate}
                help={typeof props.validate !== 'undefined' ? typeof props.helpMessage === 'undefined' ? TranslateString("@MANDATORY_FIELD_MESSAGE") : props.helpMessage : null}
            >
                {!props.textArea && <Input  onKeyPress={props.onKeyPress} readOnly={props.readonly} className="input-lg " type="text" name={props.input_name} placeholder={props.placeholder} id={props.validate} value={localValue} onChange={localOnChange} onBlur={localOnBlur} />}
                {props.textArea && <TextArea rows={props.textArea}  readOnly={props.readonly} name={props.input_name} placeholder={props.placeholder} id={props.validate} value={localValue} onChange={localOnChange} onBlur={localOnBlur} />}
            </Form.Item>
        </>
    );
}



/*export const FormTextInput: React.FC<FormInputTextProps> = props => {


    return (
        <>
            <div className="column-1">
                {typeof props.readonly === 'undefined' ?
                    <label className="label-font" htmlFor={props.label_name}>{TranslateString('@' + props.label_name)}</label>
                    :
                    <label className="label-font" style={{ color: "lightgray" }} htmlFor={props.label_name}>{TranslateString('@' + props.label_name)}</label>
                }
                {props.mandatory ? <label className="label-font label-red" htmlFor={props.label_name}> *</label> : null}
            </div>
            <Form.Item
                validateStatus={props.validate}
                help={typeof props.validate !== 'undefined' ? typeof props.helpMessage === 'undefined' ? TranslateString("@MANDATORY_FIELD_MESSAGE") : props.helpMessage : null}
            >
                <Input onKeyPress={props.onKeyPress} readOnly={props.readonly} className="input-lg " type="text" name={props.input_name} placeholder={props.placeholder} id={props.validate} value={props.input_value} onChange={props.onChange} onBlur={props.onBlur} />
            </Form.Item>
        </>
    );
}*/


export const FormTextInputOnBlur: React.FC<FormInputTextProps> = props => {


    return (
        <>
            <div className="column-1">
                <label className="label-font" htmlFor={props.label_name}>{TranslateString('@' + props.label_name)} </label>
                {props.mandatory ? <label className="label-font label-red" htmlFor={props.label_name}> *</label> : null}
            </div>
            <Form.Item
                validateStatus={props.validate}
                help={typeof props.validate !== 'undefined' ? typeof props.helpMessage === 'undefined' ? TranslateString("@MANDATORY_FIELD_MESSAGE") : props.helpMessage : null}
            >
                <Input defaultValue={props.defalutValue} className="input-lg " type="text" name={props.input_name} placeholder={props.placeholder} id={props.validate} value={props.input_value} onBlur={props.onChange} />
            </Form.Item>
        </>
    );
}

export const FormNumberInputOnBlur: React.FC<FormInputTextProps> = props => {


    return (
        <>
            <div className="column-1">
                <label className="label-font" htmlFor={props.label_name}>{TranslateString('@' + props.label_name)} {typeof props.valuta !== 'undefined' ? props.valuta : null}</label>
                {props.mandatory ? <label className="label-font label-red" htmlFor={props.label_name}> *</label> : null}
            </div>
            <Form.Item
                validateStatus={props.validate}
                help={typeof props.validate !== 'undefined' ? typeof props.helpMessage === 'undefined' ? TranslateString("@MANDATORY_FIELD_MESSAGE") : props.helpMessage : null}
            >
                <Input defaultValue={props.defalutValue} className="input-lg " type='number' name={props.input_name} placeholder={props.placeholder} id={props.validate} value={props.input_value} onBlur={props.onChange} />
            </Form.Item>
        </>
    );
}

export const FormNumberInput: React.FC<FormInputTextProps> = props => {


    return (
        <>
            <div className="column-1">
                <label className="label-font" htmlFor={props.label_name}>{TranslateString('@' + props.label_name)}</label>
                {props.mandatory ? <label className="label-font label-red" htmlFor={props.label_name}> *</label> : null}
            </div>
            <Form.Item
                validateStatus={props.validate}
                help={typeof props.validate !== 'undefined' ? typeof props.helpMessage === 'undefined' ? TranslateString("@MANDATORY_FIELD_MESSAGE") : props.helpMessage : null}
            >
                <Input className="input-lg " type="number" name={props.input_name} placeholder={props.placeholder} id={props.validate} value={props.input_value} onChange={props.onChange} />
            </Form.Item>
        </>
    );
}


export const FormPasswordInput: React.FC<FormInputTextProps> = props => {


    return (
        <>
            <div className="column-1">
                <label className="label-font" htmlFor={props.label_name}>{TranslateString('@' + props.label_name)}</label>
                {props.mandatory ? <label className="label-font label-red" htmlFor={props.label_name}> *</label> : null}
            </div>
            <Form.Item
                validateStatus={props.validate}
                help={typeof props.validate !== 'undefined' ? typeof props.helpMessage === 'undefined' ? TranslateString("@MANDATORY_FIELD_MESSAGE") : props.helpMessage : null}
            >
                <Input.Password className="input-lg-psw" type="text" name={props.input_name} placeholder={props.placeholder} id={props.validate} value={props.input_value} onChange={props.onChange} />
            </Form.Item>
        </>
    );
}


export const FormDataPickerInput: React.FC<FormInputTextProps> = props => {
    const D = new Date();
    const today = new Date(D.getFullYear() + '-' + (D.getMonth() + 1) + '-' + D.getDate());
    const dateFormat = 'DD/MM/YYYY';

    if (typeof props.date_value !== 'undefined') {
        if (props.date_value === "" || props.date_value === null) {
            return (
                <>
                    <div className="column-1">
                        <label className="label-font" htmlFor={props.label_name}>{TranslateString('@' + props.label_name)}</label>
                        {props.mandatory ? <label className="label-font label-red" htmlFor={props.label_name}> *</label> : null}
                    </div>
                    <Form.Item
                        validateStatus={props.validate}
                        help={typeof props.validate !== 'undefined' ? typeof props.helpMessage === 'undefined' ? TranslateString("@MANDATORY_FIELD_MESSAGE") : props.helpMessage : null}
                    >
                        <DatePicker
                            disabledDate={props.disabledDate}
                            disabled={props.readonly ?? false} className="custom-ant-picker"
                            locale={locale}
                            defaultValue={undefined}
                            style={{ width: "100%" }}
                            onChange={props.onChangeDataPicker} />
                    </Form.Item>
                </>
            );
        }
        else {

            const date = new Date(split_DD_MM_YYYY_with_slash_no_null(props.date_value!));
            return (
                <>
                    <div className="column-1">
                        <label className="label-font" htmlFor={props.label_name}>{TranslateString('@' + props.label_name)}</label>
                        {props.mandatory ? <label className="label-font label-red" htmlFor={props.label_name}> *</label> : null}
                    </div>
                    <Form.Item
                        validateStatus={props.validate}
                        help={typeof props.validate !== 'undefined' ? typeof props.helpMessage === 'undefined' ? TranslateString("@MANDATORY_FIELD_MESSAGE") : props.helpMessage : null}
                    >
                        <DatePicker disabledDate={props.disabledDate} disabled={props.readonly ?? false} className="custom-ant-picker" locale={locale} value={moment(date, dateFormat)} format={dateFormat} style={{ width: "100%" }} onChange={props.onChangeDataPicker} />
                    </Form.Item>
                </>
            );
        }
    } else {
        return (
            <>
                <div className="column-1">
                    <label className="label-font" htmlFor={props.label_name}>{TranslateString('@' + props.label_name)}</label>
                    {props.mandatory ? <label className="label-font label-red" htmlFor={props.label_name}> *</label> : null}
                </div>
                <Form.Item
                    validateStatus={props.validate}
                    help={typeof props.validate !== 'undefined' ? typeof props.helpMessage === 'undefined' ? TranslateString("@MANDATORY_FIELD_MESSAGE") : props.helpMessage : null}
                >
                    <DatePicker disabledDate={props.disabledDate} disabled={props.readonly ?? false} className="custom-ant-picker" locale={locale} defaultValue={undefined} style={{ width: "100%" }} onChange={props.onChangeDataPicker} />
                </Form.Item>
            </>
        );
    }
}


interface FormInputTextSearchProps {
    label_name?: string,
    input_name?: string,
    input_value?: string | undefined | number,
    validate: Validate,
    onSearch?: (value: any) => void,
    onKeyUp?: (event: React.KeyboardEvent<any>) => void,
    mandatory: boolean,
    onChange?: (value: any) => void,
    placeholder?: string,
    helpMessage?: string,
    successMessage?: boolean, //inseirire insieme a helpMessage per mostrare il messaggo di avvenuto successo con dei valori aggiuntivi fuori dalla textbox
    onResetClick?: () => void,
    display?: boolean
}

export const FormTextInputOnSearch: React.FC<FormInputTextSearchProps> = props => {
    let show = 'none';
    typeof props.display === 'undefined' || props.display === false ? show = 'none' : show = 'block';
    return (
        <>
            <div className="column-1">
                <label className="label-font" htmlFor={props.label_name}>{TranslateString('@' + props.label_name)}</label>
                {props.mandatory ? <label className="label-font label-red" htmlFor={props.label_name}> *</label> : null}
            </div>
            <Form.Item
                validateStatus={typeof props.validate !== 'undefined' ? props.validate : props.successMessage === true ? 'success' : undefined}
                help={typeof props.validate !== 'undefined' || props.successMessage === true ? typeof props.helpMessage === 'undefined' ? TranslateString("@MANDATORY_FIELD_MESSAGE") : props.helpMessage : null}
            >
                <div className="container-row">
                    <Input.Search name={props.input_name} placeholder={props.placeholder} id={props.validate} value={props.input_value} onPressEnter={props.onKeyUp} onSearch={props.onSearch} onChange={props.onChange} />
                    <Button icon={<FileSearchOutlined />} onClick={props.onResetClick} style={{ marginLeft: '-2px', display: show }}></Button>
                </div>
            </Form.Item>

        </>
    );
}

export const checkStringOnlyNumbers = (record: string): boolean => {
    var reg = /^\d+$/;
    return reg.test(record);
}