import React, { useCallback, useMemo } from "react";
import RestUrl from '../../RestUrl.json';
import { Alert, Button, Card, Drawer, Modal, Result, Select, Switch, Table, Tag } from "antd";
import { TranslateString } from "../../i18n/translate";
import { SITableFilterTypeValues } from "@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterController"
import { SITableOrder } from "@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterModel"
import { SITableVirtualizedComponent } from "@si/si-react-components/dist/esm/components/SITable/SITableVirtualized/SITableVirtualizedComponent"
import {
    ReloadOutlined, PhoneOutlined, ExclamationCircleOutlined, PlusOutlined, EyeOutlined, ClearOutlined, ApartmentOutlined, LeftOutlined, EditOutlined, DeleteOutlined, CopyOutlined
} from '@ant-design/icons';
import { PreseReducer } from './PreseType';
import { FilterStructureModel, Order, OrderType } from "../Model/FilterModel";
import { RestCall } from "../Rest/Rest_Call/RestCall";
import { notifyError, notifyErrorJSX, notifyErrorPlain, notifyInfo, notifySucces } from "../CustomToast/customToast";
import { getPreseImballiRowType, PresaPortDTO, PreseSearchModel, PreseSearchModelFilterStateDefault } from "../Model/PreseModel";
import { FormateDate } from "../../utils/FormatDate";
import { RightFixed } from "../AntDesignType";
import { PreseForm } from "./PreseForm";
import { PreseValidator, checkPreseValidField, preseValidationErrorMessage, setPreseValidField, uniformaPresa } from "./PreseInterface";
import store from '../Redux/Store';
import { FormDataPickerInput } from "../Utils_Component/InputForm";
import moment from "moment";
import { Moment } from "moment";
import { formatTime } from "../Utils_Component/FormatDate";
import { PreseCancelModal } from "./PreseCancelModal";
import { PreseColumns } from "./PreseColumns";
import { UserType } from "../Redux/Reducer";
import { ChangeUrl } from "../../utils/ChangeUrl";
import { debug } from "console";
import { TrackingList } from "../Tracking/TrackingList";

var _ = require('lodash');

const { Option } = Select;



export const PreseList: React.FC = props => {
    var timer: any = undefined;
    const [user_type, setUserType] = React.useState<UserType>(store.getState().user_type);
    const [loading, setLoading] = React.useState(false);
    const [wait, setWait] = React.useState(false);
    //const [reload, setReload] = React.useState(false);
    const [timeReload, setTimeReload] = React.useState(Date.now());
    const [codiceClienteList, setCodiceClienteList] = React.useState<any[]>([]);
    //const [codiceClienteSearch, setCodiceClienteSearch] = React.useState('ALL');
    const [filters, setFilters] = React.useState<{ filtersSearch: PreseSearchModel, customer: string, forceReload: string }>({ filtersSearch: PreseSearchModel.getDefault(), customer: 'ALL', forceReload: '' });

    const [state, dispatch] = React.useReducer(PreseReducer, {
        create_visible: false,
        update_visible: false,
        delete_visible: false,
        tracking_visible: false,
        //upload_visible:false,
        validator: PreseValidator.getDefault(),
        record: PresaPortDTO.getDefault(),
        data_source: [],
        page: 1,
        pageSize: 20,
        totalcount: 0,
        set_date: "",
        where_filter: [],
        orderby_filter: [],
        first_call: true,
        columns: [],
        timer_image_finished: false,

        //mobile filter visible
        //filter_visible: false,
        //filter_display: false
    });
    /*React.useEffect(
        () => {
            if (state.filter_visible === false) {
                setTimeout(function () {
                    dispatch({ type: 'SETFILTERDISPLAY', visible: false });
                }, 180);
            } else {
                setTimeout(function () {
                    dispatch({ type: 'SETFILTERDISPLAY', visible: true });
                }, 180);
            }
        }, [state.filter_visible]
    );*/

    React.useEffect(
        () => {
            if (state.first_call !== true) {
                loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
            }
        }, [filters.customer, filters.filtersSearch.stato, filters.forceReload]
    );

    React.useEffect(
        () => {
            const s = store.getState().user_type;
            setUserType(s);
        }, [store.getState().user_type]
    );

    React.useEffect(
        () => {

            const s = store.getState().user_type;
            setUserType(s);
            if (/*isBackoffice*/store.getState().user_type === 'superuser' || store.getState().user_type === 'backoffice') {
                loadListaClienti();
            }

            console.log('first useeffect')
            loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);

            const interval = setInterval(() => setTimeReload(Date.now()), 60000);
            return () => {
                clearInterval(interval);
            };
        }, []);

    React.useEffect(
        () => {
            const s = store.getState().user_type;
            if (state.first_call !== true && (!state.create_visible) && (!state.update_visible) && (!state.delete_visible)) {
                console.log('reload table')
                loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
            }
        }, [state.create_visible, state.update_visible, state.delete_visible, state.where_filter, state.orderby_filter, state.page, state.pageSize]);

    React.useEffect(
        () => {
            if (state.first_call !== true) {
                console.log('useeffect where filter')
                loadtable(state.where_filter, state.orderby_filter, undefined, state.pageSize);
                dispatch({ type: 'SETPAGINATION', page: undefined, pageSize: state.pageSize });
            }
        }, [state.where_filter]
    );
    React.useEffect(
        () => {
            if (state.first_call !== true) {
                console.log('useeffect orderby')
                loadtable(state.where_filter, state.orderby_filter, undefined, state.pageSize);
                dispatch({ type: 'SETPAGINATION', page: undefined, pageSize: state.pageSize });
            }
        }, [state.orderby_filter]
    );



    const loadListaClienti = () => {
        RestCall.post_with_result_no_pagination({ soloClienti: true }, RestUrl.CLIENTE_SEARCH, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    setCodiceClienteList(result.data.data);
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadListaClienti();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );
    }
    const loadtable = (where: FilterStructureModel[], order: Order[], page: number | undefined, pageSize: number | undefined, filtro: any = filters) => {
        /*if ((store.getState().user_type === 'superuser' || store.getState().user_type === 'backoffice') && codiceClienteSearch === undefined) {
            notifyInfo('Necessario specificare un cliente');
            return;
        }*/

        setLoading(true);

        console.log('stato: ' + filtro.filtersSearch.stato);
        let filter = { ...filtro.filtersSearch };
        filter.pageNr = page === undefined ? 1 : page;
        filter.pageSize = pageSize === undefined ? 10 : pageSize;
        filter.codiceCliente = filtro.customer === 'ALL' ? undefined : filters.customer;
        filter.soloDaPortale = true; //todo
        filter.aData = filter.aData === '' ? undefined : filter.aData;
        filter.daData = filter.daData === '' ? undefined : filter.daData;

        RestCall.postWithReturn(filter, RestUrl.PRESE_SEARCH, page, pageSize, 'url').then(
            result => {
                if (typeof result !== 'undefined' && typeof result !== 'string') {
                    if (typeof result.data !== 'undefined' && result.data !== 'string') {
                        const ds = result.data.data.map((el: any, index: any) => { return { ...el, key: index } });
                        dispatch({ type: 'SETDATASOURCETOTALCOUNT', value: ds, totalcount: result.headers.x_pagination });
                        /*if (image_timer !== undefined) {
                            clearTimeout(image_timer);
                        }
                        image_timer = setTimeout(() => {
                            dispatch({ type: 'SETIMAGECALL', value: true });
                        }, 250);*/
                        setLoading(false);
                    }
                } else if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        loadtable(where, order, page, pageSize);
                    } else {
                        notifyError(result);
                        setLoading(false);
                    }
                } else {
                    notifyError("@UNHANDLE_ERROR");
                    setLoading(false);
                }
            }
        );
    }

    /*     const handlePagination = (page: number, pageSize?: number | undefined) => {//gestisce la paginazione
            console.log('handlePagination')
            dispatch({ type: 'SETPAGINATION', page: page, pageSize: pageSize });
            loadtable(state.where_filter, state.orderby_filter, page, pageSize!); //reload the table with the new data of the next page
        } */

    /*const handleMobileFilterVisible = (value: boolean) => {
        dispatch({ type: 'SETFILTERVISIBILITY', visible: value });
    }*/
    /*********************************************************************************************/
    //MODAL HANDLER AND ACTIONS
    /*********************************************************************************************/

    //Modal create
    const showCreateModal = () => {
        dispatch({ type: 'SETSHOWCREATE', validator: PreseValidator.getDefault(), value: PresaPortDTO.getDefault(), visible: true });
    }
    const handleModalCancel = () => {
        dispatch({ type: 'SETVISIBLECREATE', visible: false });
    }
    const handleErrorResponse = (response: any) => {
        //notifyError(response.status);

        const notifymessagedata = response.data !== undefined ? response.data?.data?.messages?.join('; ') : response.body?.data?.messages?.join('; ');

        notifyErrorPlain(notifymessagedata);

    }
    
    const handleModalOk = () => {
        const presaUniformata = uniformaPresa(state.record);
        setWait(true);
        dispatch({ type: 'SETVALIDATOR', validator: setPreseValidField(presaUniformata) });
        const presaValidation = checkPreseValidField(setPreseValidField(presaUniformata));
        if (presaValidation.valid) {
            
            RestCall.post(presaUniformata, RestUrl.PRESE_POST_INSERT, undefined, 'url').then(
                result => {
                    if (result.statusText === 'OK' || result.status === 200) {
                        notifySucces("@V_PRESE_POST");
                        if (result.data.messages?.length > 0) {
                            switch (result.data.responseType) {
                                case 'error':
                                    notifyError(result.data.messages.join('; '));
                                    break;
                                default:
                                    notifyInfo(result.data.messages.join('; '));
                                    break;
                            }
                        }
                        dispatch({ type: 'SETVISIBLECREATE', visible: false });
                        loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
                        setWait(false);
                    } else if (result === 'VALID_TOKEN') {
                        handleModalOk();
                        setWait(false);
                    } else {
                        handleErrorResponse(result);
                        setWait(false);
                    }
                }
            );
        }
        else {
            preseValidationErrorMessage(presaValidation.message);
            setWait(false);
        }
    }
    

    const checkPresaMessage = (presaUniformata: PresaPortDTO, result: any): React.ReactNode => {
        const dati_generali = <Card
            title="Dati generali"
            size="small"
            className="prese-modal-card"
            headStyle={{ backgroundColor: '#fafafa' }}>
            <div className="drawer-body-rubrica-item-row">Operatore: {presaUniformata.operatorePortale}</div>
            <div className="drawer-body-rubrica-item-row">Rif. fatturazione: {presaUniformata.riferimentoFatturazione}</div>
            <div className="drawer-body-rubrica-item-row">Data ritiro: {moment(presaUniformata.dataRitiro).format('DD/MM/YYYY')}</div>
            <div className="drawer-body-rubrica-item-row">Ora ritiro: {presaUniformata.pronto ? 'Pronto' : formatTime(presaUniformata.oraRitiro as string)}</div>
        </Card>;

        const mittente = <Card
            title="Mittente"
            size="small"
            className="prese-modal-card"
            headStyle={{ backgroundColor: '#fafafa' }}>
            <div className="drawer-body-rubrica-item-row drawer-body-rubrica-item-title">{presaUniformata.mittente.ragioneSociale}</div>
            <div className="drawer-body-rubrica-item-row">{presaUniformata.mittente.indirizzo}</div>
            <div className="drawer-body-rubrica-item-row">{presaUniformata.mittente.localita} {presaUniformata.mittente.cap} ({presaUniformata.mittente.provincia}) - {presaUniformata.mittente.codiceNazione}</div>
            <div className="drawer-body-rubrica-item-row"><PhoneOutlined /> {presaUniformata.mittente.telefono}</div>
            <div className="drawer-body-rubrica-item-row">Per conto di: {presaUniformata.mittente.perContoDi}</div>
            <div className="drawer-body-rubrica-item-row">Riferimento ritiro: {presaUniformata.mittente.riferimentoRitiro}</div>
            <div className="drawer-body-rubrica-item-row">Nome: {presaUniformata.mittente.nome}</div>
            <div className="drawer-body-rubrica-item-row">Orari: {formatTime(presaUniformata.mittente.orarioApertura1!)}-{formatTime(presaUniformata.mittente.orarioChiusura1!)} {presaUniformata.mittente.orarioContinuato ? '(continuato)' : formatTime(presaUniformata.mittente.orarioApertura2!) + '-' + formatTime(presaUniformata.mittente.orarioChiusura2!)}</div>
            <br />
            <div className="drawer-body-rubrica-item-row">Merce pericolosa (ADR): {presaUniformata.flagMercePericolosa ? 'Sì' : 'No'}</div>
            <div className="drawer-body-rubrica-item-row">Preavviso telefonico: {presaUniformata.flagPreavvisoTelefonico ? 'Sì' : 'No'}</div>
            <div className="drawer-body-rubrica-item-row">Sponda idraulica: {presaUniformata.flagSpondaIdraulica ? 'Sì' : 'No'}</div>
            <div className="drawer-body-rubrica-item-row">Note ritiro: {presaUniformata.noteRitiro}</div>
        </Card>;


        const d = presaUniformata.destinazioni[0];
        const destinatario = <Card
            title="Destinatario"
            size="small"
            className="prese-modal-card"
            headStyle={{ backgroundColor: '#fafafa' }}>
            <div className="drawer-body-rubrica-item-row drawer-body-rubrica-item-title">{d.ragioneSociale}</div>
            <div className="drawer-body-rubrica-item-row">{d.indirizzo}</div>
            <div className="drawer-body-rubrica-item-row">{d.localita} {d.cap} ({d.provincia}) - {d.codiceNazione}</div>
            <div className="drawer-body-rubrica-item-row"><PhoneOutlined /> {d.telefono}</div>
            <div className="drawer-body-rubrica-item-row">Persona di riferimento: {d.personaRiferimento}</div>
            <div className="drawer-body-rubrica-item-row">Preavviso telefonico: {d.flagPreavvisoTelefonico ? 'Sì' : 'No'}</div>
            <div className="drawer-body-rubrica-item-row">Sponda idraulica: {d.flagSpondaIdraulica ? 'Sì' : 'No'}</div>
            <div className="drawer-body-rubrica-item-row">Note consegna: {d.noteConsegna}</div>
            <div className="drawer-body-rubrica-item-row">Totale peso (kg): {d.pesoTotale}</div>
        </Card>;

        /*const altri_dati = <Card
            title="Altri dati"
            size="small"
            className="prese-modal-card"
            headStyle={{ backgroundColor: '#fafafa' }}>
            <div className="drawer-body-rubrica-item-row">Merce pericolosa (ADR): {presaUniformata.flagMercePericolosa ? 'Sì' : 'No'}</div>
            <div className="drawer-body-rubrica-item-row">Preavviso telefonico: {presaUniformata.flagPreavvisoTelefonico ? 'Sì' : 'No'}</div>
            <div className="drawer-body-rubrica-item-row">Sponda idraulica: {presaUniformata.flagSpondaIdraulica ? 'Sì' : 'No'}</div>
            <div className="drawer-body-rubrica-item-row">Note ritiro: {presaUniformata.noteRitiro}</div>
        </Card>;*/

        const imballi = <Card
            title="Imballi"
            size="small"
            className="prese-modal-card"
            headStyle={{ backgroundColor: '#fafafa' }}>
            {
                d.imballi.map(imb => {
                    const rowType = getPreseImballiRowType(imb);
                    const message = rowType.type?.toUpperCase() + ' - Qtà: ' + rowType.number + '; Dim. (cm): ' + (_.isEmpty(imb.larghezza?.toString()) ? 0 : imb.larghezza?.toString()) + 'x' + (_.isEmpty(imb.lunghezza?.toString()) ? 0 : imb.lunghezza?.toString()) + 'x' + (_.isEmpty(imb.altezza?.toString()) ? 0 : imb.altezza?.toString());
                    return (
                        <Alert message={message} type="info" style={{ marginTop: '5px' }} />
                    )
                })
            }
        </Card>;

        let message = <div style={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}>{dati_generali}{mittente}{/*altri_dati*/}{destinatario}{imballi}</div>;


        let alert = <></>
        if (result !== undefined && result.data !== undefined && result.data.messages?.length > 0) {
            alert = result.data.messages?.map((m: any) => <Alert message={m} type="warning" style={{ marginTop: '5px' }} />);
        }
        else {
            alert = <Alert message={'Non sono stati rilevati errori nella compilazione'} type="success" style={{ marginTop: '5px' }} />
        }
        return <>{message}{alert}</>;
    }
    const FINAL_STEP_str = React.useMemo(() => {
        return TranslateString("@FINAL_STEP")
    }, []);
    const CANCEL_str = React.useMemo(() => {
        return TranslateString("@TORNA_ALLA_PRESA")
    }, []);

    const checkPresa = (callback: () => void): void => {
        const presaUniformata = uniformaPresa(state.record);

        dispatch({ type: 'SETVALIDATOR', validator: setPreseValidField(presaUniformata) });
        const presaValidation = checkPreseValidField(setPreseValidField(presaUniformata));

        if (presaValidation.valid) {
            Modal.confirm({
                title: 'Confermare i dati della presa',
                icon: <ExclamationCircleOutlined />,
                content: checkPresaMessage(presaUniformata, undefined),
                okText: FINAL_STEP_str,
                cancelText: CANCEL_str,
                width: 600,
                className: 'prese-modal',
                onOk: () => { callback() }
            });
        }
        else {
            preseValidationErrorMessage(presaValidation.message);
        }
    }

    const handleChangeCodiceClienteFilter = (value: any) => {
        setFilters({ filtersSearch: filters.filtersSearch, customer: value, forceReload: filters.forceReload });
    }

    const handleChanges = (value: any) => {
        dispatch({ type: 'SETRECORD', value: value });
    }
    //END Modal create

    //Modal update
    const showUpdateModal = (value: PresaPortDTO, duplicate = false) => {
        RestCall.getById(RestUrl.PRESE_GET_BY_ID, value.id, 'url').then(
            result => {
                if (result.responseType === 'ok') {

                    let presaImballi: any[] = result.data.destinazioni[0].imballi;
                    let imballimap = presaImballi.map(
                        imb => {
                            const rowImb = getPreseImballiRowType(imb);
                            return ({ ...imb, numberGeneric: rowImb.number, rowType: rowImb.type })
                        }
                    );

                    let presaMappata = { ...result.data };
                    presaMappata.destinazioni[0].imballi = imballimap;


                    const newPresa = duplicate ?
                        { ...presaMappata, id: 0, numeroPresa: 0, stato: -1, operatorePortale: '', dataRitiro: '', oraRitiro: undefined, riferimentoFatturazione: '' } :
                        presaMappata;
                    dispatch({ type: 'SETSHOWUPDATE', value: newPresa, validator: PreseValidator.getDefault(), visible: true });
                }
                else {
                    notifyError(result);
                }
            }
        );

    }
    const handleUpdateModalOk = () => {
        const presaUniformata = uniformaPresa(state.record);
        dispatch({ type: 'SETVALIDATOR', validator: setPreseValidField(presaUniformata) });
        const presaValidation = checkPreseValidField(setPreseValidField(presaUniformata));
        if (presaValidation.valid) {
            if (presaUniformata.id === 0) {
                RestCall.post(presaUniformata, RestUrl.PRESE_POST_INSERT, undefined, 'url').then(
                    result => {
                        if (result.statusText === 'OK' || result.status === 200) {
                            notifySucces("@V_PRESE_PUT");
                            if (result.data.messages?.length > 0) {
                                switch (result.data.responseType) {
                                    case 'error':
                                        notifyError(result.data.messages.join('; '));
                                        break;
                                    default:
                                        notifyInfo(result.data.messages.join('; '));
                                        break;
                                }
                            }
                            dispatch({ type: 'SETVISIBLEUPDATE', visible: false });
                            loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
                        } else if (result === 'VALID_TOKEN') {
                            handleUpdateModalOk();
                        } else {
                            handleErrorResponse(result);
                        }
                    }
                );
            }
            else {
                RestCall.Put(presaUniformata, RestUrl.PRESE_PUT_MODIFY, undefined, 'url').then(
                    result => {
                        if (result.statusText === 'OK' || result.status === 200) {
                            notifySucces("@V_PRESE_PUT");
                            if (result.data.messages?.length > 0) {
                                switch (result.data.responseType) {
                                    case 'error':
                                        notifyError(result.data.messages.join('; '));
                                        break;
                                    default:
                                        notifyInfo(result.data.messages.join('; '));
                                        break;
                                }
                            }
                            dispatch({ type: 'SETVISIBLEUPDATE', visible: false });
                            loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
                        } else if (result === 'VALID_TOKEN') {
                            handleUpdateModalOk();
                        } else {
                            handleErrorResponse(result);
                        }
                    }
                );
            }
        }
        else {
            preseValidationErrorMessage(presaValidation.message);
        }
    }
    const handleUpdateModalCancel = () => {
        dispatch({ type: 'SETVISIBLEUPDATE', visible: false });
    }
    //END Modal update

    //Modal create
    const showCancelModal = (value: PresaPortDTO) => {
        dispatch({ type: 'SETRECORD', value: value });
        dispatch({ type: 'SETVISIBLEDELETE', visible: true });
    }


    const handleCancelModalCancel = (visible: boolean, record: any | undefined, type: 'ok' | '') => {
        dispatch({ type: 'SETVISIBLEDELETE', visible: visible });

        if (type === 'ok')
            loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
    }

    //END Modal create

    //Modal Tracking

    const showTrackingModal = (record: PresaPortDTO) => {
        dispatch({ type: 'SETSHOWTRACKING', record, tracking_visible: true });
        //  ChangeUrl('Tracking/' + value.idBolla );

    }
    const handleTrackingModalCancel = () => {
        dispatch({ type: 'SETSHOWTRACKING', record: state.record, tracking_visible: false });
    }
    //End Modal Tracking

    //Modal upload
    /*const showUploadModal = (value:PreseModel) => {
        dispatch({type:'SETRECORD',value:value});
        dispatch({type:'SETUPLOADVISIBLE',visible:true});
    }
    const handleUploadOk = () => {dispatch({type:'SETUPLOADVISIBLE',visible:false});}
    const handleUploadCancel = () => {dispatch({type:'SETUPLOADVISIBLE',visible:false});}
    const handleOnChange = (info : any) => {
        let fileList = [...info.fileList];
        // Accept 5 files only
        fileList = fileList.slice(-5);
        fileList.forEach(function (file, index) {
          let reader = new FileReader();
          reader.onload = (e:any) => {
            file.url = e.target.result;
          };
          reader.readAsDataURL(file.originFileObj);
        });
        return(fileList);
        
    }*/

    //END Modal upload

    /*___________________________________________________________________________________________*/
    //END MODAL HANDLER AND ACTIONS
    /*___________________________________________________________________________________________*/

    /*********************************************************************************************/
    //DESKTOP ACTIONS
    /*********************************************************************************************/

    //START filter handler
    const handleOrderByColumn = (columnName: string, ascORdesc: OrderType) => {//permette di effettuare l'ordinamento per colonna ASC/DESC
        dispatch({ type: 'SETORDERBYFILTER', orderby_filter: new Order(columnName, ascORdesc) });
    }
    const handleStringFilter = (f: string, columnName: string, operator: string) => { //gestisce i filtri delle colonne di tipo stringa
        dispatch({ type: 'SETFILTERWHERE', where_filter: new FilterStructureModel(columnName, operator, "String", f) });
    }
    const handleDateTimeFilter = (f: string, columnName: string, operator: string) => {//gestisce i filtri delle colonne di tipo datetime
        dispatch({ type: 'SETFILTERWHERE', where_filter: new FilterStructureModel(columnName, operator, "DateTime", f) });
    }
    //END filter handler

    const statoMap = (stato: any): { color: string, description: string } => {
        //non facciamo uno switch case perché ci sono degli stati intermedi che devono essere inglobati

        if (stato <= 2) {
            //"Caricata dal portale"
            return { color: 'green', description: TranslateString('@PRESE_STATO_CARICATA_DAL_PORTALE') };
        }

        if (stato <= 10) {
            //"In gestione"
            return { color: 'cyan', description: TranslateString('@PRESE_STATO_IN_GESTIONE') };
        }

        if (stato <= 20) {
            //"Assegnata"
            return { color: 'purple', description: TranslateString('@PRESE_STATO_ASSEGNATA') };
        }

        if (stato <= 30) {
            //"Confermata"
            return { color: 'blue', description: TranslateString('@PRESE_STATO_CONFERMATA') };
        }

        if (stato <= 60) {
            //"Caricata"
            return { color: 'lime', description: TranslateString('@PRESE_STATO_CARICATA') };
        }

        if (stato <= 90) {
            //"Rientrata"
            return { color: 'orange', description: TranslateString('@PRESE_STATO_RIENTRATA') };
        }

        if (stato === 95) {
            //"Richiesta annullamento"
            return { color: 'black', description: TranslateString('@PRESE_STATO_RICHIESTA_ANNULLAMENTO') };
        }

        if (stato === 100) {
            //"Annullata"
            return { color: 'red', description: TranslateString('@PRESE_STATO_ANNULLATA') };
        }

        return { color: '', description: '' };
    }

    const Columns = [
        {
            title: TranslateString('@NUMEROPRESA'),
            dataIndex: 'numeroPresa',
            key: 'numeroPresa',
            width: 80,
        },
        /*         {
                    title: TranslateString('@DATAPRESA'),
                    dataIndex: 'dataPresa',
                    key: 'dataPresa',
                    width: 100,
                    render: (record: any) => (FormateDate(record))
                }, */
        {



            title: TranslateString('@DATA_RITIRO'),
            dataIndex: 'dataRitiro',
            key: 'dataRitiro',
            width: 100,
            render: (record: any) => (FormateDate(record))
        },
        /*         {
                    title: TranslateString('@ORA_RITIRO'),
                    dataIndex: 'oraRitiro',
                    width: 100,
                    key: 'oraRitiro'
                }, */
        {
            title: TranslateString('@STATOPRESA'),
            dataIndex: '',
            key: 'stato',
            width: 150,
            render: (record: any) => {
                const s = statoMap(record.stato);
                return (<Tag color={s.color} key={'stato_' + record.id}>
                    {s.description}
                </Tag>);
            }
        },
        {
            title: TranslateString('@MITTENTE_RAGIONE_SOCIALE'),
            dataIndex: 'mittenteRagioneSociale',
            key: 'mittenteRagioneSociale'
        },
        {
            title: TranslateString('@DESTINAZIONE_RAGIONE_SOCIALE'),
            dataIndex: 'destinazioneRagioneSociale',
            key: 'destinazioneRagioneSociale'
        },
        {
            title: TranslateString('@PESO'),
            dataIndex: 'peso',
            key: 'peso',
            width: 100
        },
        {
            title: TranslateString('@QTA'),
            dataIndex: 'quantita',
            key: 'quantita',
            width: 100
        },
        {
            title: TranslateString('@AUTISTA'),
            dataIndex: 'autista',
            key: 'autista',
            width: 100
        },
        {
            title: TranslateString('@TARGA'),
            dataIndex: 'veicolo',
            key: 'veicolo',
            width: 100
        },
    ];

    /*const Columns = [        
        {
            title: <div className="container-row"><div className="column-7D8">{TranslateString('@FIRSTNAME')}</div><div  className="column-1D8">
            <ConteniToreFiltri onOrderBy={handleOrderByColumn} filterType="String"  
            columnName= {"firstname"} onFilter={handleStringFilter}></ConteniToreFiltri></div></div>,
            dataIndex:'firstname',
            key:'firstname',
        },
        {
            title: <div className="container-row"><div className="column-7D8">{TranslateString('@SURNAME')}</div><div  className="column-1D8">
            <ConteniToreFiltri onOrderBy={handleOrderByColumn} filterType="String"  
            columnName= {"surname"} onFilter={handleStringFilter}></ConteniToreFiltri></div></div>,
            dataIndex:'surname',
            key:'surname',
        },
        {
            title: <div className="container-row"><div className="column-7D8">{TranslateString('@BIRTHDATE')}</div><div  className="column-1D8">
            <ConteniToreFiltri onOrderBy={handleOrderByColumn} filterType="DateTime"  
            columnName= {"birthdate"} onFilter={handleDateTimeFilter}></ConteniToreFiltri></div></div>,
            dataIndex:'birthdate',
            key:'birthdate',
            render:(record:any) => (FormateDate(record)) 
        },
        {
            title: <div className="container-row"><div className="column-7D8">{TranslateString('@MAIL')}</div><div  className="column-1D8">
            <ConteniToreFiltri onOrderBy={handleOrderByColumn} filterType="String"  
            columnName= {"mail"} onFilter={handleStringFilter}></ConteniToreFiltri></div></div>,
            dataIndex:'mail',
            key:'mail',
        },
        {
            title: <div className="container-row"><div className="column-7D8">{TranslateString('@PHONENUMBER')}</div><div  className="column-1D8">
            <ConteniToreFiltri onOrderBy={handleOrderByColumn} filterType="String"  
            columnName= {"phonenumber"} onFilter={handleStringFilter}></ConteniToreFiltri></div></div>,
            dataIndex:'phonenumber',
            key:'phonenumber',
        },
        {
            title: <div className="container-row"><div className="column-7D8">{TranslateString('@STREET')}</div><div  className="column-1D8">
            <ConteniToreFiltri onOrderBy={handleOrderByColumn} filterType="String"  
            columnName= {"street"} onFilter={handleStringFilter}></ConteniToreFiltri></div></div>,
            dataIndex:'street',
            key:'street',
        },
        {
            title: <div className="container-row"><div className="column-7D8">{TranslateString('@DESCRIPTION')}</div><div  className="column-1D8">
            <ConteniToreFiltri onOrderBy={handleOrderByColumn} filterType="String"  
            columnName= {"description"} onFilter={handleStringFilter}></ConteniToreFiltri></div></div>,
            dataIndex:'description',
            key:'description',
        }
    ];*/

    const getPresaEditable = (stato: any): boolean => {
        return !(/*isBackoffice*/ store.getState().user_type === 'superuser' || store.getState().user_type === 'backoffice') && (stato === 2 || stato === -1); //inserita da portale o duplica o inserimento
    }

    const getPresaAnnullabile = (stato: any): boolean => {
        return !(/*isBackoffice*/ store.getState().user_type === 'superuser' || store.getState().user_type === 'backoffice') && stato <= 30; //ifino all' "in gestione"
    }

    const actions = [
        {
            title: "",
            key: 'action',
            fixed: RightFixed,
            width: "150px",
            render: (text: any, record: any) => {

                const isEditable = getPresaEditable(record.stato);
                const isCancelable = getPresaAnnullabile(record.stato);
                return (
                    <div className="container-col">
                        <div className="container-row c-ctr">
                            {
                                !(/*isBackoffice*/ store.getState().user_type === 'superuser' || store.getState().user_type === 'backoffice') ?
                                    <>
                                        <Button
                                            className={isEditable ? "button-modify" : "normal-background-ghost"}
                                            title={TranslateString(isEditable ? "@MODIFY" : "@SHOW")}
                                            style={{ marginRight: "5px" }}
                                            type={isEditable ? "primary" : undefined}
                                            shape="round"
                                            size={'small'}
                                            icon={isEditable ? <EditOutlined /> : <EyeOutlined />}
                                            onClick={() => showUpdateModal(record)}
                                        />
                                        <Button
                                            className={"normal-background-ghost"}
                                            title={TranslateString("@DUPLICATE")}
                                            style={{ marginRight: "5px" }}
                                            shape="round"
                                            size={'small'}
                                            icon={<CopyOutlined />}
                                            onClick={() => showUpdateModal(record, true)}
                                        />
                                        <Button
                                            className={isCancelable ? "red-background-ghost" : "gray-background-ghost"}
                                            title={TranslateString("@DELETE")}
                                            shape="round"
                                            size={'small'}
                                            icon={<DeleteOutlined />}
                                            onClick={() => isCancelable ? showCancelModal(record) : notifySucces("Non è possibile annullare la presa")}
                                        />
                                    </> :
                                    <Button
                                        className="normal-background-ghost"
                                        title={TranslateString("@SHOW")}
                                        style={{ marginRight: "5px" }}
                                        shape="round"
                                        size={'small'}
                                        icon={<EyeOutlined />}
                                        onClick={() => showUpdateModal(record)}
                                    />
                            }

                        </div>
                    </div>
                );
            },
        }
    ];
    /*___________________________________________________________________________________________*/
    //END DESKTOP ACTIONS
    /*___________________________________________________________________________________________*/



    /*********************************************************************************************/
    //MOBILE ACTIONS 
    /*********************************************************************************************/

    /*const handleOrderBy = (columnName: string, ascORdesc: OrderType) => {//handle the order by of the mobile portal
        handleMobileFilterVisible(false);
    }
    const handleFilter = (f: string, col: string, type: string) => {//handle the filter by of the mobile portal
        handleMobileFilterVisible(false);
    } */

    const handleAction = (record: any): any[] => {
        return [
            <Button
                className="button-modify"
                title={TranslateString("@MODIFY")}
                style={{ marginRight: "5px" }}
                type="primary"
                shape="round"
                size={'small'}
                icon={<EditOutlined />}
                onClick={() => showUpdateModal(record)}
            />,
            <Button
                className="red-background-ghost"
                title={TranslateString("@DELETE")}
                shape="round"
                size={'small'}
                icon={<DeleteOutlined />}
                onClick={() => showCancelModal(record)}
            />
        ];
    }

    const handleFabScrollVisibility = () => {
        if (timer !== undefined) {
            clearTimeout(timer);
        }
        document.getElementById('FAB_COMPONENT')!.style.opacity = '0';
        if (document.getElementById('FAB_COMPONENT')!.style.display === 'flex') {
            setTimeout(() => {
                document.getElementById('FAB_COMPONENT')!.style.display = 'none';
            }, 200);
        }
        timer = setTimeout(() => {
            document.getElementById('FAB_COMPONENT')!.style.display = 'flex';
            setTimeout(() => {
                document.getElementById('FAB_COMPONENT')!.style.opacity = '1';
            }, 100);
        }, 250);
    }
    /*___________________________________________________________________________________________*/
    //END MOBILE ACTIONS
    /*___________________________________________________________________________________________*/

    const handleChangesFilter = (event: React.ChangeEvent<HTMLInputElement>) => {//gestisce i cambiamenti di stato che avvengono nelle textbox della form
        const target = event.target;
        const value = target.value;//contiene il nuovo valore della textbox
        const name = target.name;//contiene il nome del valore così potrà essere cambiato autonomamente, come si vede nel passaggio sottostante con [name]
        setFilters(
            {
                filtersSearch: {
                    ...filters.filtersSearch,
                    [name]: value
                },
                customer: filters.customer,
                forceReload: filters.forceReload
            }
        );
    }

    const handleChangesFilterStato = (checked: boolean) => {
        setFilters(
            {
                filtersSearch: {
                    ...filters.filtersSearch,
                    stato: checked ? undefined : PreseSearchModelFilterStateDefault //lo stato caricata, undefined li vede tutti
                },
                customer: filters.customer,
                forceReload: filters.forceReload
            }
        );
    }
    const handleChangesFilterClear = (value: any) => {
        setFilters({ filtersSearch: PreseSearchModel.getDefault(), customer: 'ALL', forceReload: Date.now().toString() });
    }
    const onBlurFilter = () => {
        loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
    }
    const onKeyPressFilter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter')
            loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize);
    }
    const handlePagination = (page: number | undefined, page_size?: number | undefined) => {//gestisce la paginazione
        dispatch({ type: 'SETPAGINATION', page: page, pageSize: page_size });
        loadtable(state.where_filter, state.orderby_filter, page, page_size);
    }
    //START filter handler
    const handleFilter = (value: string, sql_name: string, operator: string, filter_type: SITableFilterTypeValues) => { //gestisce i filtri delle colonne di tipo stringa
        let filtro = filters;

        if (sql_name === 'stato' && value === '') {
            setFilters(current => {
                const copy = { ...filters };

                delete copy.filtersSearch['stato'];

                return copy;

            });
            filtro = filters;
        } else {

            filtro = {
                filtersSearch: {
                    ...filters.filtersSearch,
                    [sql_name]: (sql_name === 'nrPresa') ? +value : value
                },
                customer: filters.customer,
                forceReload: filters.forceReload
            };
        }


        setFilters(filtro);

        loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize, filtro);

        //dispatch({ type: 'SETFILTERWHERE', where_filter: new FilterStructureModel(sql_name, operator, filter_type, value) });
    }
    const handleOrderBy = (order_by: SITableOrder) => {//permette di effettuare l'ordinamento per colonna ASC/DESC
        dispatch({ type: 'SETORDERBYFILTER', orderby_filter: new Order(order_by.table_View_ColumnName, order_by.mode) });
    }
    //END filter handler
    const handleDataChanges = (field: string, event: any, value?: any) => {
        const newDate = event !== null ? (event as Moment).format('YYYY-MM-DD[T]HH:mm:ss') : '';

        setFilters(
            {
                filtersSearch: {
                    ...filters.filtersSearch,
                    [field]: newDate
                },
                customer: filters.customer,
                forceReload: Date.now().toString()
            }
        );
    }
    const onOpenFile = (record: any) => {
        setWait(true);
        let nomefile: string = record.id.toString() + "_";
        nomefile += ((record.ragioneSocialeMittente !== undefined && record.ragioneSocialeMittente !== null) ? record.ragioneSocialeMittente.replace(' ', '_').toString() : "") + ".pdf";
        RestCall.downloadPdf({}, RestUrl.PRESE_GETFILE + "?idPresa=" + record.id, 'ok', 'url', nomefile).then(
            result => {
                if (typeof result === 'string') {
                    if (result === 'VALID_TOKEN') {
                        onOpenFile(record);
                    } else if (result !== 'ok') {
                        notifyError("@NOTFOUND");
                    }
                } else {
                    notifyError("@NOTFOUND")
                }
                setWait(false);
            }
        );
    }


    return (
        <>
            <div className="container-col desktop-flex height-100">
                <div className="scrollable-calc">
                    <div className="desktopBody">
                        <div className="container-row">
                            {/*                             <div className="column-1D6 container-col" style={{ marginRight: '50px' }}>
                                <FormTextInput_old
                                    onChange={handleChangesFilter}
                                    label_name={"NUMEROPRESA"}
                                    mandatory={false}
                                    input_name={"nrPresa"}
                                    validate={undefined}
                                    input_value={filters.filtersSearch.nrPresa}
                                    onBlur={onBlurFilter}
                                    onKeyPress={onKeyPressFilter}

                                />
                            </div>
                            <div className="column-1D6 container-col margin-right-5">
                                <FormTextInput_old
                                    onChange={handleChangesFilter}
                                    label_name={"MITTENTE_RAGIONE_SOCIALE"}
                                    mandatory={false}
                                    input_name={"ragioneSocialeMittente"}
                                    validate={undefined}
                                    input_value={filters.filtersSearch.ragioneSocialeMittente}
                                    onBlur={onBlurFilter}
                                    onKeyPress={onKeyPressFilter}
                                />
                            </div>
                            <div className="column-1D6 container-col margin-right-5">
                                <FormTextInput_old
                                    onChange={handleChangesFilter}
                                    label_name={"DESTINAZIONE_RAGIONE_SOCIALE"}
                                    mandatory={false}
                                    input_name={"ragioneSocialeDest"}
                                    validate={undefined}
                                    input_value={filters.filtersSearch.ragioneSocialeDest}
                                    onBlur={onBlurFilter}
                                    onKeyPress={onKeyPressFilter}
                                />
                            </div> */}

                            <div className="column-1D8 container-col margin-right-5">
                                <FormDataPickerInput

                                    onChangeDataPicker={(event: any, value?: any) => handleDataChanges('daData', event, value)}
                                    label_name="DATA_RITIRO_DA"
                                    input_name={"daData"}
                                    date_value={filters.filtersSearch.daData} validate={undefined} mandatory={false} />
                            </div>
                            <div className="column-1D8 container-col margin-right-5">
                                <FormDataPickerInput

                                    onChangeDataPicker={(event: any, value?: any) => handleDataChanges('aData', event, value)}
                                    label_name="DATA_RITIRO_A"
                                    input_name={"aData"}
                                    date_value={filters.filtersSearch.aData} validate={undefined} mandatory={false}
                                />
                            </div>
                            <div className="column-1D8   margin-right-5">
                                <div className="column-1">
                                    <label className="label-font" htmlFor={'stato'}>{TranslateString("@STATOPRESA")}</label>
                                </div>
                                <Switch checked={filters.filtersSearch.stato === undefined} onChange={handleChangesFilterStato} checkedChildren={TranslateString("@PRESE_ALL")} unCheckedChildren={TranslateString("@PRESE_SOLO_ATTIVE")} />
                            </div>

                            <div className="column-1D8 container-col margin-right-5">
                                {(/*isBackoffice*/ store.getState().user_type === 'superuser' || store.getState().user_type === 'backoffice') ?
                                    <>
                                        <div className="column-1">
                                            <label className="label-font" htmlFor={'filterCustomer'}>Cliente</label>
                                            <label className="label-font label-red" htmlFor={'filterCustomer'}> *</label>
                                        </div>
                                        <Select
                                            showSearch
                                            style={{ width: 300 }}
                                            placeholder={'Seleziona il ciente'}
                                            optionFilterProp="children"
                                            onChange={handleChangeCodiceClienteFilter}
                                            value={filters.customer}
                                            defaultValue={'ALL'}
                                        >
                                            <Option key={'ALL'} value={'ALL'}>TUTTI</Option>
                                            {
                                                codiceClienteList.map(
                                                    (item: any) =>
                                                        <Option key={item.codiceCliente} value={item.codiceCliente}>
                                                            {item.codiceCliente + ' - ' + item.clienteMetodo?.ragioneSociale}
                                                        </Option>
                                                )
                                            }
                                        </Select>
                                    </> : <></>
                                }
                            </div>



                            <div style={{ alignSelf: 'center' }}>
                                {(/*isBackoffice*/ store.getState().user_type === 'superuser' || store.getState().user_type === 'backoffice') ? <></> :
                                    <Button className="table-new-button" style={{ width: '150px', marginLeft: '5px' }} onClick={showCreateModal}><PlusOutlined />{TranslateString("@NEW_PRESE")}</Button>
                                }
                                <Button className="table-reload-button" style={{ width: '150px', marginLeft: '5px' }} onClick={() => loadtable(state.where_filter, state.orderby_filter, state.page, state.pageSize)} ><ReloadOutlined />{TranslateString("@RELOAD_TABLE")}</Button>
                                <Button className="table-reload-button" style={{ width: '150px', marginLeft: '5px', marginRight: '5px' }} onClick={handleChangesFilterClear}><ClearOutlined />{TranslateString("@CLEAR")}</Button>
                            </div>
                        </div>




                    </div>

                    <div className="height-100-70">
                        <div className="si-table-container height-100 width-100">
                            {user_type !== undefined &&
                                <SITableVirtualizedComponent
                                    id_univoco={"prese_filter_transfilm"}
                                    page={state.page}
                                    page_size={state.pageSize}
                                    totalcount={state.totalcount}
                                    data_source={state.data_source}
                                    columns={PreseColumns(handleOrderBy, handleFilter, showUpdateModal, showUpdateModal, showCancelModal, showTrackingModal, onOpenFile, user_type)}
                                    filters={undefined}
                                    bordered={true}
                                    onPagination={handlePagination}
                                    select_rows={undefined}
                                    onFilterChange={() => { }}
                                    onShiftColumn={() => { }}
                                    loading={loading}
                                    colorRowClick={'#18D5FF'}
                                    onRowClass={(record: any, index) => {
                                        if (index % 2 == 0) {
                                            return " light-gray "
                                        } else {
                                            return " "
                                        }
                                    }}
                                />
                            }
                        </div>
                    </div>
                </div >
            </div >

            {/**********************************************************************************************
            * *********************************************************************************************
            * *********************************************************************************************
            //                                       MOBILE HTML 
            /***********************************************************************************************
             ***********************************************************************************************
             ***********************************************************************************************/}
            {/*<div className="c-ctr mobile-flex height-100">
                <div className="container-col width-100 height-100 scroll-visible">
                    <MobileFilter
                        visible={state.filter_display}
                        id={"FilterAnagrafica"}
                        onFilter={handleFilter}
                        onOrderBy={handleOrderBy}
                        sqlNames={[new ColumnFilterMobile('nome', 'String'), new ColumnFilterMobile('cognome', 'String')]}
                    />
                    <div id={'ManiAnagrafica'} style={{ display: state.filter_display ? 'none' : 'block' }} className={state.filter_visible ? 'no-animate-left height-100' : 'animate-left height-100'}>
                        <div className="container-col c-jtf-c padding-bottom-15 height-100 scroll-visible"
                            onScroll={() => { handleFabScrollVisibility() }}
                        >
                            {
                                state.data_source?.map(
                                    (item: PresaListModel) => (
                                        <MobileCard
                                            actions={handleAction(undefined)}
                                            title={item.mittenteRagioneSociale}
                                            visible_props={['birthdate']}
                                            value={item}
                                            image={''}
                                        />)
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>


            <FabComponent
                filter_visible onFilter={() => handleMobileFilterVisible(!state.filter_visible)}
                reload_visible onReload={() => { }}
                add_popup_name="NEW_PRESE" add_visible onAddRecord={showCreateModal}
                        />*/}
            {/**********************************************************************************************
            * *********************************************************************************************
            * *********************************************************************************************
            //                                  END MOBILE HTML 
            /***********************************************************************************************
             ***********************************************************************************************
             ***********************************************************************************************/}

            {
                state.create_visible &&
                <Modal
                    wrapClassName="mobile-large-modal"
                    title={TranslateString("@NEW_PRESE")}
                    visible={state.create_visible}
                    onOk={() => checkPresa(handleModalOk)}
                    onCancel={handleModalCancel}
                    footer={!wait ? undefined : wait}
                    closeIcon={<LeftOutlined className="mobile-body" />}
                    keyboard={false}
                    maskClosable={false}
                    width="1000px"
                    okText={TranslateString("@SAVE")}
                >
                    <PreseForm record={state.record} onChange={handleChanges} />
                </Modal>
            }
            {
                state.update_visible &&
                <Modal
                    wrapClassName="mobile-large-modal"
                    title={state.record.id === 0 ? TranslateString("@NEW_PRESE") : TranslateString(getPresaEditable(state.record.stato) ? "@UPDATE_PRESE" : "@SHOW_PRESE") + ': ' + state.record.numeroPresa + '/' + state.record.esercizio}
                    visible={state.update_visible}
                    onOk={() => checkPresa(handleUpdateModalOk)}
                    onCancel={handleUpdateModalCancel}
                    closeIcon={<LeftOutlined className="mobile-body" />}
                    keyboard={false}
                    maskClosable={false}
                    width="1000px"
                    okText={TranslateString("@SAVE")}
                    footer={getPresaEditable(state.record.stato) ? undefined : <Button onClick={handleUpdateModalCancel} >{TranslateString("@CLOSE")}</Button>}
                >
                    <PreseForm readonly={getPresaEditable(state.record.stato) ? undefined : true} record={state.record} onChange={handleChanges} />
                </Modal>
            }
            {/*
                state.delete_visible &&
                <Modal
                    wrapClassName="modal-s"
                    title={TranslateString("@DELETE_PRESE") + ': ' + state.record.numeroPresa}
                    visible={state.delete_visible}
                    onOk={handleCancelModalOk}
                    onCancel={handleCancelModalCancel}
                    closeIcon={<LeftOutlined className="mobile-body" />}
                    keyboard={false}
                    maskClosable={false}
                    width="400px"
                >
                    {TranslateString("@ARE_YOU_SURE")}
                </Modal>*/
            }
            {state.delete_visible &&
                <PreseCancelModal
                    visible={state.delete_visible}
                    record={state.record}
                    onChangeVisibility={handleCancelModalCancel}
                />
            }
            {/*state.upload_visible&&
                <Modal
                wrapClassName="modal-m"
                title= {TranslateString("@UPLOAD_FILE_PRESE")}
                visible={state.upload_visible}
                onOk={handleUploadOk}
                onCancel={handleUploadCancel}
                closeIcon={<LeftOutlined className="mobile-body"/>}
                keyboard={false}
                maskClosable={false}
                width="600px"
            >
                <DragUpload onChange={handleOnChange} />
                   
            </Modal>*/
            }

            {
                state.tracking_visible &&
                <Modal
                    //wrapClassName="mobile-large-modal"
                    wrapClassName="modal-m"
                    title={TranslateString("@TRACKING")}
                    visible={state.tracking_visible}
                    onOk={handleTrackingModalCancel}
                    onCancel={handleTrackingModalCancel}
                    closeIcon={<LeftOutlined className="mobile-body" />}
                    keyboard={false}
                    maskClosable={false}
                    width="1500px"
                    //okText={TranslateString("@SAVE")}
                    //footer={getPresaEditable(state.record.stato) ? undefined : <Button onClick={handleUpdateModalCancel} >{TranslateString("@CLOSE")}</Button>}
                >
                    <TrackingList idBolla={state.record.idBolla} />
                </Modal>
            }

        </>
    );
}