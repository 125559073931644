import { Alert } from "antd"
import React from "react";
import store from "../Redux/Store";
import { Link } from "react-router-dom";

import { SearchShippingComponent } from "./SearchShippingComponent";

export const SearchShipping = () => {

    const [isLogged] = React.useState(store.getState().isLogged);

    return <>
        <div className={isLogged ? " " : 'backgroundIMG'} style={{ height: isLogged ? "100%" : "100vh" }}>
            {isLogged ? <SearchShippingComponent />
                : <div
                    className="mainLogin container-col c-ctr"
                    style={{
                        maxWidth: isLogged ? "100%" : "1200px",
                        margin: "auto",
                        padding: "2rem"
                    }}
                >
                    <div className={isLogged ? " " : 'logoIMG'} />
                    <SearchShippingComponent />
                    {!isLogged && <Alert
                        style={{ marginTop: 20 }}
                        description={<>Se sei già registato al portale <Link to={"/"}>Accedi qui!</Link></>}
                        type="info"
                        showIcon
                    />}
                </div>}
        </div>
    </>
}