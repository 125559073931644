import { SITableFilterController, SITableFilterTypeValues } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterController';
import { SITableOrder } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterModel';
import { SITableColumnStructure } from "@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableModels";
import { TranslateString } from "../../i18n/translate";
import { Button, Tag } from 'antd';
import {
  CheckOutlined, EditOutlined, DeleteOutlined
} from '@ant-design/icons';


export const RubricaColumns = (
  onOrderBy: (order_by: SITableOrder) => void,
  onFilter: (value: string, sql_name: string, operator: string, filter_type: SITableFilterTypeValues) => void,
  showUpdate: (row: any) => void,
  showDelete: (row: any) => void,
): any[] => {

  const columns: SITableColumnStructure[] = [
    {
      title: <SITableFilterController
        sql_name={"isDefault"}
        alias={''}
      //onOrderBy={onOrderBy}
      /* filter_type={'Number'}
      onSearch={onFilter} */
      />,
      key: 'isDefault',
      column_type: 'Number',
      dataIndex: 'isDefault',
      width: 40,
      render: (value: boolean, record: any) =>
      (
        value ? <CheckOutlined title='Default' /> : <></>
      )
    },
    {
      title: <SITableFilterController
        sql_name={"id"}
        alias={'ID'}
        onOrderBy={onOrderBy}
        filter_type={'Number'}
        onSearch={onFilter}
        titleAlign='end'
      />,
      key: 'id',
      column_type: 'Number',
      dataIndex: 'id',
      width: 110,
      padding: "0",
      render: (record: any) => (<div className="container-row c-ctrbottom-b padding-right-10 ">{record}</div>)
    },
    {
      title: <SITableFilterController
        sql_name={"ragioneSociale"}
        alias={TranslateString('@RAGSOC')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
        titleAlign='center'
      />,
      key: 'ragioneSociale',
      column_type: 'String',
      dataIndex: 'ragioneSociale',
      width: 220,
      padding: "0",
      //render: (record: string) => (<div className="container-row c-ctr ">{FormateDateWith00(record)}</div>)
    },
    {
      title: <SITableFilterController
        sql_name={"perContoDi"}
        alias={TranslateString('@PRESSO')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
        titleAlign='center'
      />,
      key: 'perContoDi',
      column_type: 'String',
      dataIndex: 'perContoDi',
      width: 220,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"indirizzo"}
        alias={TranslateString('@INDIRIZZO')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      />,
      key: 'indirizzo',
      column_type: 'String',
      dataIndex: 'indirizzo',
      width: 200,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"localita"}
        alias={TranslateString('@COMUNE')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      />,
      key: 'localita',
      column_type: 'String',
      dataIndex: 'localita',
      width: 200,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"provincia"}
        alias={'Prov'}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
        titleAlign='center'
      />,
      key: 'provincia',
      column_type: 'String',
      dataIndex: 'provincia',
      width: 70,
      padding: "0",
      cellClassName: 'container-row c-ctr'
    },
    {
      title: <SITableFilterController
        sql_name={"cap"}
        alias={TranslateString('@CAP')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
        titleAlign='center'
      />,
      key: 'cap',
      column_type: 'String',
      dataIndex: 'cap',
      width: 70,
      padding: "0",
      cellClassName: 'container-row c-ctr'
    },
    {
      title: <SITableFilterController
        sql_name={"telefono"}
        alias={TranslateString('@TELEFONO')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      />,
      key: 'telefono',
      column_type: 'String',
      dataIndex: 'telefono',
      width: 120,
      padding: "0",
    },
    {
      title: <SITableFilterController
        sql_name={"mail"}
        alias={TranslateString('@MAIL')}
        onOrderBy={onOrderBy}
        filter_type={'String'}
        onSearch={onFilter}
      />,
      key: 'mail',
      column_type: 'String',
      dataIndex: 'mail',
      width: 120,
      padding: "0",
    },

    {
      title: <SITableFilterController
        sql_name={""}
        alias={"Action"}
        titleAlign='center'
      />,
      key: 'action',
      column_type: 'String',
      fixed: 'right',
      dataIndex: 'action',
      width: 110,
      padding: "0",
      render: (text: any, record: any) => {
        return (
          <div className="container-col">
            <div className="container-row c-ctr">
              <Button
                className="button-modify"
                title={TranslateString("@MODIFY")}
                style={{ marginRight: "5px" }}
                type="primary"
                shape="round"
                size={'small'}
                icon={<EditOutlined />}
                onClick={() => showUpdate(record)}
              />
              <Button
                className="red-background-ghost"
                title={TranslateString("@DELETE")}
                shape="round"
                size={'small'}
                icon={<DeleteOutlined />}
                onClick={() => showDelete(record)}
              />
            </div>
          </div>
        );
      },
    }

  ];

  return (columns);
}

