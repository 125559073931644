import { Redirect, Route, Switch } from "react-router-dom"
import { SearchShipping } from "../CercaSpedizione/SearchShipping"
import { Login } from "../login/Login"
import { PreventiviPage } from "../Preventivi/PreventiviPage";
interface RoutesNoAuthProps {

}
export const RoutersNoAuth: React.FC<RoutesNoAuthProps> = () => {
    return (
        <Switch>
            <Route exact path="/CercaSpedizione" component={() => <SearchShipping />} />
            <Route path="/ConfermaPreventivo/:guid" component={() => <PreventiviPage  />} />
            <Route exact path="/" component={() => <Login />} />
            <Route component={() => <Redirect to={"/"} />} />
        </Switch>
    );
}