import '../../CustomCss/LayoutCss/Layout.scss';
import {isMobile,isTablet} from 'react-device-detect';
import { ReactElement } from 'react';
import { DeskTopSider, MenuProps } from './DesktopMenu';
import { MobileMenu } from './MobileMenu';
import React from 'react';


export const Structure:React.FC<MenuProps> = props =>  {

  const handleMobile = (): ReactElement =>{

    /*if(isMobile || isTablet){
      return <MobileMenu />
    }else{
      return(
      <DeskTopSider />
    );
    }*/
    return <DeskTopSider />
  }
  
  return(
    handleMobile()
  );

  }