import { Button, Modal, Table } from 'antd';
import React from 'react';
import {
    PlusOutlined,
    ReloadOutlined,
    LeftOutlined,
    EditOutlined,
    DeleteOutlined,
    CheckOutlined
} from '@ant-design/icons';
import { notifyError, notifySucces } from '../CustomToast/customToast';
import { RubricaItemModel } from '../Model/RubricaModel';
import translate, { TranslateString } from '../../i18n/translate';
import { RightFixed } from '../AntDesignType';
import { RubricaForm } from './RubricaForm';
import { RubricaProps } from './RubricaInterface';
import { RubricaReducer } from './RubricaType';
import { RestCall } from '../Rest/Rest_Call/RestCall';
import RestUrl from '../../RestUrl.json';
import { Checked } from '../Utils_Component/CheckedBoolean';
import { checkOrariApertura, formatTime } from '../Utils_Component/FormatDate';
import { SITableVirtualizedComponent } from '@si/si-react-components/dist/esm/components/SITable/SITableVirtualized/SITableVirtualizedComponent';
import { RubricaColumns } from './RubricaColumns';
import { SITableFilterTypeValues } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterController';
import { SITableOrder } from '@si/si-react-components/dist/esm/components/SITable/SITableCommon/Header/SITableFilterModel';
import { FilterModel, FilterStructureModel, Order } from '../Model/FilterModel';

var _ = require('lodash');

export const RubricaList: React.FC<RubricaProps> = props => {
    const [loading, setLoading] = React.useState(false);

    const [state, dispatch] = React.useReducer(RubricaReducer, {
        create_visible: false,
        update_visible: false,
        delete_visible: false,
        validator: undefined,
        rubricaItem: RubricaItemModel.getDefault(),
        rubrica: [],
        page: 1,
        page_size: 10,
        totalcount: 100,
        columnsSelected: [],
        where_filter: [],
        orderby_filter: new Order("", "ASC"),
        first_call: true,
    });
    React.useEffect(
        () => {
            load();
        }, []
    );


    React.useEffect(
        () => {
            if (state.first_call !== true) {
                load();
                dispatch({ type: 'SETPAGINATION', page: undefined, page_size: state.page_size });
            }
        }, [state.where_filter]
    );
    React.useEffect(
        () => {
            if (state.first_call !== true) {
                load();
                dispatch({ type: 'SETPAGINATION', page: undefined, page_size: state.page_size });
            }
        }, [state.orderby_filter]
    );
    const load = () => {
        loadtable(state.where_filter, state.orderby_filter, undefined, state.page_size);
    }

    const loadtable = (where_filter: FilterStructureModel[], orderby_filter: Order, page: number | undefined, page_size: number | undefined) => {
        const filter = new FilterModel(orderby_filter, where_filter, [])

        if (props.type === 'mittenti') {
            setLoading(true);
            RestCall.postWithResultNoPaginationEasyAccess(filter, RestUrl.RUBRICA_MITTENTE_POST, 'url',
                (result: any, header) => {
                    dispatch({ type: 'SETRUBRICA', value: result });
                    setLoading(false);
                },
                (message) => {
                    notifyError(message);
                    setLoading(false);
                }
            );

        } else {
            //RestCall.getAll(props.type === 'mittenti' ? RestUrl.RUBRICA_MITTENTE_GET_ALL : RestUrl.RUBRICA_DESTINATARIO_GET_ALL, 'url').then(
            RestCall.getAll(RestUrl.RUBRICA_DESTINATARIO_GET_ALL, 'url').then(
                result => {
                    if (typeof result !== 'string' && typeof result !== 'undefined') {
                        if (typeof result.data !== 'string' && typeof result.data !== 'undefined') {
                            dispatch({ type: 'SETRUBRICA', value: result.data });
                        }
                    } else if (typeof result === 'string') {
                        if (result === "VALID_TOKEN") {
                            loadtable(where_filter, orderby_filter, page, page_size);
                        } else {
                            notifyError(result);
                        }
                    } else {
                        notifyError("@UNHANDLE_ERROR");
                    }
                }
            );
        }
    }
    const handleChanges = (value: RubricaItemModel) => { //handle the changes done inside the modal
        dispatch({ type: 'SETRUBRICAITEM', payload: value });
    }
    //Modal create a new record
    const showCreateModal = () => {
        dispatch({ type: 'SETVALIDATOR', validator: undefined });
        dispatch({ type: 'SETVISIBLECREATE', visible: true, value: RubricaItemModel.getDefault() });
    }
    const ControllaCampi = () => {
        
        const checkOrari = checkOrariApertura(state.rubricaItem.orarioApertura1, state.rubricaItem.orarioChiusura1, state.rubricaItem.orarioApertura2, state.rubricaItem.orarioChiusura2, state.rubricaItem.orarioContinuato);
        if (!checkOrari) {
            notifyError("@ERR_INSER_ORARI");
            return false;
        }

        if ((typeof state.rubricaItem.ragioneSociale === 'undefined') || (state.rubricaItem.ragioneSociale === '')) {
            notifyError("@ERR_INSER_RAGIONE_SOCIALE");
            return false;
        }
        if ((typeof state.rubricaItem.indirizzo === 'undefined') || (state.rubricaItem.indirizzo === '')) {
            notifyError("@ERR_INSER_INDIRIZZO");
            return false;
        }
        if ((typeof state.rubricaItem.provincia === 'undefined') || (state.rubricaItem.provincia === '')) {
            notifyError("@ERR_INSER_PROVINCIA");
            return false;
        }
        if ((typeof state.rubricaItem.cap === 'undefined') || (state.rubricaItem.cap === '')) {
            notifyError("@ERR_INSER_CAP");
            return false;
        }
        if ((typeof state.rubricaItem.localita === 'undefined') || (state.rubricaItem.localita === '')) {
            notifyError("@ERR_INSER_LOCALITA");
            return false;
        }
        if ((typeof state.rubricaItem.telefono === 'undefined') || (state.rubricaItem.telefono === '')) {
            notifyError("@ERR_INSER_TELEFONO");
            return false;
        }



        return true;
    }
    const handleCreate = () => {
        if (!ControllaCampi())
            return;
        //devo fare questo perché altrienti devo dichiarare gli orari come string|undefined. Lo forzo solo allac reazione, tanto in modifica sono già null perhcé scendono dal server        
        const normalized =
            props.type === 'mittenti' ?
                state.rubricaItem :
                {
                    ...state.rubricaItem,
                    orarioApertura1: undefined,
                    orarioChiusura1: undefined,
                    orarioApertura2: undefined,
                    orarioChiusura2: undefined
                }
        RestCall.post(normalized, props.type === 'mittenti' ? RestUrl.RUBRICA_MITTENTE_POST_INSERT : RestUrl.RUBRICA_DESTINATARIO_POST_INSERT, "ok", 'url').then(
            result => {
                if (result === "ok") {
                    load();
                    dispatch({ type: "SETVISIBLECREATE", visible: false });
                    notifySucces("@V_ITEM_INSERT");
                } else {
                    if (result === "VALID_TOKEN") {
                        handleCreate();
                    } else {
                        notifyError(result);
                    }
                }
            }
        );

    }
    const handleCreateCancel = () => {
        dispatch({ type: 'SETVISIBLECREATE', visible: false });
    }
    //END Modal create a new record    

    //Modal delete a record (it's a logical delete, the data will not be deleted in the database)
    const showDelete = (value: any) => {
        dispatch({ type: 'SETVISIBLEDELETE', visible: true });
        dispatch({ type: 'SETRUBRICAITEM', payload: value });
    }
    const handleDelete = () => {
        RestCall.DeleteByID(props.type === 'mittenti' ? RestUrl.RUBRICA_MITTENTE_CANCEL : RestUrl.RUBRICA_DESTINATARIO_CANCEL, state.rubricaItem.id, 'ok', 'url').then(
            result => {
                if (result === 'ok') {
                    notifySucces("@V_DELETE_ITEM");
                    dispatch({ type: 'SETVISIBLEDELETE', visible: false });
                    load();
                } else if (result === 'VALID_TOKEN') {
                    handleDelete();
                } else if (typeof result === 'string') {
                    notifyError(result);
                }
            }
        );
    }
    const handleDeleteCancel = () => {
        dispatch({ type: 'SETVISIBLEDELETE', visible: false });
    }
    //END Modal delete

    //Modal update a record
    const showUpdate = (value: any) => {
        dispatch({ type: 'SETRUBRICAITEM', payload: value });
        dispatch({ type: 'SETVALIDATOR', validator: undefined });
        dispatch({ type: "SETVISIBLEUPDATE", visible: true });
    }
    const handleUpdate = () => {
        if (!ControllaCampi())
            return;

        let newrecord = {
            ...state.rubricaItem,
        };

        if (state.rubricaItem.orarioApertura1 === '') {
            newrecord = {
                ...newrecord,
                orarioApertura1: null,
            };
        }

        if (state.rubricaItem.orarioApertura1 === '') {
            newrecord = {
                ...newrecord,
                orarioChiusura1: null,
            };
        }

        if (state.rubricaItem.orarioApertura2 === '') {
            newrecord = {
                ...newrecord,
                orarioApertura2: null,
            };
        }

        if (state.rubricaItem.orarioChiusura2 === '') {
            newrecord = {
                ...newrecord,
                orarioChiusura2: null,
            };
        }

        RestCall.Put(newrecord, props.type === 'mittenti' ? RestUrl.RUBRICA_MITTENTE_POST_MODIFY : RestUrl.RUBRICA_DESTINATARIO_POST_MODIFY, "ok", 'url').then(
            result => {
                if (result === "ok") {
                    load();
                    dispatch({ type: "SETVISIBLEUPDATE", visible: false });
                    notifySucces("@V_ITEM_MODIFY");
                } else {
                    if (result === "VALID_TOKEN") {
                        handleUpdate();
                    } else {
                        notifyError("@ERROR_400");
                    }
                }
            }
        );
    }
    const handleUpdateCancel = () => {
        dispatch({ type: "SETVISIBLEUPDATE", visible: false });
    }
    //END Modal update

    const RubricaCreatorColumn = [

        {
            title: '',
            dataIndex: 'isDefault',
            key: 'isDefault',
            width: 30,
            render: (value: boolean, record: any) =>
            (
                value ? <CheckOutlined title='Default' /> : <></>
            )
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: translate('@RAGSOC'),
            dataIndex: 'ragioneSociale',
            key: 'ragioneSociale',
        },
        {
            title: translate('@PRESSO'),
            dataIndex: 'perContoDi',
            key: 'perContoDi',
            width: 100,
            ellipsis: true,
        },
        {
            title: translate('@INDIRIZZO'),
            dataIndex: 'indirizzo',
            key: 'indirizzo',
            ellipsis: true,
        },
        {
            title: translate('@COMUNE'),
            dataIndex: 'localita',
            key: 'localita',
            ellipsis: true,
        },
        {
            title: 'Prov',
            dataIndex: 'provincia',
            key: 'provincia',
            width: 70
        },
        {
            title: translate('@CAP'),
            dataIndex: 'cap',
            key: 'cap',
            width: 100,
        }
        /*,
        {
            title: 'Cod',
            dataIndex:'codiceNazione',
            key:'codiceNazione',
            width:60
        },
        {
            title: translate('@NAZIONE'),
            dataIndex:'nazione',
            key:'nazione',
            width: 100
        }*/,
        {
            title: translate('@TELEFONO'),
            dataIndex: 'telefono',
            key: 'telefono',
            ellipsis: true,
        },
        {
            title: translate('@MAIL'),
            dataIndex: 'mail',
            key: 'mail',
            width: 120,
            ellipsis: true,
        },
        {
            title: "",
            key: 'action',
            fixed: RightFixed,
            width: 120,
            render: (text: any, record: any) => (
                <div className="container-col">
                    <div className="container-row c-ctr">
                        <Button
                            className="button-modify"
                            title={TranslateString("@MODIFY")}
                            style={{ marginRight: "5px" }}
                            type="primary"
                            shape="round"
                            size={'small'}
                            icon={<EditOutlined />}
                            onClick={() => showUpdate(record)}
                        />
                        <Button
                            className="red-background-ghost"
                            title={TranslateString("@DELETE")}
                            shape="round"
                            size={'small'}
                            icon={<DeleteOutlined />}
                            onClick={() => showDelete(record)}
                        />
                    </div>
                </div>
            ),
        }
    ];

    //START filter handler
    const handleFilter = (value: string, sql_name: string, operator: string, filter_type: SITableFilterTypeValues) => { //gestisce i filtri delle colonne di tipo stringa 
        dispatch({ type: 'SETFILTERWHERE', where_filter: new FilterStructureModel(sql_name, operator, filter_type, value) });
    }
    const handleOrderBy = (order_by: SITableOrder) => {//permette di effettuare l'ordinamento per colonna ASC/DESC
        dispatch({ type: 'SETORDERBYFILTER', orderby_filter: new Order(order_by.table_View_ColumnName, order_by.mode) });
    }
    //END filter handler

    return (
        <>
            <div className="container-col height-100">
                <div><h1 className="title-page">{TranslateString("@RUBRICA"/*props.type === 'mittenti' ? "@RUBRICA_MITTENTI" : "@RUBRICA_DESTINATARI"*/)}</h1></div>
                <div className="container-col scrollable-calc-40">
                    <div className="desktopBody" style={{ paddingBottom: '10px' }}>
                        <Button className="table-new-button" onClick={showCreateModal}><PlusOutlined />{TranslateString("@NEW_ITEM")}</Button>
                        <Button className="table-reload-button" onClick={() => load()}><ReloadOutlined />{TranslateString("@RELOAD_TABLE")}</Button>
                    </div>
                    <div className="mobile-body container-rowr" style={{ paddingBottom: '10px' }}>
                        <Button className="table-new-button" onClick={showCreateModal}><PlusOutlined /></Button>
                        <Button className="table-reload-button" onClick={() => load()}><ReloadOutlined /></Button>
                    </div>
                    {/*                     <div className="height-100-32">
                        <Table size={"small"} pagination={false} dataSource={state.rubrica} columns={RubricaCreatorColumn} scroll={{ x: 'auto', y: 650 }} />
                    </div> */}

                    <div className="height-100-70">
                        <div className="si-table-container height-100 width-100">
                            <SITableVirtualizedComponent
                                id_univoco={"rubrica_transfilm"}
                                page={undefined}
                                page_size={undefined}
                                totalcount={1}
                                data_source={state.rubrica}
                                columns={RubricaColumns(handleOrderBy, handleFilter, showUpdate, showDelete)}
                                filters={undefined}
                                bordered={true}
                                onPagination={() => { }}
                                select_rows={undefined}
                                onFilterChange={() => { }}
                                onShiftColumn={() => { }}
                                loading={loading}
                                colorRowClick={'#18D5FF'}
                                onRowClass={(record: any, index) => {
                                    if (index % 2 == 0) {
                                        return " light-gray "
                                    } else {
                                        return " "
                                    }
                                }}
                            />
                        </div>
                    </div>


                </div>
                {state.create_visible &&
                    <Modal
                        wrapClassName="mobile-large-modal"
                        title={TranslateString("@NEW_RUBRICA")}
                        visible={state.create_visible}
                        onOk={handleCreate}
                        onCancel={handleCreateCancel}
                        closeIcon={<LeftOutlined className="mobile-body" />}
                        keyboard={false}
                        maskClosable={false}
                        width="500px"
                    >
                        <RubricaForm record={state.rubricaItem} onChange={handleChanges} type={props.type} />
                    </Modal>
                }

                {state.update_visible &&
                    <Modal
                        wrapClassName="mobile-large-modal"
                        title={TranslateString("@UPDATE_RUBRICA")}
                        visible={state.update_visible}
                        onOk={handleUpdate}
                        onCancel={handleUpdateCancel}
                        closeIcon={<LeftOutlined className="mobile-body" />}
                        keyboard={false}
                        maskClosable={false}
                        width="500px"
                    >
                        <RubricaForm record={state.rubricaItem} onChange={handleChanges} type={props.type} />
                    </Modal>
                }
                {state.delete_visible &&
                    <Modal
                        wrapClassName="modal-s"
                        title={TranslateString("@DELETE_RUBRICA")}
                        visible={state.delete_visible}
                        onOk={handleDelete}
                        onCancel={handleDeleteCancel}
                        closeIcon={<LeftOutlined className="mobile-body" />}
                        keyboard={false}
                        maskClosable={false}
                        width="400px"
                    >
                        {TranslateString("@ARE_YOU_SURE")}
                    </Modal>
                }

            </div>
        </>
    );
}