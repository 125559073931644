import { ShippingTrackingStatusHeadModel, ShippingTrackingStatusLineModel, ShippingTrackingStatusModel } from "../Model/ShippingTrackingModel";

interface actionTypeSetLoading {
    type: "SETLOADING",
    loading: boolean
}

interface actionTypeSetData {
    type: "SETDATA",
    data: any
}

type actions = actionTypeSetLoading | actionTypeSetData;

interface IReducer {
    loading: boolean,
    barcode: string | null,
    header: ShippingTrackingStatusHeadModel | null,
    list: ShippingTrackingStatusLineModel[]
}

export const SearchShippingReducer = (state: IReducer,action: actions): IReducer => {
    switch (action.type) {
        case 'SETLOADING':
            return {...state, loading: action.loading}
        case "SETDATA":
            if(!action.data || action.data.length === 0) return {...state, header: null, list: [], barcode: null}
            const _header: ShippingTrackingStatusHeadModel = ShippingTrackingStatusHeadModel.getInstance(action.data[0]);
            const _list: ShippingTrackingStatusLineModel[] = action.data.map((item: ShippingTrackingStatusModel) => {
                return ShippingTrackingStatusLineModel.getInstance(item);
            })
            return {...state, header: _header, list:_list, barcode: action.data[0].barcode}
        default: 
            return  {...state}
    }
}