import { Form, Select } from "antd";
import { TranslateString } from "../../i18n/translate";
import { Today } from "../../utils/Today";
import { fixRoundingWithZeros } from "./MathUtils";

const { Option } = Select;
var _ = require('lodash');

export type SelectValidate = "" | "error" | "warning" | "success" | "validating" | undefined;

interface FormSelectProps {
    record: any,
    object_name: string,
    validate: SelectValidate,
    label_name?: string,
    label_className?: string,
    select_className?: string,
    form_className?: string,
    mandatory: boolean,
    onRecordChange: (record: any) => void,
    readOnly?: boolean,
    allowClear?: boolean
    title?: string | undefined,
    da: number,
    mezza?: boolean,
    a: number,
    helpMessage?: string | undefined
}


export const FormSelectTime: React.FC<FormSelectProps> = props => {


    const onChange = (value: any) => {
        let newRecord: any;
        if (value !== undefined) {
            newRecord = {
                ...props.record,
                [props.object_name]: Today() + value
            };
        } else {
            newRecord = {
                ...props.record,
                [props.object_name]: ""
            };
        }
        props.onRecordChange(newRecord);
    }

    function onValue(record: any): string | undefined {

        if (record !== undefined) {
            if (record[props.object_name] === null || record[props.object_name] === "" || record[props.object_name] < 0) {
                return undefined;
            } else {
                let time = "";
                try {
                    let ora: string = record[props.object_name].toString();
                    const split = ora.split("T");
                    if (split.length > 1) {
                        console.log(split[1]);
                        time = split[1].substring(0, 5);
                        console.log(time);
                    }
                }
                catch { }
                return time;
            }
        }

        return undefined;

    }

    function option(da: number, a: number): any[] {
        let options: any[] = [];
        for (var i = da; i <= a; i++) {
            // return <Option key={'0' + i.toString() + '00'} value={'T0' + i.toString() + ':00:00'}>0{i.toString()}:00</Option>
            
            let ora = "";


            if (props.mezza && i === da) {
            } else {
                ora = '' + (i.toString().length === 1 ? '0' : '') + i.toString() + ':00';
                options.push({ key: 'T' + ora + ':00', value: 'T' + ora + ':00', label: ora });
            }

            if (i !== a) {
                if (props.mezza && i === da) {
                    ora = '' + (i.toString().length === 1 ? '0' : '') + i.toString() + ':30';
                    options.push({ key: 'T' + ora + ':00', value: 'T' + ora + ':00', label: ora });

                    ora = '' + (i.toString().length === 1 ? '0' : '') + i.toString() + ':45';
                    options.push({ key: 'T' + ora + ':00', value: 'T' + ora + ':00', label: ora });
                } else {
                    ora = '' + (i.toString().length === 1 ? '0' : '') + i.toString() + ':15';
                    options.push({ key: 'T' + ora + ':00', value: 'T' + ora + ':00', label: ora });

                    ora = '' + (i.toString().length === 1 ? '0' : '') + i.toString() + ':30';
                    options.push({ key: 'T' + ora + ':00', value: 'T' + ora + ':00', label: ora });

                    ora = '' + (i.toString().length === 1 ? '0' : '') + i.toString() + ':45';
                    options.push({ key: 'T' + ora + ':00', value: 'T' + ora + ':00', label: ora });
                }

            } else {
                if (props.mezza) {
                    ora = '' + (i.toString().length === 1 ? '0' : '') + i.toString() + ':15';
                    options.push({ key: 'T' + ora + ':00', value: 'T' + ora + ':00', label: ora });

                    ora = '' + (i.toString().length === 1 ? '0' : '') + i.toString() + ':30';
                    options.push({ key: 'T' + ora + ':00', value: 'T' + ora + ':00', label: ora });
                }
            }
        }

        return options;
    }


    return (
        <>
            <Form.Item
                className={props.form_className}
                validateStatus={props.validate}
                help={typeof props.validate !== 'undefined' ? (props.helpMessage === undefined ? TranslateString("@MANDATORY_FIELD_MESSAGE") : props.helpMessage) : null}
            >
                <div className="column-1">
                    {props.label_name !== undefined &&
                        <label
                            className={props.label_className}
                            htmlFor="icons"
                            title={props.title}
                        >
                            {TranslateString("@" + props.label_name)}{props.mandatory && <label className="label-red"> *</label>}
                        </label>
                    }
                </div>
                <Select
                    showSearch
                    placeholder={'Seleziona...'}
                    style={{ marginTop: '4px', width: '160px' }}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    onChange={(value) => { onChange(value) }}
                    value={onValue(props.record)}
                    allowClear
                    options={option(props.da, props.a)}
                    disabled={props.readOnly}
                >
                </Select>
            </Form.Item>
        </>
    );
}